import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import surveyPlaceholderImage from "../../../assets/images/day.svg";
import MDAvatar from "components/MDAvatar";
import {
  archivedStyle,
  tableCellStyle,
  tableRowStyle,
} from "../styles/SurveyStyles";
import { getSurveyCategoryImage } from "@ivueit/vue-engine";
import { Link } from "@mui/material";
import { getEnumStringValueForTypes } from "../utils/constants";

interface Props {
  id: string;
  title: string;
  status: string;
  type: string;
  isArchived: boolean;
  onClick: () => void;
}

const SurveyListItem = (props: Props) => {
  // Setting text color for draft & published items
  const statusColor = props.status.toUpperCase() === "DRAFT" ? "red" : "green";
  // defines row style
  const rowStyle = props.isArchived
    ? { ...tableRowStyle, ...archivedStyle }
    : tableRowStyle;
  const type = getEnumStringValueForTypes(props.type);

  return (
    <Link
      onClick={props.onClick}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      <MDBox sx={rowStyle}>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexBasis: "120px",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <CustomBadge content={`#${props.id}`} />
        </MDBox>
        <MDBox sx={tableCellStyle}>
          <MDAvatar
            src={getSurveyCategoryImage(props.type)}
            alt=""
            size="lg"
            sx={{
              backgroundColor: surveyPlaceholderImage ? "" : "#4CAF50",
              img: surveyPlaceholderImage
                ? {}
                : {
                    width: "auto !important",
                    height: "auto !important",
                  },
            }}
          />
          <MDBox ml={1}>
            <MDTypography sx={{ color: "#1A73E8 !important" }}>
              {props.title}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexBasis: "120px",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <MDTypography sx={{ color: `${statusColor} !important` }}>
            {props.status}
          </MDTypography>
        </MDBox>
        <MDBox
          className="tableCellActions"
          sx={{
            ...tableCellStyle,
            flexBasis: "120px",
            flexGrow: "0",
            flexShrink: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MDTypography sx={{ color: `#344767 !important` }}>
            {props.isArchived ? "YES" : "NO"}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexBasis: "200px",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <CustomBadge
            content={type}
            sx={{
              "& .MuiBadge-badge": {
                color: "white !important",
                background: "#4CAF50",
              },
            }}
          />
        </MDBox>
      </MDBox>
    </Link>
  );
};

export default SurveyListItem;
