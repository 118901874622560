import {
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { dialogText } from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";

interface Props {
  companyName: string;
  closeDialog: () => void;
  onAddCompany: (isReadOnly: boolean) => void;
}

const ConfirmReadOnlyDialog = (props: Props) => {
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  return (
    <>
      <DialogContent sx={{ padding: "32px 29px !important" }}>
        <MDTypography
          variant="body"
          sx={{ ...dialogText, marginBottom: "10px" }}
        >
          {`Would you like to add ${props.companyName} to the group?`}
        </MDTypography>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => {
                setIsReadOnly(event.target.checked);
              }}
            />
          }
          label={`Read Only`}
          sx={{
            ".MuiFormControlLabel-label": {
              fontWeight: "400",
            },
          }}
        />
        <MDTypography
          variant="body"
          sx={{ ...dialogText, mb: 0.6 }}
        >
          <i>{`(By checking this, ${props.companyName} will be added as read only)`}</i>
        </MDTypography>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CLOSE
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              props.onAddCompany(isReadOnly);
            }}
            sx={{ fontSize: "14px", padding: "11px 22px" }}
          >
            ADD
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default ConfirmReadOnlyDialog;
