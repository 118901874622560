import { List } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  tableConStyle,
  tableRowHeadStyle,
  tableHeadCellStyle,
} from "pages/surveys/styles/SurveyStyles";
import CompaniesListItem from "./CompaniesListItem";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { alphanumericSort } from "utils/helpers/extensions";

interface Props {
  companiesList: Company[];
  companyGroupId: string;
  onRefresh: () => void;
}

const CompaniesList = (props: Props) => {
  return (
    <>
      <MDBox
        sx={{
          ...tableConStyle,
          position: "sticky",
          top: "0",
          zIndex: "124",
          background: "white",
        }}
      >
        <MDBox sx={tableRowHeadStyle}>
          <MDBox sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "80px",
            }}>
            <MDTypography>ID</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>Name</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>Email</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "120px",
            }}
          >
            <MDTypography>Read Only</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "120px",
            }}
          ></MDBox>
        </MDBox>
      </MDBox>
      <List sx={tableConStyle}>
        {props.companiesList
          .sort((a, b) => alphanumericSort(a.canonicalId, b.canonicalId))
          .map((company, index) => (
            <CompaniesListItem
              key={index}
              currentCompany={company}
              companiesList={props.companiesList}
              onRefresh={props.onRefresh}
              companyGroupID={props.companyGroupId}
            />
          ))}
      </List>
    </>
  );
};

export default CompaniesList;
