import {
  TEXTFIELD_CHANGE_DELAY,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import { useCallback, useEffect, useMemo, useState } from "react";
import { searchCompanies } from "pages/dashboard/home/common/services/services";
import {
  SEARCH_COMPANIES_PAGE_NUMBER,
  SEARCH_COMPANIES_PAGE_SIZE,
} from "../utils/constants";
import { CustomAutoCompleteComponent } from "components/CustomComponents/CustomAutoCompleteComponent";
import { Company } from "pages/profile/utils/ProfileInterfaces";

interface Props {
  company?: Company;
  onSuccess: (company?: Company) => void;
}

export const ClientSearchComponent = (props: Props) => {
  const { onSuccess, company } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [isFetchingCompanies, setIsFetchingCompanies] = useState<boolean>(true);
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [userIsTyping, setUserIsTyping] = useState<boolean>(false);
  const companyNamesList = useMemo(
    () => companyList.map((company) => company.name),
    [companyList]
  );

  // This executes when an option is selected from the drop-down
  const handleOptionSelect = (selectedValue: string, reason: string) => {
    setCompanyList([]);
    if (reason === "clear") {
      if (!searchText) return;
      setSearchText("");
      onSuccess(null);
      return;
    }
    setUserIsTyping(false);
    const companyName = selectedValue as string;
    const selectedCompany = companyList.filter(
      (company) => company.name.toLowerCase() === companyName.toLowerCase()
    )[0];
    onSuccess(selectedCompany);
  };

  const handleInputChange = (newValue: string) => {
    const sameCompany =
      newValue.toLowerCase() === company?.name.toLowerCase() ?? "";
    if (sameCompany) return;
    setUserIsTyping(true);
    setSearchText(newValue);
  };

  const handleSearchCompanies = useCallback(async () => {
    const parameters = `page.pageNumber=${SEARCH_COMPANIES_PAGE_NUMBER}&page.pageSize=${SEARCH_COMPANIES_PAGE_SIZE}&query=${searchText}`;
    const response = await searchCompanies(parameters);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.companies as Company[];
      setCompanyList(data);
    } else {
      setCompanyList([]);
    }
    setIsFetchingCompanies(false);
  }, [searchText]);

  useEffect(() => {
    if (company) {
      setSearchText(company.name);
    } else {
      setSearchText("");
    }
  }, [company]);

  useEffect(() => {
    if (userIsTyping && searchText) {
      const delaySearch = setTimeout(() => {
        handleSearchCompanies();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearch);
    }
  }, [handleSearchCompanies, userIsTyping, searchText]);

  return (
    <CustomAutoCompleteComponent
      onOpen={() => {
        handleSearchCompanies();
      }}
      optionsList={companyNamesList}
      noOptionsText={
        isFetchingCompanies
          ? "Loading..."
          : companyList.length === 0
          ? "Not available"
          : ""
      }
      value={company && company.name}
      inputValue={searchText}
      onOptionSelect={(event, selectedValue, reason) => {
        handleOptionSelect(selectedValue, reason);
      }}
      onInputChange={(event, newValue, reason) => {
        handleInputChange(newValue);
      }}
      placeholderText="Enter company name"
    />
  );
};
