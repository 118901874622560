import {
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  WebServiceResponse,
  WebServiceStatus,
  gridStyle,
  searchBarStyle,
  textEllipsis,
} from "@ivueit/vue-engine";
import {
  Card,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import { surveysGridCardStyle } from "pages/surveys/styles/SurveyStyles";
import { Search } from "@mui/icons-material";
import MDButton from "components/MDButton";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { useCallback, useEffect, useState } from "react";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridRenderCellParams,
  GridRowsProp,
  gridClasses,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridMetaData } from "pages/vuers/interface/interface";
import { defaultMetaData } from "pages/surveys/utils/constants";
import { useNavigate } from "react-router-dom";
import { clientsGridColumnData } from "../utlis/utils";
import DataGridBottomBar from "pages/vuers/components/BottomBar";
import { getAllCompanies, searchCompanies } from "../services/services";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import CustomDialogBox from "pages/components/CustomDialogBox";
import CreateNewClientDialog from "./CreateNewClientDialog";
import { getLinkElement } from "pages/vuers/utils/helpers";
import UserImageAvatar from "pages/vuers/components/UserImageAvatar";
import ShopIcon from "assets/images/Shop_Icon.svg";
import { RoutePath, TEXTFIELD_CHANGE_DELAY } from "../../../constants";
import { alphanumericSort } from "utils/helpers/extensions";
import { clientAvatarContainerStyle } from "../styles/ClientStyles";

const ClientsDataGrid = () => {
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [metaValues, setMetaValues] = useState<GridMetaData>(defaultMetaData);
  const [openAddClientDialog, setOpenAddClientDialog] =
    useState<boolean>(false);
  const [rowData, setRowData] = useState<GridRowsProp>([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchStarted, setSearchStarted] = useState(false);
  const navigate = useNavigate();
  /// Reference for the grid
  const gridRef = useGridApiRef();

  ////// GRID //////

  const parseCompanyDataResults = (
    response: WebServiceResponse,
    isScrolling: boolean
  ) => {
    var gridData: Company[] = [];
    if (response.status === WebServiceStatus.success) {
      const {
        companies,
        meta,
      }: {
        companies: Company[];
        meta: GridMetaData;
      } = response.data;
      gridData = companies;
      setMetaValues({
        pageSize: Number(meta.pageSize),
        pageNumber: Number(meta.pageNumber),
        totalPages: Number(meta.totalPages),
        totalElements: Number(meta.totalElements),
      });
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setLoader(false);
    const newRowData = isScrolling ? rowData.concat(gridData) : gridData;
    setRowData(
      newRowData.sort((a, b) => alphanumericSort(a.canonicalId, b.canonicalId))
    );
  };

  //Generating parameters for API
  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      // Default page number will be 1 if not scrolling
      const newPageNumber = isScrolling
        ? (metaValues.pageNumber + 1).toString()
        : "1";
      return `pageSize=${DEFAULT_PAGE_SIZE}&pageNumber=${newPageNumber}`;
    },
    [metaValues.pageNumber]
  );

  // Fetches the vuer list from the server
  const fetchCompanyListFromServer = useCallback(
    async (isScrolling: boolean) => {
      let parameters = getParameterString(isScrolling);
      let response: WebServiceResponse;
      setLoader(true);
      if (searchText.trim().isEmpty()) {
        response = await getAllCompanies(parameters);
      } else {
        parameters += `&query=${searchText.trim()}`;
        response = await searchCompanies(parameters);
      }
      parseCompanyDataResults(response, isScrolling);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    fetchCompanyListFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ////// SEARCH //////

  // Handling the text search for the grid
  useEffect(() => {
    const handleSearchTextChange = async (searchInput: string) => {
      if (!searchStarted) {
        return;
      }

      let parameters = getParameterString(false);
      parameters += `&query=${searchInput.trim()}`;
      setLoader(true);
      const response = await searchCompanies(parameters);
      // Ignoring scroll on search
      parseCompanyDataResults(response, false);
    };
    /// The method delays the api call for 700ms
    /// It helps us to avoid frequent api calls & state updates, on text change
    const delayAPIInvocation = setTimeout(() => {
      handleSearchTextChange(searchText.trim());
    }, TEXTFIELD_CHANGE_DELAY);

    return () => clearTimeout(delayAPIInvocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  ////// GRID //////

  // Defines the custom cells in the grid
  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value, row } = params;
    const company: Company = row;
    let cellContent = value;
    switch (field) {
      case "canonicalId":
        return getLinkElement(`#${value}`, () => {
          onClickLinkElement(params);
        });
      case "company":
        return getCompanyCell(params);
      case "archived":
        cellContent = company.archived ? "YES" : "NO";
        break;
      case "accountManager":
        cellContent = company.accountManager.isNotEmpty()
          ? company.accountManager
          : "-";
        break;
      default:
        break;
    }

    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{cellContent}</MDBox>
      </MDBox>
    );
  };

  const getCompanyCell = (params: GridRenderCellParams) => {
    const { row } = params;
    const company: Company = row;
    return (
      <Grid
        container
        columnSpacing={3}
        alignItems="center"
        flexWrap={"nowrap"}
        pt={1.2}
        pb={0.8}
      >
        <Grid item pl="6px">
          <MDBox sx={clientAvatarContainerStyle}>
            <UserImageAvatar
              imageId={company.logoFileId}
              size="lg"
              shadow="lg"
              placeholderImage={ShopIcon}
              sx={{
                backgroundColor: "#4CAF50",
                img: company.logoFileId
                  ? {}
                  : {
                    width: "auto !important",
                    height: "auto !important",
                  },
              }}
            />
          </MDBox>
        </Grid>
        <Grid item>
          <MDBox
            height="100%"
            display="flex"
            flexDirection={"column"}
            mt={0.5}
            lineHeight={1}
          >
            {getLinkElement(company.name, () => {
              onClickLinkElement(params);
            })}
          </MDBox>
        </Grid>
      </Grid>
    );
  };

  /// Handles the navigation to client detail page
  const onClickLinkElement = (params: GridRenderCellParams) => {
    const client = params.row;
    if (client) {
      navigate(`${RoutePath.clients}/${client.canonicalId}`, {
        state: {
          item: client,
        },
      });
    }
  };

  const renderedColumns = clientsGridColumnData.map((data) => {
    data.renderCell = getCustomCells;
    return data;
  });

  const getFooter = () => {
    return (
      <MDBox display="flex" alignItems="center">
        <DataGridBottomBar
          title={"Total Clients"}
          totalItems={metaValues.totalElements}
        />
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No clients found.
        </Typography>
      </MDBox>
    );
  };

  const handleOnRowsScrollEnd: DataGridPremiumProps["onRowsScrollEnd"] =
    async () => {
      if (
        rowData.length < metaValues.totalElements &&
        metaValues.pageNumber < metaValues.totalPages
      ) {
        await fetchCompanyListFromServer(true);
      }
    };

  ////// GRID //////

  const getAddClientDialog = () => {
    return (
      <CustomDialogBox
        title={"New Client"}
        width="420px"
        openDialog={openAddClientDialog}
      >
        <CreateNewClientDialog
          closeDialog={() => {
            setOpenAddClientDialog(false);
          }}
          onClientCreation={() => {
            // Reloading the pages
            fetchCompanyListFromServer(false);
            setOpenAddClientDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  return (
    <>
      {openAddClientDialog && getAddClientDialog()}
      <MDBox display={"flex"} pt={0.6} pb={1.4}>
        <MDBox flexGrow={1} maxWidth={"400px"} mr={0.4}>
          <TextField
            fullWidth
            placeholder="Search by Name"
            sx={searchBarStyle}
            onChange={(event) => {
              setSearchStarted(true);
              setSearchText(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search fontSize="medium" sx={{ color: "#344767" }} />
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox ml="auto">
          <MDButton
            variant="contained"
            color="success"
            sx={{ fontSize: "14px", fontWeight: "bold" }}
            onClick={() => {
              setOpenAddClientDialog(true);
            }}
          >
            ADD CLIENT
          </MDButton>
        </MDBox>
      </MDBox>
      <Card sx={surveysGridCardStyle}>
        <DataGridPremium
          apiRef={gridRef}
          columns={renderedColumns}
          rows={rowData}
          disableColumnMenu
          sx={{
            ...gridStyle,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          density="compact"
          disableRowSelectionOnClick
          slots={{
            footer: getFooter,
            loadingOverlay: LinearProgress,
            noRowsOverlay: getNoRowsMessage,
          }}
          loading={loader}
          sortingMode="server"
          filterMode="server"
          getRowHeight={() => "auto"}
          // rowHeight={140}
          onRowsScrollEnd={handleOnRowsScrollEnd}
        />
      </Card>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </>
  );
};

export default ClientsDataGrid;
