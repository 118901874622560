import DashboardContentLayout from "layouts/DashboardContentLayout";
import SurveysDataGrid from "./components/SurveysDataGrid";

const Surveys = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <SurveysDataGrid />
    </DashboardContentLayout>
  );
};

export default Surveys;
