import { IconButton } from "@mui/material";
import { Icon } from "pages/dashboard/home/vues/vue-grid/helpers/imports";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderStepSection } from "./HeaderStepSection";
import { searchBarStyle } from "pages/dashboard/home/vues/styles/VueStyles";
import MDInput from "components/MDInput";
import { CustomAddoption } from "./CustomAddOption";
import {
  inputNumberStyle,
  regularTextStyle,
  tagItemStyle,
} from "../styles/SurveyStyles";
import { mdiDelete } from "@mdi/js";
import { CustomPreviewComponent } from "./CustomPreviewComponent";
import {
  SurveyStep,
  TEXTFIELD_CHANGE_DELAY,
  WebServiceStatus,
  customBadgeStyle,
} from "@ivueit/vue-engine";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { updateSurveyTemplateStep } from "../services/SurveyServices";
import { StepActionType } from "../types/enums";
import { SurveyGroupWithStep, VueSurveyData } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import {
  hasConditionsOnSurvey,
  hasConditionsOnSurveyStep,
  isGroupOrphaned,
} from "../utils/helpers";

interface Props {
  serialNumber: string;
  groupName: string;
  step: SurveyStep;
  hasBeenGrouped: boolean;
  hasConditionsApplied: boolean;
  showAddConditionButton: boolean;
  onSuccess: (step: SurveyStep) => void;
  groupIndex: number;
  surveyData: VueSurveyData;
  onDeleteGroup: (id: string) => void;
  onHeaderClick: (type: StepActionType) => void;
}

const DraftModeMultiSelectComponent = (props: Props) => {
  const { serialNumber, step, hasBeenGrouped } = props;
  const [multiSelectStep, setMultiSelectStep] = useState<SurveyStep>(step);
  const [delayTypeAction, setDelayTypeAction] = useState<boolean>(false);
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const { id, description, options, minSelections, maxSelections } =
    multiSelectStep;
  const parsedOptions: string[] = useMemo(
    () => (options ? JSON.parse(options).options : []),
    [options]
  );
  const [error, setError] = useState({
    prompt: "",
    minValue: "",
    maxValue: "",
  });

  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setMultiSelectStep({
      ...multiSelectStep,
      [event.target.name]: value,
    });
    setError({ ...error, prompt: !value ? "Prompt cannot be empty" : "" });
    setDelayTypeAction(value.isNotEmpty());
  };

  const handleMinValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const min = parseInt(value);
    const isValid =
      !isNaN(min) &&
      min >= 0 &&
      min <= maxSelections &&
      min <= parsedOptions.length;
    setMultiSelectStep({ ...multiSelectStep, [event.target.name]: value });
    setError({ ...error, minValue: !isValid ? "Invalid min value" : "" });
    setDelayTypeAction(isValid);
  };

  const handleMaxValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const max = parseInt(value);
    const isValid =
      !isNaN(max) &&
      max >= 0 &&
      minSelections <= max &&
      max <= parsedOptions.length;
    setMultiSelectStep({ ...multiSelectStep, [event.target.name]: value });
    setError({ ...error, maxValue: !isValid ? "Invalid max value" : "" });
    setDelayTypeAction(isValid);
  };

  const handleDeleteOption = (optionToBeDeleted: string) => {
    setDelayTypeAction(true);
    const filteredOptions = parsedOptions.filter(
      (item) => item.toLowerCase() !== optionToBeDeleted.toLowerCase()
    );
    const optionsObj = {
      options: filteredOptions,
    };
    setMultiSelectStep({
      ...multiSelectStep,
      options: JSON.stringify(optionsObj),
    });
  };

  const handleAddOption = (newOptions: string[]) => {
    setDelayTypeAction(true);
    const optionsObj = {
      options: newOptions,
    };
    const stringifiedValue = JSON.stringify(optionsObj);
    setMultiSelectStep({ ...multiSelectStep, options: stringifiedValue });
  };

  const updateGroupIdForStep = () => {
    // Updating the groupId for this step
    setMultiSelectStep({ ...multiSelectStep, groupId: "" });
    setDelayTypeAction(true);
  };

  const deleteGroup = () => {
    const hasSurveyConditions = hasConditionsOnSurvey(props.surveyData, step);
    const hasStepConditions = hasConditionsOnSurveyStep(props.surveyData, step);
    // TODO - Allow/deny based on specific scenaario, for now deny all
    // when ungrouping, stepcondition could contain condition from step in group, if so deny
    // always deny if parent step of condition, as only other steps of same group could be linked
    if (hasSurveyConditions || hasStepConditions) {
      setSnackBar({
        title: "Attention!",
        message: "This step is linked to another, cannot ungroup step.",
        isError: true,
      });
      return;
    }
    const islastStepInTheGroup = isGroupOrphaned(
      step,
      props.surveyData.survey.steps
    );
    const groupID = islastStepInTheGroup ? multiSelectStep.groupId : "";
    if (groupID) {
      // Removing the group if the group is orphaned
      props.onDeleteGroup(groupID);
    }
    updateGroupIdForStep();
  };

  const handleUpdateSurveyStep = useCallback(async () => {
    if (id.isEmpty()) {
      return;
    }
    setShowLoader(true);
    const response = await updateSurveyTemplateStep(id, multiSelectStep);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as SurveyStep;
      props.onSuccess(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to update Multi-Select step.",
        isError: true,
      });
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiSelectStep, id]);

  useEffect(() => {
    if (delayTypeAction) {
      const delaySearchAction = setTimeout(() => {
        handleUpdateSurveyStep();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
  }, [handleUpdateSurveyStep, delayTypeAction]);

  return (
    <MDBox p={2}>
      <HeaderStepSection
        groupName={props.groupName}
        stepNumber={serialNumber}
        stepType={"Multiselect"}
        isGrouped={hasBeenGrouped}
        onButtonClick={(type: StepActionType) => {
          type === StepActionType.ungroup
            ? deleteGroup()
            : props.onHeaderClick(type);
        }}
        hasConditionApplied={props.hasConditionsApplied}
        showAddConditionButton={props.showAddConditionButton}
        groupIndex={props.groupIndex}
      />
      <MDBox
        border="1px solid #C7CCD0"
        p="16px 16px 20px 16px"
        borderRadius="8px"
      >
        <MDBox>
          <MDInput
            fullWidth
            value={description}
            name="description"
            error={error.prompt}
            placeholder="Enter Prompt"
            multiline
            rows={2}
            onChange={handlePromptChange}
          />
        </MDBox>
        <MDBox pt={1}>
          <CustomAddoption
            onAddOption={handleAddOption}
            optionsList={parsedOptions}
          />
        </MDBox>
        {/* generating the options */}
        {parsedOptions.length > 0 && (
          <MDBox pt={1}>
            <MDTypography sx={regularTextStyle}>Options</MDTypography>
            <MDBox display="flex" flexWrap="wrap">
              {parsedOptions.map((option, index) => {
                return (
                  <MDBox
                    key={`${option}-${index}`}
                    display="flex"
                    sx={tagItemStyle}
                  >
                    <CustomBadge
                      content={`${index + 1}. ${option}`}
                      borderRadius="8px"
                      sx={customBadgeStyle}
                    />
                    <IconButton
                      color="error"
                      onClick={() => {
                        handleDeleteOption(option);
                      }}
                    >
                      <Icon path={mdiDelete} size={0.85} />
                    </IconButton>
                  </MDBox>
                );
              })}
            </MDBox>
          </MDBox>
        )}
        <MDBox pt={1} display="flex">
          <MDBox pt={1}>
            <MDTypography sx={regularTextStyle}>
              Minimum Selections
            </MDTypography>
            <MDInput
              name="minSelections"
              type="number"
              placeholder="Min"
              value={minSelections}
              error={error.minValue}
              sx={{ ...searchBarStyle, ...inputNumberStyle }}
              onChange={handleMinValueChange}
            />
          </MDBox>
          <MDBox pt={1} ml={1}>
            <MDTypography sx={regularTextStyle}>
              Maximum Selections (0 for unlimited)
            </MDTypography>
            <MDInput
              name="maxSelections"
              type="number"
              placeholder="Max"
              error={error.maxValue}
              value={maxSelections}
              sx={{ ...searchBarStyle, ...inputNumberStyle }}
              onChange={handleMaxValueChange}
            />
          </MDBox>
        </MDBox>
        <MDBox pt={1}>
          <CustomPreviewComponent step={multiSelectStep} />
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};

export default DraftModeMultiSelectComponent;
