export interface Tag {
  id: string;
  companyId: string;
  canonicalId: string;
  name: string;
  metadata: string;
  authorId: string;
  createdAt: string;
}

export enum Portals {
  admin = "Admin Portal",
  client = "Client portal",
}
