import MDBox from "components/MDBox";
import { useState } from "react";
import VuersTabs, { VuersTabType } from "./components/VuersTabs";
import VuerTabContent from "./components/VuerTabContent";
import { VueSubmissionGrid } from "./components/VueSubmissionGrid";
import { TransactionsGrid } from "./components/TransactionsGrid";
import { VuerData } from "@ivueit/vue-engine";

interface Props {
  vuer: VuerData;
}
const VuersDetailContent = ({ vuer }: Props) => {
  const [activeTab, setActiveTab] = useState<VuersTabType>(
    VuersTabType.VuerDetails
  );

  const getVuerDetailsTab = () => {
    return <VuerTabContent vuer={vuer} />;
  };

  const getTransactionsTab = () => {
    return <TransactionsGrid vuerID={vuer.id} />;
  };

  const getSubmissionsTab = () => {
    return <VueSubmissionGrid vuerID={vuer.id} />;
  };

  return (
    <>
      <MDBox display="flex" pt={2.2}>
        <MDBox display="flex" ml="auto" alignItems="center" mr={1.4}>
          <VuersTabs
            onTabChange={(tab: VuersTabType) => {
              setActiveTab(tab);
            }}
          />
        </MDBox>
      </MDBox>

      {activeTab === VuersTabType.VuerDetails
        ? getVuerDetailsTab()
        : activeTab === VuersTabType.transactions
        ? getTransactionsTab()
        : getSubmissionsTab()}
    </>
  );
};

export default VuersDetailContent;
