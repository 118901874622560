import { VueSurvey } from "@ivueit/vue-engine";
import {
  VueSurveyAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

// SURVEY LISTING SCREEN

export const getSurveyTemplates = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = VueSurveyAPIServices.getSurveyTemplates + "?" + parameters;
  return await performGetRequest(url);
};

export const createSurveyTemplate = async (
  data: any
): Promise<WebServiceResponse> => {
  const url = VueSurveyAPIServices.getSurveyTemplates;
  return await performPostRequest(url, data);
};

// SURVEY DETAIL SCREEN

export const updateSurveyTemplate = async (
  template: VueSurvey,
  selectedSurveyId: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.updateSurveyTemplate}/${selectedSurveyId}`;
  return await performPostRequest(url, template);
};

export const getSurveyTemplateById = async (
  templateId: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplateById}/${templateId}`;
  return await performGetRequest(url);
};

export const cloneSurveyTemplate = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplates}/${id}/clone`;
  return await performPostRequest(url, null);
};

// STEP RELATED APIs

export const insertSurveyTemplateStep = async (
  surveyId: string,
  param: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplateById}/${surveyId}/step`;
  return await performPostRequest(url, param);
};

export const updateSurveyTemplateStep = async (
  id: string,
  param: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplateById}/${id}/step/update`;
  return await performPostRequest(url, param);
};

export const deleteSurveyTemplateStep = async (
  surveyId: string,
  stepId: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplateById}/${surveyId}/step/${stepId}/remove`;
  return await performPostRequest(url, null);
};

export const moveSurveyTemplateStep = async (
  surveyId: string,
  param: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplateById}/${surveyId}/step/move`;
  return await performPostRequest(url, param);
};

// CONDITION RELATED APIs

export const createSurveyCondition = async (
  childStepId: string,
  param: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateConditions}/${childStepId}`;
  return await performPostRequest(url, param);
};

export const updateSurveyCondition = async (
  stepConditionId: string,
  param: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateConditions}/${stepConditionId}/update`;
  return await performPostRequest(url, param);
};

export const deleteSurveyCondition = async (
  stepConditionId: string,
  param: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateConditions}/${stepConditionId}/remove`;
  return await performPostRequest(url, param);
};

// GROUP RELATED APIs

export const getSurveyGroups = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateGroup}/${id}`;
  return await performGetRequest(url);
};

export const createSurveyGroups = async (
  params: any
): Promise<WebServiceResponse> => {
  const url = VueSurveyAPIServices.surveyTemplateGroup;
  return await performPostRequest(url, params);
};

export const updateSurveyGroups = async (
  id: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateGroup}/${id}/update`;
  return await performPostRequest(url, params);
};

export const deleteSurveyGroups = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateGroup}/${id}/remove`;
  return await performPostRequest(url, {});
};

// QUESTION/ANSWER TAG RELATED APIs

export const getStepAndAnswerTagsForSurveyTemplateId = async (
  templateId: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplateById}/${templateId}/tags`;
  return await performGetRequest(url);
};

export const applyQuestionTagToStep = async (
  associatedSurveyStepId: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateStep}/${associatedSurveyStepId}/tags/question`;
  return await performPostRequest(url, params);
};

export const removeQuestionTagFromStep = async (
  associatedSurveyStepId: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateStep}/${associatedSurveyStepId}/tags/question/remove`;
  return await performPostRequest(url, params);
};

export const addAnswerTagToQuestionTag = async (
  questionTagId: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateQuestionTag}/${questionTagId}/answer`;
  return await performPostRequest(url, params);
};

export const updateAnswerTag = async (
  answerTagId: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateAnswerTag}/${answerTagId}/update`;
  return await performPostRequest(url, params);
};

export const removeAnswerTag = async (
  answerTagId: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.surveyTemplateAnswerTag}/${answerTagId}/remove`;
  return await performPostRequest(url, params);
};
