import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  List,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { listStyle } from "../../vue-grid/components/CustomSearchBarWithList";
import { MDBox, MDButton, Search } from "../../vue-grid/helpers/imports";
import {
  dialogCancelButtonStyle,
  dialogSaveButtonStyle,
} from "../../styles/VueDetailStyles";
import { CustomDeletableChip } from "./CustomDeletableChip";
import {
  fetchVueTagsByVueId,
  updateVueTagsForVue,
} from "pages/dashboard/home/vues/services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { InfoOutlined } from "@mui/icons-material";
import MessageAlert from "pages/authentication/components/MessageAlert";
import { alphanumericSort } from "utils/helpers/extensions";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";

interface Props {
  vueId: string;
  previouslyAddedTags: string[];
  cancelButtonClick: () => void;
  saveButtonClick: (selectedTagsList: string[]) => void;
  immutableTags?: string[];
}

export interface VueTag {
  id: string;
  name: string;
  isImmutable: boolean;
}

export const TagVueDialogContent = ({
  vueId,
  cancelButtonClick,
  saveButtonClick,
  previouslyAddedTags,
  immutableTags = [],
}: Props) => {
  /// Holds the state of search field
  const [searchInput, setSearchInput] = useState<string>("");
  /// Loader's state
  const [showLoader, setShowLoader] = useState(false);
  /// Holds the tag vues that are added to the list
  const [addedTagsList, setAddedTagsList] = useState<VueTag[]>([]);
  /// Holds the tag vues that are NOT added to the list
  const [allTagsList, setAllTagsList] = useState<VueTag[]>([]);
  const [allVueTags, setAllVueTags] = useState<VueTag[]>([]);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [error, setError] = useState<string>("");

  /// Handles the checkbox change
  const handleCheckboxChange = (selectedTag: VueTag) => {
    setSearchInput("");
    setAddedTagsList([...addedTagsList, selectedTag]);
    setAllTagsList(allTagsList.filter((tag) => tag.id !== selectedTag.id));
  };

  /// Handles the onChange of the search input
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    setSearchInput(input);
  };

  /// Handles the chip delete
  const handleChipDelete = (selectedItem: VueTag) => {
    var newTagList = [...allTagsList];
    const isActive = allVueTags.some((tag) => tag.id === selectedItem.id);
    if (isActive) {
      newTagList.push(selectedItem);
    }
    setAllTagsList(newTagList);
    setAddedTagsList(addedTagsList.filter((tag) => tag.id !== selectedItem.id));
  };

  /// Handles the onClick of "Save" button
  const handleSaveButtonClick = async () => {
    const addedTagsWithId = addedTagsList.filter((item) => item.id !== null);
    const newIds = addedTagsWithId.map((item) => item.id);
    setShowLoader(true);
    const response = await updateVueTagsForVue(vueId, newIds);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      cancelButtonClick();
      setError("");
      saveButtonClick(addedTagsWithId.map((item) => item.name));
    } else {
      setError(response.error);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    const fetchAllVueTagsForVueId = async () => {
      setShowLoader(true);
      const response = await fetchVueTagsByVueId(vueId);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        setError("");
        const {
          appliedVueTags,
          allVueTags,
        }: { appliedVueTags: VueTag[]; allVueTags: VueTag[] } = response.data;
        setAllVueTags(allVueTags);
        // Filtering items that are already applied
        const tagsToBeAdded = allVueTags.filter(
          (tag) =>
            !appliedVueTags.some((appliedTag) => appliedTag.id === tag.id)
        );
        const immutableTagsAdded = immutableTags.map((tag) => {
          return { id: null, name: tag, isImmutable: true };
        });
        setAddedTagsList([...immutableTagsAdded, ...appliedVueTags]);
        setAllTagsList(tagsToBeAdded);
      } else {
        setError(response.error);
        setShowLoader(false);
      }
    };
    fetchAllVueTagsForVueId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vueId]);

  useEffect(() => {
    const addedTags = addedTagsList.map((tag) => tag.name).sort();
    const hasNewTags =
      JSON.stringify(addedTags) !== JSON.stringify(previouslyAddedTags.sort());
    setEnableSaveButton(hasNewTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTagsList]);

  return (
    <>
      {showLoader && <CustomIndicator />}
      <MDBox px={2} pt={2}>
        {error && (
          <MessageAlert message={error} type="error" icon={<InfoOutlined />} />
        )}
        <TextField
          fullWidth
          placeholder="Search Tags"
          value={searchInput}
          InputLabelProps={{ shrink: true }}
          onChange={handleSearchInputChange}
          sx={{ py: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="medium" sx={{ color: "#344767" }} />
              </InputAdornment>
            ),
          }}
        />
        <MDBox>
          <MDBox display="flex" alignItems="center">
            <MDTypography sx={{ fontSize: "14px", fontWeight: "450" }}>
              Added Tags ({addedTagsList.length})
            </MDTypography>
            <Divider
              sx={{
                backgroundColor: "#A8B8D8",
                height: "1x",
                opacity: 0.5,
                flexGrow: "1",
                ml: "6px",
              }}
            />
          </MDBox>
          <MDBox
            sx={{
              maxHeight: "154px",
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "6px",
              },
              "::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#9F9FA1",
                borderRadius: "3px",
                ":hover": {
                  background: "#AF9FA1",
                },
              },
            }}
          >
            {addedTagsList.length > 0 ? (
              addedTagsList.sort().map((checkedItem) => (
                <CustomDeletableChip
                  key={checkedItem.id}
                  label={checkedItem.name}
                  onClick={() => {
                    handleChipDelete(checkedItem);
                  }}
                  isImmutable={checkedItem.isImmutable}
                />
              ))
            ) : (
              <PlaceholderComponent label={"No tags added"} fontSize="18px" />
            )}
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <MDTypography sx={{ fontSize: "14px", fontWeight: "450" }}>
              All Tags
            </MDTypography>
            <Divider
              sx={{
                backgroundColor: "#A8B8D8",
                opacity: 0.5,
                height: "1px",
                flexGrow: "1",
                ml: "6px",
              }}
            />
          </MDBox>
          <MDBox>
            {allTagsList.length > 0 ? (
              <List sx={listStyle}>
                {allTagsList
                  .sort((a, b) => alphanumericSort(a.name, b.name))
                  .filter((item) =>
                    item.name
                      .toLowerCase()
                      .includes(searchInput.trim().toLowerCase())
                  )
                  .map((item) => (
                    <FormGroup key={item.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              handleCheckboxChange(item);
                            }}
                          />
                        }
                        label={item.name}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontWeight: "400",
                          },
                        }}
                      />
                    </FormGroup>
                  ))}
              </List>
            ) : (
              <PlaceholderComponent
                label={"No tags available"}
                fontSize="18px"
              />
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        sx={{
          backgroundColor: "#A8B8D8",
          height: "1px",
          opacity: 0.5,
          m: 0,
          backgroundImage: "none !important",
        }}
      />
      <MDBox px={2} py={2} display="flex" justifyContent="flex-end">
        <MDButton
          variant="outlined"
          sx={dialogCancelButtonStyle}
          size="small"
          onClick={cancelButtonClick}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="success"
          disabled={!enableSaveButton}
          sx={dialogSaveButtonStyle}
          size="small"
          onClick={handleSaveButtonClick}
        >
          SAVE
        </MDButton>
      </MDBox>
    </>
  );
};
