import DashboardContentLayout from "layouts/DashboardContentLayout";
import { useLocation } from "react-router-dom";
import { Vuer } from "./interface/interface";
import VuersDetailContent from "./VuerDetailContent";

const VuersDetail = () => {
  const location = useLocation();
  const vuer = location.state.item as Vuer;

  return (
    <DashboardContentLayout>
      <VuersDetailContent vuer={vuer} />
    </DashboardContentLayout>
  );
};

export default VuersDetail;
