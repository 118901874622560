import {
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  TEXTFIELD_CHANGE_DELAY,
  gridStyle,
  searchBarStyle,
  textEllipsis,
} from "@ivueit/vue-engine";
import { Search } from "@mui/icons-material";
import {
  Card,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import { surveysGridCardStyle } from "pages/surveys/styles/SurveyStyles";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { defaultMetaData } from "pages/surveys/utils/constants";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridRenderCellParams,
  GridRowsProp,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import {
  getLinkElement,
  getTextlineURL,
  vuerColumnData,
} from "../utils/helpers";
import DataGridBottomBar from "./BottomBar";
import { findUser, getAllVuers } from "../services/VuerServices";
import { GridMetaData, Vuer } from "../interface/interface";
import { WebServiceStatus } from "utils/services/AppUrls";
import { RoutePath } from "../../../constants";
import UserImageAvatar from "./UserImageAvatar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { ExportVuerDialog } from "./ExportVuerDialog";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { avatarContainerStyle } from "../styles/VuersStyles";

const VuersDataGrid = () => {
  const [rowData, setRowData] = useState<GridRowsProp>([]);
  /// Used to perform search by name
  const [searchInput, setSearchInput] = useState("");
  /// Used to perform search by canonical
  const [searchStarted, setSearchStarted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showExportVuerDialog, setShowExportVuerDialog] = useState(false);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [metaValues, setMetaValues] = useState<GridMetaData>(defaultMetaData);
  const navigate = useNavigate();
  /// Reference for the grid
  const gridRef = useGridApiRef();

  ////// SEARCH //////

  // Handling the text search for the grid
  useEffect(() => {
    const handleSearchTextChange = async (searchInput: string) => {
      const searchValue = searchInput.trim().replaceAll("#", "");
      if (!searchStarted) {
        return;
      } else if (searchValue.isEmpty()) {
        fetchVuerListFromServer(false);
        return;
      }

      let parameter = "";
      if (searchValue.isNumber()) {
        parameter += `canonicalID=${searchValue}`;
      } else {
        parameter = `nameQueryString=${searchValue}`;
      }

      setLoader(true);
      const response = await findUser(parameter);
      if (response.status === WebServiceStatus.success) {
        const users = response.data.users as Vuer[];
        setRowData(users);
        // As API do not have the meta values, handling it manually
        setMetaValues({
          pageSize: users.length,
          pageNumber: 1,
          totalPages: 1,
          totalElements: users.length,
        });
      } else {
        setSnackBarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
      setLoader(false);
    };
    /// The method delays the api call for 700ms
    /// It helps us to avoid frequent api calls & state updates, on text change
    const delayAPIInvocation = setTimeout(() => {
      handleSearchTextChange(searchInput.trim());
    }, TEXTFIELD_CHANGE_DELAY);

    return () => clearTimeout(delayAPIInvocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  ////// Export Feature //////

  /// Export vuer list dialog
  const getExportVuerDialog = () => {
    return (
      <CustomDialogBox
        title="Export Vuers"
        width="560px"
        openDialog={showExportVuerDialog}
      >
        <ExportVuerDialog
          closeDialog={() => {
            setShowExportVuerDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  ////// GRID //////

  //Generating parameters for API
  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      // Default page number will be 1 if not scrolling
      var newPageNumber = isScrolling
        ? (metaValues.pageNumber + 1).toString()
        : "1";
      var pageSize = DEFAULT_PAGE_SIZE;
      return `pageSize=${pageSize}&pageNumber=${newPageNumber}`;
    },
    [metaValues.pageNumber]
  );

  // Fetches the vuer list from the server
  const fetchVuerListFromServer = useCallback(
    async (isScrolling: boolean) => {
      setLoader(true);
      const parameters = getParameterString(isScrolling);
      const response = await getAllVuers(parameters);
      var gridData: Vuer[] = [];
      if (response.status === WebServiceStatus.success) {
        const {
          users,
          meta,
        }: {
          users: Vuer[];
          meta: GridMetaData;
        } = response.data;
        gridData = users;
        setMetaValues({
          pageSize: Number(meta.pageSize),
          pageNumber: Number(meta.pageNumber),
          totalPages: Number(meta.totalPages),
          totalElements: Number(meta.totalElements),
        });
      } else {
        setSnackBarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
      setLoader(false);
      if (isScrolling) {
        setRowData(rowData.concat(gridData));
      } else {
        setRowData(gridData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    fetchVuerListFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Defines the custom cells in the grid
  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value } = params;

    if (field === "canonicalId") {
      return getLinkElement(`#${value}`, () => {
        onClickLinkElement(params);
      });
    } else if (field === "vuer") {
      return getUserCell(params);
    }

    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{value}</MDBox>
      </MDBox>
    );
  };

  const getUserCell = (params: GridRenderCellParams) => {
    const { row } = params;
    const vuer: Vuer = row;
    return (
      <Grid
        container
        columnSpacing={3}
        alignItems="center"
        flexWrap={"nowrap"}
        pt={1.2}
        pb={0.8}
      >
        <Grid item pl="6px">
          <MDBox sx={avatarContainerStyle}>
            <UserImageAvatar
              imageId={vuer.profilePhotoFileId}
              size="md"
              shadow="sm"
            />
          </MDBox>
        </Grid>
        <Grid item>
          <MDBox
            height="100%"
            display="flex"
            flexDirection={"column"}
            mt={0.5}
            lineHeight={1}
          >
            {getLinkElement(vuer.name, () => {
              onClickLinkElement(params);
            })}
            <MDBox>
              <MDTypography variant="button" color="dark" fontWeight="light">
                {vuer.email}
              </MDTypography>
            </MDBox>
            <MDBox>
              {getLinkElement(vuer.phoneNumber, () => {
                onClickPhoneNumber(params);
              })}
            </MDBox>
            {/* <MDBox>
              {isPreferred && (
                <CustomBadge content={vuer.typeOf} borderRadius="5px" />
              )}
            </MDBox> */}
          </MDBox>
        </Grid>
      </Grid>
    );
  };

  /// Handles the navigation to vuer detail page
  const onClickLinkElement = (params: GridRenderCellParams) => {
    const vuer = params.row;
    if (vuer) {
      navigate(`${RoutePath.vuers}/${vuer.name}`, {
        state: {
          item: vuer,
        },
      });
    }
  };

  /// Handles the navigation to textline website
  const onClickPhoneNumber = (params: GridRenderCellParams) => {
    const vuer = params.row as Vuer;
    if (vuer) {
      const phoneNumber = vuer.phoneNumber;
      const name = vuer.name;
      const textLineURL = getTextlineURL(phoneNumber, name);
      window.open(textLineURL, "_blank");
    }
  };

  const renderedColumns = vuerColumnData.map((data) => {
    data.renderCell = getCustomCells;
    return data;
  });

  const handleOnRowsScrollEnd: DataGridPremiumProps["onRowsScrollEnd"] =
    async () => {
      if (
        rowData.length < metaValues.totalElements &&
        metaValues.pageNumber < metaValues.totalPages
      ) {
        await fetchVuerListFromServer(true);
      }
    };

  const getFooter = () => {
    return (
      <MDBox display="flex" alignItems="center">
        <DataGridBottomBar
          title={"Total Vuers"}
          totalItems={metaValues.totalElements}
        />
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No vuers found.
        </Typography>
      </MDBox>
    );
  };

  return (
    <MDBox>
      {showExportVuerDialog && getExportVuerDialog()}
      {/* Header */}
      <MDBox display={"flex"} pt={0.6} pb={1.4}>
        <MDBox width="100%" display="flex" maxWidth={"300px"}>
          <TextField
            fullWidth
            placeholder="Search by Vuer ID or Vuer Name"
            sx={searchBarStyle}
            onChange={(event) => {
              setSearchStarted(true);
              setSearchInput(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search fontSize="medium" sx={{ color: "#344767" }} />
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox ml={"auto"}>
          <MDButton
            variant="contained"
            color="success"
            onClick={() => {
              setShowExportVuerDialog(true);
            }}
            sx={{ fontSize: "14px", fontWeight: "bold" }}
          >
            Export
          </MDButton>
        </MDBox>
      </MDBox>
      {/* Grid */}
      <Card sx={surveysGridCardStyle}>
        <DataGridPremium
          apiRef={gridRef}
          columns={renderedColumns}
          rows={rowData}
          disableColumnMenu
          sx={{
            ...gridStyle,
          }}
          density="compact"
          disableRowSelectionOnClick
          slots={{
            footer: getFooter,
            loadingOverlay: LinearProgress,
            noRowsOverlay: getNoRowsMessage,
          }}
          loading={loader}
          sortingMode="server"
          filterMode="server"
          rowHeight={140}
          onRowsScrollEnd={handleOnRowsScrollEnd}
        />
      </Card>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </MDBox>
  );
};

export default VuersDataGrid;
