export const dialogText = {
    fontSize: "14px",
    display: "block",
}
export const buttonHoverStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    background: "rgba(255, 255, 255, 0.65)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "0",
    transition: "all 0.25s ease",
    ":hover": {
      opacity: "1",
    },
  };

  export const vuersGridStyle= {
    ".MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:first-child, .MuiDataGrid-virtualScrollerContent .MuiDataGrid-cell:first-child" : {
      paddingLeft: "20px"
    }
  }

  export const avatarContainerStyle = {
    width: "74px",
    height: "74px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}