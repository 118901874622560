import { List } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  tableConStyle,
  tableRowHeadStyle,
  tableHeadCellStyle,
} from "pages/surveys/styles/SurveyStyles";
import SettingsListItem from "./SettingsListItem";
import { ARDevice } from "../utils/interface";

const SettingsList = ({
  arDevicesList,
  onRefresh,
}: {
  arDevicesList: ARDevice[];
  onRefresh: () => void;
}) => {
  return (
    <>
      <MDBox
        sx={{
          ...tableConStyle,
          position: "sticky",
          top: "0",
          zIndex: "124",
          background: "white",
        }}
      >
        <MDBox sx={tableRowHeadStyle}>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "200px",
            }}
          >
            <MDTypography>Model</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>Brand</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>Name</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "260px",
            }}
          ></MDBox>
        </MDBox>
      </MDBox>
      <List sx={tableConStyle}>
        {arDevicesList.map((device, index) => {
          return (
            <SettingsListItem
              key={index}
              arDevice={device}
              onRefresh={onRefresh}
            />
          );
        })}
      </List>
    </>
  );
};

export default SettingsList;
