import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface Props {
  value: number;
  color?: string;
}

const CustomProgressbar = ({ value, color = "#4CAF50" }: Props) => {
  const progressbarStyles = {
    width: "100%",
    height: "10px",
    borderRadius: "5px",
    background: "#f0f2f5",
    position: "relative",
    marginRight: "10px",
    "&:before": {
      position: "absolute",
      content: '""',
      display: "block",
      height: "100%",
      top: 0,
      left: 0,
      borderRadius: "5px",
      background: color,
      width: `${value}%`,
    },
  };

  return (
    <MDBox display="flex" alignItems="center">
      <MDBox sx={progressbarStyles}></MDBox>
      <MDBox flexShrink={0}>
        <MDTypography variant="button" sx={{fontWeight: "400"}}>{value}%</MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default CustomProgressbar;
