import { List } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  tableConStyle,
  tableRowHeadStyle,
  tableHeadCellStyle,
} from "pages/surveys/styles/SurveyStyles";
import UsersListItem from "./UsersListItem";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";

interface Props {
  orgUsers: UserInfo[];
  companyGroupId: string;
  onRefresh: () => void;
}

const UsersList = ({ orgUsers, companyGroupId, onRefresh }: Props) => {
  return (
    <>
      <MDBox
        sx={{
          ...tableConStyle,
          position: "sticky",
          top: "0",
          zIndex: "124",
          background: "white",
        }}
      >
        <MDBox sx={tableRowHeadStyle}>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>Email</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "140px",
            }}
          >
            <MDTypography>Created on</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "220px",
            }}
          >
            <MDTypography>Created at</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "100px",
            }}
          >
            <MDTypography>Status</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
              flexBasis: "120px",
            }}
          ></MDBox>
        </MDBox>
      </MDBox>
      <List sx={tableConStyle}>
        {orgUsers.map((user) => (
          <UsersListItem
            user={user}
            companyGroupId={companyGroupId}
            onRefresh={onRefresh}
          />
        ))}
      </List>
    </>
  );
};

export default UsersList;
