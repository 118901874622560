import { List } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  tableConStyle,
  tableRowHeadStyle,
  tableHeadCellStyle,
} from "pages/surveys/styles/SurveyStyles";
import AdminsListItem from "./AdminsListItem";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { alphanumericSort } from "utils/helpers/extensions";

const AdminsList = ({
  usersList,
  onResendInvite,
  onRemoveUser,
}: {
  usersList: UserInfo[];
  onResendInvite: (email: string, portal: string) => void;
  onRemoveUser: (userID: string) => void;
}) => {
  return usersList.length > 0 ? (
    <>
      <MDBox
        sx={{
          ...tableConStyle,
          position: "sticky",
          top: "0",
          zIndex: "124",
          background: "white",
        }}
      >
        <MDBox sx={tableRowHeadStyle}>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexBasis: "120px",
              flexGrow: "0",
              flexShrink: "0",
            }}
          >
            <MDTypography>ID</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>Email</MDTypography>
          </MDBox>
          <MDBox
            sx={{
              ...tableHeadCellStyle,
              flexGrow: "0",
              flexShrink: "0",
            }}
          ></MDBox>
        </MDBox>
      </MDBox>
      <List sx={tableConStyle}>
        {usersList
          .sort((a, b) => alphanumericSort(a.canonicalId, b.canonicalId))
          .map((user) => {
            const { id, email } = user;
            return (
              <AdminsListItem
                user={user}
                onResendInvite={(portal: string) => {
                  onResendInvite(email, portal);
                }}
                onRemoveUser={() => {
                  onRemoveUser(id);
                }}
              />
            );
          })}
      </List>
    </>
  ) : (
    <></>
  );
};

export default AdminsList;
