import {
  SurveyConditions,
  SurveyStep,
  SurveyStepConditions,
} from "@ivueit/vue-engine";
import {
  SurveyStepType,
  getSurveyStepType,
} from "pages/dashboard/home/vues/vue_detail/types/types";
import {
  SurveyGroupWithStep,
  VueSurveyData,
} from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import {
  CONTAINS,
  DOES_NOT_EQUALS,
  EQUALS,
  EXCLUDES,
  GREATER_THAN,
  GREATER_THAN_OR_EQUALS,
  GroupColorHexValues,
  LESS_THAN,
  LESS_THAN_OR_EQUALS,
  allOperators,
  supportedConditionTypes,
} from "./constants";
import { ConditionOperator } from "../types/interfaces";

export const hasConditionsOnSurvey = (
  surveyData: VueSurveyData,
  step: SurveyStep
) => {
  let conditionExists = false;
  const conditions = surveyData?.conditions ?? [];
  conditions.forEach((condition) => {
    if (step.id === condition.parentStepId) {
      conditionExists = true;
    }
  });
  return conditionExists;
};

export const hasConditionsOnSurveyStep = (
  surveyData: VueSurveyData,
  step: SurveyStep
) => {
  const stepConditions = surveyData?.stepConditions ?? [];
  let stepConditionExists = false;
  stepConditions.forEach((stepCondition) => {
    if (step.id === stepCondition.childStepId) {
      stepConditionExists = true;
    }
  });
  return stepConditionExists;
};

// Checks whether the group ID exists in any other steps
// Survey group deletion/cleanup should occur when you update the last step
// referring to the group to remove its groupId or when you delete such a step.
export const isGroupOrphaned = (step: SurveyStep, allSteps: SurveyStep[]) => {
  const groupId = step.groupId;
  const isGroupOrphaned =
    groupId &&
    allSteps.filter((thisStep) => thisStep.groupId === groupId).length <= 1;
  return isGroupOrphaned;
};

export const checkConditionExists = (
  conditions: SurveyConditions[],
  step: SurveyStep
) => {
  let conditionExists = false;
  conditions.forEach((condition) => {
    if (step.id === condition.parentStepId) {
      conditionExists = true;
    }
  });
  return conditionExists;
};

export const checkStepConditionExists = (
  stepConditions: SurveyStepConditions[],
  step: SurveyStep
) => {
  let stepConditionExists = false;
  stepConditions.forEach((stepCondition) => {
    if (step.id === stepCondition.childStepId) {
      stepConditionExists = true;
    }
  });
  return stepConditionExists;
};

export const validateSurvey = (
  title: string,
  steps: SurveyStep[],
  companyID: { value: string },
  hasValidCosts: boolean
): string => {
  if (!title) {
    return "Cannot publish a survey without a title.";
  } else if (companyID && companyID.value && !hasValidCosts) {
    return "Cost, Payout, Urgent cost and urgent payout cannot be empty.";
  } else if (steps.length === 0) {
    return "Cannot publish a survey without steps.";
  } else {
    for (let index = 0; index < steps.length; index++) {
      const {
        description,
        stepType,
        options,
        minSelections,
        maxSelections,
        sliderMax,
        sliderMin,
        sliderSegments,
        photoCountMax,
        photoCountMin,
        videoSecondsMax,
        videoSecondsMin,
      } = steps[index];
      const type = getSurveyStepType(stepType);
      const indexVal = index + 1;
      if (!description) {
        return `Cannot Publish Survey: A description is required for step ${indexVal}`;
      } else if (
        type === SurveyStepType.select ||
        type === SurveyStepType.multiSelect
      ) {
        let optionsList;
        if (options) {
          optionsList = JSON.parse(options).options;
        } else {
          optionsList = "";
        }

        if (!optionsList.length) {
          return `Cannot Publish Survey: At least one option required for step ${indexVal}`;
        } else if (type === SurveyStepType.multiSelect) {
          if (!minSelections && minSelections !== 0) {
            return `Cannot Publish Survey: Minimum selection count required for step ${indexVal}. Use 0 if no selection is needed.`;
          } else if (minSelections < 0) {
            return `Cannot Publish Survey: Minimum selections cannot be negative for step " ${indexVal}`;
          } else if (minSelections > optionsList.length) {
            return `Cannot Publish Survey: Minimum selections cannot be greater than number of available options for step ${indexVal}`;
          } else if (!maxSelections && maxSelections !== 0) {
            return `Cannot Publish Survey: Maximum selection count required for step ${indexVal}. Use 0 to allow all selections.`;
          } else if (maxSelections < 0) {
            return `Cannot Publish Survey: Maximum selections cannot be negative for step ${indexVal}`;
          } else if (maxSelections > optionsList.length) {
            return `Cannot Publish Survey: Maximum selections cannot be greater than number of available options for step ${indexVal}`;
          } else if (maxSelections !== 0 && minSelections > maxSelections) {
            return `Cannot Publish Survey: Minimum selection value cannot be greater than maximum for step ${indexVal}`;
          }
        }
      } else if (type === SurveyStepType.slider) {
        if (!sliderMin && sliderMin !== 0) {
          return `Cannot Publish Survey: Slider minimum required for step ${indexVal}`;
        } else if (!sliderMax && sliderMax !== 0) {
          return `"Cannot Publish Survey: Slider maximum required for step ${indexVal}`;
        } else if (!sliderSegments) {
          return `Cannot Publish Survey: Slider segments required for step ${indexVal}`;
        } else if (sliderSegments < 1) {
          return `Cannot Publish Survey: Slider segments must be greater than 0 for step ${indexVal}`;
        } else if (sliderSegments - Math.floor(sliderSegments) !== 0) {
          return `Cannot Publish Survey: Slider segments decimal not permitted for step ${indexVal}`;
        } else if (sliderMin >= sliderMax) {
          return `Cannot Publish Survey: Slider minimum must be less than maximum for step ${indexVal}`;
        }
      } else if (type === SurveyStepType.photo) {
        if (!photoCountMin && photoCountMin !== 0) {
          return `Cannot Publish Survey: Minimum photo count required for step ${indexVal}. Use 0 if no minimum is needed.`;
        } else if (photoCountMin < 0) {
          return `Cannot Publish Survey: Minimum photo count cannot be negative for step ${indexVal}`;
        } else if (!photoCountMax && photoCountMax !== 0) {
          return `Cannot Publish Survey: Maximum photo count required for step ${indexVal}. Use 0 if no maximum is needed.`;
        } else if (photoCountMax < 0) {
          return `Cannot Publish Survey: Maximum photo count cannot be negative for step ${indexVal}`;
        } else if (photoCountMax !== 0 && photoCountMin > photoCountMax) {
          return `Cannot Publish Survey: Minimum photo count cannot be greater than maximum photo count for step ${indexVal}`;
        }
      } else if (type === SurveyStepType.video) {
        if (!videoSecondsMin && videoSecondsMin !== 0) {
          return `Cannot Publish Survey: Minimum video length required for step ${indexVal}. Use 0 if no minimum is needed.`;
        } else if (videoSecondsMin < 0) {
          return `Cannot Publish Survey: Minimum video length cannot be negative for step ${indexVal}`;
        } else if (!videoSecondsMax && videoSecondsMax !== 0) {
          return `Cannot Publish Survey: Maximum video length required for step ${indexVal}. Use 0 if no maximum is needed.`;
        } else if (videoSecondsMax < 0) {
          return `Cannot Publish Survey: Maximum video length cannot be negative for step ${indexVal}`;
        } else if (videoSecondsMax !== 0 && videoSecondsMin > videoSecondsMax) {
          return `Cannot Publish Survey: Minimum video length cannot be greater than maximum video length for step ${indexVal}`;
        }
      }
    }
  }
  return "";
};

// Checks whether the a step link can be added to the given currentStep
// currentStep - defines the currently selected step
// stepToCheck - defines the previous step or step which needs to be verified
export const canLinkTheStep = (
  currentStep: SurveyStep,
  stepToCheck: SurveyStep
) => {
  const stepType = getSurveyStepType(stepToCheck.stepType);
  // Filters the array to the one that supports condition types
  return (
    supportedConditionTypes.includes(stepType) && // Whether the step includes in supported types
    stepToCheck.index < currentStep.index && // stepToCheck should be in an earlier position
    (currentStep.groupId === stepToCheck.groupId ||
      stepToCheck.groupId.isEmpty()) && // Either in same group or no groups
    // Additionally added empty description check to avoid adding empty description to the list
    stepToCheck.description.isNotEmpty()
  );
};

export // generates the supported operator list based on the step type
const getSupportedOperatorList = (
  thisStep: SurveyStep
): ConditionOperator[] => {
  const stepType = getSurveyStepType(thisStep.stepType);
  switch (stepType) {
    case SurveyStepType.multiSelect:
      return [CONTAINS, EXCLUDES];
    case SurveyStepType.select:
      return [EQUALS, DOES_NOT_EQUALS];
    case SurveyStepType.bool:
      return [EQUALS];
    case SurveyStepType.slider:
      return [
        EQUALS,
        DOES_NOT_EQUALS,
        LESS_THAN,
        LESS_THAN_OR_EQUALS,
        GREATER_THAN,
        GREATER_THAN_OR_EQUALS,
      ];
    default:
      // Returns all available operators as default values
      return allOperators;
  }
};

export const generateGroupColor = (groupIndex: number) => {
  let color = "#FFFFFF";
  if (groupIndex !== -1) {
    const colorsList = Object.values(GroupColorHexValues);
    color = colorsList[groupIndex % colorsList.length];
  }
  return color;
};
