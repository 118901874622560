/** 
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React TS components
import Icon from "@mdi/react";
import { mdiAccountBoxMultipleOutline, mdiAccountGroup, mdiCog, mdiHandshake, mdiNoteTextOutline } from "@mdi/js";
import { AddAPhoto, PhotoCamera } from "@mui/icons-material";
import { RoutePath } from "./constants";
import Vues from "pages/dashboard/home/vues/Vues";
import Support from "pages/support/Support";
import Profile from "pages/profile/Profile";
import CreateVues from "pages/dashboard/home/create-vues/CreateVues";
import { UserRoles } from "pages/portal/types/types";
import Surveys from "pages/surveys/Surveys";
import Vuers from "pages/vuers/Vuers";
import Clients from "pages/clients/Clients";
import Users from "pages/users/Users";
import Settings from "pages/settings/Settings";

export const getRoutesList = (fullName: string, profilePic: JSX.Element) => {
  const routes = [
    {
      type: "collapse",
      name: fullName,
      key: "user",
      noCollapse: true,
      icon: profilePic,
      route: RoutePath.profile,
      requiredRole: UserRoles.user,
      component: <Profile />,
    },
    { type: "divider", key: "divider-0" },
    {
      type: "collapse",
      name: "Create Vues",
      key: "create-vues",
      icon: <AddAPhoto sx={{ fontSize: "1.2rem !important" }} />,
      route: RoutePath.createVues,
      requiredRole: UserRoles.manager,
      component: <CreateVues />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Vues",
      key: "vues",
      icon: <PhotoCamera sx={{ fontSize: "1.2rem !important" }} />,
      route: RoutePath.vues,
      requiredRole: UserRoles.user,
      component: <Vues />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Surveys",
      key: "surveys",
      icon: <Icon path={mdiNoteTextOutline} size={0.8} />,
      route: RoutePath.surveys,
      requiredRole: UserRoles.user,
      component: <Surveys />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Vuers",
      key: "vuers",
      icon: <Icon path={mdiAccountGroup} size={0.8} />,
      route: RoutePath.vuers,
      requiredRole: UserRoles.user,
      component: <Vuers />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Clients",
      key: "clients",
      icon: <Icon path={mdiAccountBoxMultipleOutline} size={0.8} />,
      route: RoutePath.clients,
      requiredRole: UserRoles.user,
      component: <Clients />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Users",
      key: "users",
      icon: <Icon path={mdiAccountBoxMultipleOutline} size={0.8} />,
      route: RoutePath.users,
      requiredRole: UserRoles.user,
      component: <Users />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Settings",
      key: "settings",
      icon: <Icon path={mdiCog} size={0.8} />,
      route: RoutePath.settings,
      requiredRole: UserRoles.user,
      component: <Settings />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Support",
      key: "support",
      icon: <Icon path={mdiHandshake} size={0.8} />,
      route: RoutePath.support,
      requiredRole: UserRoles.user,
      component: <Support />,
      noCollapse: true,
    },
  ];
  return routes;
};
