import { DialogContent, DialogActions, Portal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomRadioComponent from "pages/dashboard/components/CustomRadioComponent";
import { dialogText } from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";
import { Portals } from "../utlis/interface";

interface Props {
  email: string;
  closeDialog: () => void;
  onInvite: (portal: string) => void;
}

const InviteDialogContent = (props: Props) => {
  const [selectedPortal, setSelectedPortal] = useState<string>("");

  return (
    <>
      <DialogContent sx={{ padding: "20px 16px !important" }}>
        <MDTypography
          variant="body"
          sx={{ ...dialogText, marginBottom: "10px" }}
        >
          Email invitation will be send to :
          <span style={{ fontWeight: "500", marginLeft: "5px" }}>
            {props.email}
          </span>
        </MDTypography>
        <MDBox mt={2}>
          <MDTypography
            variant="body"
            sx={{ ...dialogText, marginBottom: "10px" }}
          >
            Select portal to send the Email invitation
          </MDTypography>
        </MDBox>
        <MDBox px={4}>
          <CustomRadioComponent
            label={Portals.admin}
            checked={
              selectedPortal.toLowerCase() === Portals.admin.toLowerCase()
            }
            handleChange={() => {
              setSelectedPortal(Portals.admin);
            }}
          />
          <CustomRadioComponent
            label={Portals.client}
            checked={
              selectedPortal.toLowerCase() === Portals.client.toLowerCase()
            }
            handleChange={() => {
              setSelectedPortal(Portals.client);
            }}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={props.closeDialog}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CLOSE
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={selectedPortal.isEmpty()}
            onClick={() => {
              props.onInvite(selectedPortal);
            }}
            sx={{ fontSize: "14px", padding: "11px 22px" }}
          >
            SEND
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default InviteDialogContent;
