import { GridColDef } from "@mui/x-data-grid-premium";

const clientColumnList = [
  {
    field: "canonicalId",
    header: "Client ID",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 150,
    },
  },
  {
    field: "company",
    header: "Company",
    config: {
      width: 350,
      minWidth: 300,
      maxWidth: 400,
    },
  },
  { field: "accountManager", header: "Account Manager" },
  { field: "archived", header: "Archived" },
];

// Generating default column data for clients grid
export const clientsGridColumnData: GridColDef[] = clientColumnList.map((column) => {
  let columnData = {
    field: column.field,
    headerName: column.header.toUpperCase(),
    sortable: false,
    editable: false,
    flex: 1,
  };

  if (column.config) {
    columnData = { ...columnData, ...column.config };
  }

  return columnData;
});
