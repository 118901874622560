import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";
import { createCompanyGroup } from "../services/services";
import { GENERIC_ERROR_MESSAGE, WebServiceStatus } from "@ivueit/vue-engine";
import { CompanyGroup } from "pages/profile/utils/ProfileInterfaces";
import { CustomIndicator } from "pages/components/CustomIndicator";

interface Props {
  closeDialog: (groupCreated: boolean) => void;
}

const CreateGroupDialogContent = (props: Props) => {
  const [name, setName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [loader, setLoader] = useState(false);
  const hasValidData = () => {
    if (name.trim().isEmpty() || groupDescription.trim().isEmpty()) {
      setSnackBarContent({
        title: "Attention!",
        message: "All fields are required",
        isError: true,
      });

      return false;
    }
    return true;
  };

  const onCreateCompanyClick = async () => {
    if (hasValidData()) {
      setLoader(true);
      const response = await createCompanyGroup(name, groupDescription);
      if (response.status === WebServiceStatus.success) {
        const data = response.data as CompanyGroup;
        if (data && data.id) {
          setLoader(false);
          props.closeDialog(true);
          return;
        }
      }
      setLoader(false);
      // Error occurred
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
  };

  return (
    <>
      <DialogContent>
        {loader && <CustomIndicator />}
        <MDBox px={1.4} pt={2}>
          <MDBox flex={1} mb={2}>
            <MDBox display="flex">
              <MDTypography sx={regularTextStyle}>Name</MDTypography>
              <RequiredFieldMarker />
            </MDBox>
            <MDInput
              placeholder="Enter Name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              fullWidth
              value={name}
            />
          </MDBox>
          <MDBox flex={1}>
            <MDBox display="flex">
              <MDTypography sx={regularTextStyle}>Description</MDTypography>
              <RequiredFieldMarker />
            </MDBox>
            <MDInput
              placeholder="Enter Description"
              multiline
              rows={3}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setGroupDescription(event.target.value);
              }}
              value={groupDescription}
              fullWidth
            />
          </MDBox>
        </MDBox>
        <CustomSnackbar
          snackbarContent={snackBarContent}
          onClose={() => {
            setSnackBarContent(null);
          }}
        />
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog(false);
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
            disabled={loader}
          >
            CLOSE
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              onCreateCompanyClick();
            }}
            sx={{ fontSize: "14px", padding: "11px 22px" }}
            disabled={loader}
          >
            CREATE
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default CreateGroupDialogContent;
