import { useEffect, useState } from "react";
import { AttachmentItem } from "./AttachmentItem";
import { downloadFile } from "pages/profile/services/ProfileServices";
import { CustomIndicator } from "pages/components/CustomIndicator";
import {
  DownloadFile,
  TextFileViewer,
  createFileObject,
  downloadFileToDevice,
  photoViewerStyle,
} from "@ivueit/vue-engine";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import MDButton from "components/MDButton";
import FileViewer from "react-file-viewer-extended";
import MessageAlert from "pages/authentication/components/MessageAlert";
import { InfoOutlined } from "@mui/icons-material";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";

interface FileProps {
  id: number;
  file: File;
  path: string;
  type: string;
}

interface Props {
  closeDialog: () => void;
  listOfFileIds: string[];
}

export const ViewAttachmentsDialogContent = (props: Props) => {
  const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
  const [attachmentsList, setAttachmentsList] = useState<FileProps[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileProps>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  /// Handles the onClick of "Preview" button
  const handlePreviewButtonClick = (attachment: FileProps) => {
    setSelectedFile(attachment);
    setOpenPreviewDialog(true);
  };

  /// Handles the onClick of "Download" button
  const handleDownloadButtonClick = async (attachment: FileProps) => {
    const { path, file } = attachment;
    await downloadFileToDevice(path, file.name, file.type);
  };

  const getMessageAlert = () => {
    return (
      <MessageAlert message={error} type="error" icon={<InfoOutlined />} />
    );
  };

  /// Preview Dialog box
  const getPreviewDialog = () => {
    const { file, type, path } = selectedFile;
    return (
      <Dialog open={true} onClose={null} maxWidth={false}>
        <DialogContent
          sx={{
            ...photoViewerStyle,
            pt: "30px",
            pb: "30px",
            borderBottom: "1px solid rgb(168, 184, 216)",
            minWidth: "500px",
            minHeight: "500px",
          }}
        >
          {type === "txt" ? (
            <TextFileViewer file={file} />
          ) : (
            <FileViewer
              fileType={type}
              filePath={path}
              onError={() => {
                console.log(
                  "Unable to load file. Please contact iVueit support if the issue persists."
                );
              }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ pt: "15px" }}>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => {
              setOpenPreviewDialog(false);
            }}
          >
            CLOSE
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  };

  /// Generating attachments from the file IDs
  useEffect(() => {
    const generateAttachmentsFromId = async () => {
      setShowLoader(true);
      const promises = props.listOfFileIds.map(async (id, index) => {
        const response = await downloadFile(id);
        if (response) {
          const responseData = response as DownloadFile;
          const { filename, mimeType, data } = responseData;
          const type = filename.split(".").pop();
          const file = createFileObject(filename, mimeType, data);
          const path = URL.createObjectURL(file);
          const fileWithProps: FileProps = {
            id: index,
            path: path,
            file: file,
            type: type,
          };
          setShowLoader(false);
          return fileWithProps;
        } else {
          setShowLoader(false);
          return null;
        }
      });
      const listOfFiles = await Promise.all(promises);
      const filesDownloaded = listOfFiles.filter((file) => file !== null);
      if (listOfFiles.includes(null)) {
        setError("One or more attachments failed to download.");
      }
      setAttachmentsList(filesDownloaded);
    };
    generateAttachmentsFromId();
  }, [props.listOfFileIds]);

  return (
    <>
      <DialogContent>
        {openPreviewDialog && getPreviewDialog()}
        {showLoader && <CustomIndicator />}
        {error && getMessageAlert()}
        {attachmentsList.length ? (
          attachmentsList.map((attachment, index) => {
            const { file } = attachment;
            return (
              <AttachmentItem
                key={index}
                slNum={index + 1}
                fileName={file.name}
                handlePreviewButtonClick={() => {
                  handlePreviewButtonClick(attachment);
                }}
                handleDownloadButtonClick={() => {
                  handleDownloadButtonClick(attachment);
                }}
              />
            );
          })
        ) : (
          <PlaceholderComponent
            label={"Loading Attachments..."}
            fontSize="20px"
          />
        )}
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          color="info"
          size="medium"
          onClick={props.closeDialog}
        >
          CLOSE
        </MDButton>
      </DialogActions>
    </>
  );
};
