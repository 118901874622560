import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { regularTextStyle } from "../styles/SurveyStyles";
import {
  SurveyStep,
  SurveyStepType,
  getBodyContent,
  getSurveyStepType,
} from "@ivueit/vue-engine";

interface Props {
  step: SurveyStep;
}

export const CustomPreviewComponent = ({ step }: Props) => {
  
  // Returns true when slider step has valid values
  const isValidSliderStep = () => {
    const { sliderMin, sliderMax, sliderSegments } = step;
    const isValidMin =
      !isNaN(sliderMin) && sliderMin >= 0 && sliderMin < sliderMax;
    const isValidMax =
      !isNaN(sliderMax) && sliderMax > 0 && sliderMin < sliderMax;
    const isValidSegment = !isNaN(sliderSegments) && sliderSegments > 0;

    return isValidMin && isValidMax && isValidSegment && sliderMax > sliderMin;
  };

  const isValidPhotoStep = () => {

    const {photoCountMin, photoCountMax} = step;

  }

  // Checks whether the step is valid or not
  const isValidStep = () => {
    const stepType = getSurveyStepType(step.stepType);
    switch (stepType) {
      case SurveyStepType.slider:
        return isValidSliderStep();
      default:
    }
    return true;
  };

  return (
    <MDBox pt={1} pb={1}>
      <MDTypography sx={regularTextStyle}>Preview</MDTypography>
      <MDBox
        mt={1}
        p={3}
        bgColor="whitesmoke"
        sx={{
          minHeight: "50px",
          width: "100%",
          borderRadius: "5px",
        }}
      >
        {isValidStep() ? (
          getBodyContent(step)
        ) : (
          <MDTypography sx={regularTextStyle}>
            Unable to display the preview. Please verify the step values
            entered.
          </MDTypography>
        )}
      </MDBox>
    </MDBox>
  );
};
