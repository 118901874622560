import { GridColDef } from "@mui/x-data-grid-premium";

const userGridColumnList = [
  {
    field: "name",
    header: "Name",
    config: {
      width: 200,
      minWidth: 200,
      maxWidth: 300,
    },
  },
  {
    field: "description",
    header: "Description",
    config: {
      width: 350,
      minWidth: 300,
      maxWidth: 400,
    },
  },
  { field: "createdAt", header: "Created At" },
  { field: "active", header: "Active" },
];

// Generating default column data for clients grid
export const usersGridColumnData: GridColDef[] = userGridColumnList.map(
  (column) => {
    let columnData = {
      field: column.field,
      headerName: column.header.toUpperCase(),
      sortable: false,
      editable: false,
      flex: 1,
    };

    if (column.config) {
      columnData = { ...columnData, ...column.config };
    }

    return columnData;
  }
);
