import DashboardContentLayout from "layouts/DashboardContentLayout";
import SettingsDataGrid from "./components/SettingsDataGrid";

const Settings = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <SettingsDataGrid />
    </DashboardContentLayout>
  );
};

export default Settings;
