import { autoCompleteStyle } from "@ivueit/vue-engine";
import { Autocomplete, Grid } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import WorkIcon from "@mui/icons-material/Work";

interface Props {
  onOpen: () => void;
  optionsList: string[];
  noOptionsText: string;
  value: string;
  inputValue: string;
  onOptionSelect: (
    event: React.SyntheticEvent,
    selectedValue: string,
    reason: string
  ) => void;
  onInputChange: (
    event: React.SyntheticEvent,
    newValue: string,
    reason: string
  ) => void;
  placeholderText: string;
  icon?: string;
}

export const CustomAutoCompleteComponent = (props: Props) => {
  const {
    onOpen,
    optionsList,
    noOptionsText,
    value,
    inputValue,
    onInputChange,
    onOptionSelect,
    placeholderText,
    icon,
  } = props;
  return (
    <Autocomplete
      filterOptions={(x) => x}
      onOpen={onOpen}
      options={optionsList}
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => {
        const data = option as string;
        return data ? data : "";
      }}
      value={value}
      inputValue={inputValue}
      sx={{
        ...autoCompleteStyle,
        ".MuiOutlinedInput-root": {
          paddingTop: "2px !important",
          paddingBottom: "2px !important",
        },
      }}
      onChange={(
        event: React.SyntheticEvent,
        selectedValue: string,
        reason: string
      ) => {
        onOptionSelect(event, selectedValue, reason);
      }}
      onInputChange={(
        event: React.SyntheticEvent,
        newValue: string,
        reason: string
      ) => {
        onInputChange(event, newValue, reason);
      }}
      fullWidth
      renderInput={(params) => (
        <MDInput
          {...params}
          fullWidth
          placeholder={placeholderText}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              {icon && (
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <WorkIcon sx={{ color: "text.secondary" }} />
                </Grid>
              )}
              <Grid
                item
                sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word",
                }}
              >
                <MDTypography sx={{ fontWeight: 400, fontSize: 14 }}>
                  {option}
                </MDTypography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
