import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MDBox from "components/MDBox";
import {
  customListsyle,
  groupTitleStyle,
  listGroup,
} from "../styles/SurveyStyles";
import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { SurveyStep } from "@ivueit/vue-engine";
import { SurveyGroupWithStep } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import { GroupColorHexValues } from "../utils/constants";
import { generateGroupColor } from "../utils/helpers";

interface Props {
  onDrag: (
    stepId: string,
    sourceIndex: number,
    destinationIndex: number
  ) => void;
  stepList: SurveyStep[];
  groups: SurveyGroupWithStep[];
  onGroupNameEdit: (step: SurveyStep) => void;
  onClickListItem: () => void;
}

export const DraggableList = (props: Props) => {
  return (
    <DragDropContext
      onDragEnd={(result: any) => {
        const { draggableId, destination, source } = result;
        props.onDrag(draggableId, source.index, destination.index);
      }}
    >
      <Droppable droppableId={"droppable"}>
        {(provided: any) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ ...customListsyle, ...listGroup }}
          >
            {props.stepList.map((step, indexVal) => {
              const { id, index, groupId, stepType, description } = step;
              const type = stepType === "BOOL" ? "YES/NO" : stepType;
              const serialNumber = (indexVal + 1).toString();
              const filteredGroup = props.groups.filter(
                (group) => group.id === groupId
              );
              const groupName =
                filteredGroup.length > 0 ? filteredGroup[0].groupTitle : "";
              return (
                <Draggable key={id} draggableId={id} index={indexVal}>
                  {(provided: any) => {
                    const stepIndex = parseInt(index);
                    const previousStep = props.stepList[stepIndex - 1];
                    const nextStep = props.stepList[stepIndex + 1];
                    const hasDifferentGroupId =
                      groupId &&
                      stepIndex > 0 &&
                      groupId !== previousStep.groupId;
                    const groupIndex = groupId
                      ? props.groups.findIndex((group) => group.id === groupId)
                      : -1;
                    const groupColor = generateGroupColor(groupIndex);
                    return (
                      <MDBox
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {((stepIndex === 0 && groupId) ||
                          hasDifferentGroupId) && (
                          <ListItem
                            sx={{
                              ...groupTitleStyle,
                              borderLeftWidth: "5px",
                              borderLeftColor: groupColor,
                              borderLeftStyle: "solid",
                              marginTop: "15px",
                            }}
                          >
                            <ListItemText primary={groupName} />
                            <MDBox ml="auto">
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  props.onGroupNameEdit(step);
                                }}
                              >
                                <Icon path={mdiPencil} size={0.75} />
                              </IconButton>
                            </MDBox>
                          </ListItem>
                        )}
                        <ListItem
                          disablePadding
                          sx={{
                            borderLeftWidth: "5px",
                            borderLeftColor: groupColor,
                            borderLeftStyle: "solid",
                            marginBottom:
                              nextStep && nextStep.groupId !== groupId
                                ? "15px"
                                : "0px",
                          }}
                        >
                          <ListItemButton onClick={props.onClickListItem}>
                            <ListItemIcon>{`${serialNumber}.`}</ListItemIcon>
                            <MDBox minWidth={0}>
                              <ListItemText primary={type} />
                              <ListItemText secondary={description} />
                            </MDBox>
                          </ListItemButton>
                        </ListItem>
                      </MDBox>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};
