import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ReactNode } from "react";

export enum TagsQuestionContainerType {
  question,
  answer,
}

interface Props {
  count: number;
  question: string;
  children: ReactNode;
  type: TagsQuestionContainerType;
}

const TagsQuestionContainer = ({
  count,
  question,
  children,
}: Props): JSX.Element => {
  return (
    <MDBox mb={1.8} border="2px solid #DEE2E8;" borderRadius="8PX">
      <MDBox bgColor="#F0F2F5" borderRadius="6px 6px 0 0" px={2} py={0.8}>
        <MDTypography variant="h6">
          {count}. {question}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>{children}</MDBox>
    </MDBox>
  );
};

export default TagsQuestionContainer;
