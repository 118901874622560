import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { labelStyle } from "../styles/SurveyStyles";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { useState } from "react";
import {
  applyQuestionTagToStep,
  removeQuestionTagFromStep,
} from "../services/SurveyServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { INVALID_JSON_ERROR_FROM_API } from "../utils/constants";

const TagsQuestionContent = ({
  value,
  stepID,
  questionID,
  onSuccess,
}: {
  value: string;
  questionID: string;
  stepID: string;
  onSuccess: () => void;
}) => {
  const [questionTag, setQuestionTag] = useState<string>(value);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);

  const disableSaveButton =
    value.trim().toLowerCase() === questionTag.trim().toLowerCase();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuestionTag(value);
  };
  

  const addOrUpdateQuestionTag = async () => {
    const param = { meta: questionTag };
    setShowLoader(true);
    const response = await applyQuestionTagToStep(stepID, param);
    if (response.status === WebServiceStatus.success) {
      onSuccess();
    } else {
      const error = response.error;
      const hasInvalidJSON = error.includes(INVALID_JSON_ERROR_FROM_API);
      setSnackBar({
        title: "Attention!",
        message: hasInvalidJSON
          ? "Invalid JSON, please correct and try again"
          : "Unable to add/update question tag.",
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const deleteQuestionTag = async () => {
    const param = { questionTagId: questionID };
    setShowLoader(true);
    const response = await removeQuestionTagFromStep(stepID, param);
    if (response.status === WebServiceStatus.success) {
      onSuccess();
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to delete question tag.",
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const handleSaveQuestionTag = () => {
    if (questionTag) {
      addOrUpdateQuestionTag();
    } else {
      deleteQuestionTag();
    }
  };

  return (
    <>
      <MDBox>
        <MDTypography sx={labelStyle}>Question Tag</MDTypography>
        <MDInput
          value={questionTag}
          placeholder="Enter Question Tag"
          fullWidth
          multiline
          rows={3}
          onChange={handleInputChange}
        />
        <MDBox mt={2.2}>
          <StyledButtonWithIcon
            disabled={disableSaveButton}
            onClick={handleSaveQuestionTag}
          >
            SAVE
          </StyledButtonWithIcon>
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default TagsQuestionContent;
