import { StepActionType, getTitleForType } from "../types/enums";
import MDBox from "components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { HeaderStepSection } from "./HeaderStepSection";
import { SurveyStepType } from "pages/dashboard/home/vues/vue_detail/types/types";
import { CustomPreviewComponent } from "./CustomPreviewComponent";
import MDInput from "components/MDInput";
import { WebServiceStatus, SurveyStep } from "@ivueit/vue-engine";
import { TEXTFIELD_CHANGE_DELAY } from "../../../constants";
import { updateSurveyTemplateStep } from "../services/SurveyServices";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  hasConditionsOnSurvey,
  hasConditionsOnSurveyStep,
  isGroupOrphaned,
} from "../utils/helpers";
import { SurveyGroupWithStep, VueSurveyData } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";

interface Props {
  serialNumber: string;
  componentType: SurveyStepType;
  step: SurveyStep;
  groupName: string;
  hasBeenGrouped: boolean;
  hasConditionsApplied: boolean;
  showAddConditionButton: boolean;
  onSuccess: (step: SurveyStep) => void;
  onDeleteGroup: (id: string) => void;
  onHeaderClick: (type: StepActionType) => void;
  groupIndex: number;
  surveyData: VueSurveyData;
}
const SimpleTextComponent = (props: Props) => {
  const { serialNumber, componentType, step, hasBeenGrouped } = props;
  const [textStep, setTextStep] = useState<SurveyStep>(step);
  const [delayTypeAction, setDelayTypeAction] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  // Currently unused
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const { id, description, stepType } = textStep;
  const [error, setError] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextStep({ ...textStep, [event.target.name]: value });
    setError(!value ? "Prompt cannot be empty" : "");
    setDelayTypeAction(value.isNotEmpty());
  };

  const handleUpdateSurveyStep = useCallback(async () => {
    if (id.isEmpty()) {
      return;
    }
    setShowLoader(true);
    const response = await updateSurveyTemplateStep(id, textStep);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as SurveyStep;
      props.onSuccess(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to update ${stepType.toLowerCase()} step.`,
        isError: true,
      });
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textStep, id]);

  const updateGroupIdForStep = () => {
    // Updating the groupId for this step
    setTextStep({ ...textStep, groupId: "" });
    setDelayTypeAction(true);
  };

  const deleteGroup = () => {
    const hasSurveyConditions = hasConditionsOnSurvey(props.surveyData, step);
    const hasStepConditions = hasConditionsOnSurveyStep(props.surveyData, step);
    // TODO - Allow/deny based on specific scenaario, for now deny all
    // when ungrouping, stepcondition could contain condition from step in group, if so deny
    // always deny if parent step of condition, as only other steps of same group could be linked
    if (hasSurveyConditions || hasStepConditions) {
      setSnackBar({
        title: "Attention!",
        message: "This step is linked to another, cannot ungroup step.",
        isError: true,
      });
      return;
    }
    const islastStepInTheGroup = isGroupOrphaned(
      step,
      props.surveyData.survey.steps
    );
    const groupID = islastStepInTheGroup ? textStep.groupId : "";
    if (groupID) {
      // Removing the group if the group is orphaned
      props.onDeleteGroup(groupID);
    }
    updateGroupIdForStep();
  };

  useEffect(() => {
    if (delayTypeAction) {
      const delaySearchAction = setTimeout(() => {
        handleUpdateSurveyStep();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
  }, [handleUpdateSurveyStep, delayTypeAction]);

  return (
    <MDBox p={2}>
      <HeaderStepSection
        stepNumber={serialNumber}
        groupName={props.groupName}
        stepType={getTitleForType(componentType)}
        isGrouped={hasBeenGrouped}
        onButtonClick={(type: StepActionType) => {
          type === StepActionType.ungroup
            ? deleteGroup()
            : props.onHeaderClick(type);
        }}
        hasConditionApplied={props.hasConditionsApplied}
        showAddConditionButton={props.showAddConditionButton}
        groupIndex={props.groupIndex}
      />
      <MDBox
        border="1px solid #C7CCD0"
        p="16px 16px 20px 16px"
        borderRadius="8px"
      >
        <MDBox mb={1}>
          <MDInput
            value={description}
            name="description"
            fullWidth
            error={error}
            placeholder="Enter Prompt"
            multiline
            rows={3}
            onChange={handleInputChange}
          />
        </MDBox>
        <MDBox>
          <CustomPreviewComponent step={textStep} />
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};

export default SimpleTextComponent;
