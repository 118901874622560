import { SurveyStep } from "@ivueit/vue-engine";
import { SurveyStepType } from "pages/dashboard/home/vues/vue_detail/types/types";

export const getTitleForType = (type: SurveyStepType) => {
  switch (type) {
    case SurveyStepType.text:
      return "Text";
    case SurveyStepType.photo:
      return "Photo";
    case SurveyStepType.video:
      return "Video";
    case SurveyStepType.bool:
      return "Yes/No";
    case SurveyStepType.measurement:
      return "Measurement";
    case SurveyStepType.instructions:
      return "Instruction";
    case SurveyStepType.signature:
      return "Signature";
    case SurveyStepType.select:
      return "Select";
    case SurveyStepType.slider:
      return "Slider";
    case SurveyStepType.multiSelect:
      return "MultiSelect";
    default:
      return "";
  }
};

export enum StepActionType {
  group,
  ungroup,
  link,
  delete,
}

export interface SurveyAPIMetaData {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalElements: number;
}

export enum DeviceOrientation {
  either = "EITHER",
  landScape = "LANDSCAPE",
  portrait = "PORTRAIT",
}

export const getDeviceOrientationValue = (type: string) => {
  switch (type.toUpperCase()) {
    case DeviceOrientation.either:
      return 0;
    case DeviceOrientation.landScape:
      return 1;
    case DeviceOrientation.portrait:
      return 2;
    default:
      break;
  }
};

export interface SurveyGroupResponse {
  id: string;
  groupTitle: string;
  minInstances: number;
  maxInstances: number;
}

// INTERFACES FOR TAGS SECTION

export interface QuestionTag {
  id: string;
  associatedSurveyStepId: string;
  meta: string;
}

export interface AnswerTag {
  id: string;
  associatedQuestionTagId: string;
  possibleAnswer: string;
  meta: string;
  originalOption: boolean;
}

export interface QuestionTagsWithAnswerTags {
  questionTag: QuestionTag;
  answerTags: AnswerTag[];
  answerTagsAreImmutable: boolean;
}

export interface SurveyTags {
  surveyTemplateId: string;
  questionTagsWithAnswerTags: QuestionTagsWithAnswerTags[];
}

export interface SurveyStepWithTags extends SurveyStep {
  surveyTemplateId: string;
  questionTagsWithAnswerTags?: QuestionTagsWithAnswerTags;
  possibleOptions?: string[];
}
