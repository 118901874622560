import {
  mdiAccountCircle,
  mdiCreditCardMultipleOutline,
  mdiListBoxOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Tabs, Tab, AppBar } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import { useState, useEffect } from "react";

export enum VuersTabType {
  VuerDetails,
  transactions,
  submissions,
}

interface Props {
  onTabChange: (tab: VuersTabType) => void;
}

const VuersTabs = ({ onTabChange }: Props) => {
  const [tabsOrientation, setTabsOrientation] = useState<
    "horizontal" | "vertical"
  >("horizontal");
  const [tabValue, setTabValue] = useState<VuersTabType>(
    VuersTabType.VuerDetails
  );

  /// A function that sets the orientation state of the tabs.
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsOrientation]);

  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
    onTabChange(newValue);
  };

  return (
    <AppBar position="static">
      <Tabs
        orientation={tabsOrientation}
        value={tabValue}
        onChange={handleSetTabValue}
      >
        <Tab
          label="Vuer"
          icon={<Icon path={mdiAccountCircle} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 0 ? 14 : 16 }}
        />
        <Tab
          label="Transactions"
          icon={<Icon path={mdiCreditCardMultipleOutline} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 1 ? 14 : 16 }}
        />
        <Tab
          label="Vue Submissions"
          icon={<Icon path={mdiListBoxOutline} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 2 ? 14 : 16 }}
        />
      </Tabs>
    </AppBar>
  );
};

export default VuersTabs;
