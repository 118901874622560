import { mdiTextBoxCheck, mdiTooltipQuestionOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Tabs, Tab, AppBar } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import { useState, useEffect } from "react";

export enum EditTagsTabType {
  questionTags,
  answerTags,
}

interface Props {
  onTabChange: (tab: EditTagsTabType) => void;
  disableAnswerTab: boolean;
}

const EditTagsTabs = ({ onTabChange, disableAnswerTab }: Props) => {
  const [tabsOrientation, setTabsOrientation] = useState<
    "horizontal" | "vertical"
  >("horizontal");
  const [tabValue, setTabValue] = useState<EditTagsTabType>(
    EditTagsTabType.questionTags
  );

  /// A function that sets the orientation state of the tabs.
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsOrientation]);

  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
    onTabChange(newValue);
  };

  return (
    <AppBar position="static">
      <Tabs
        orientation={tabsOrientation}
        value={tabValue}
        onChange={handleSetTabValue}
      >
        <Tab
          label="Question Tags"
          icon={<Icon path={mdiTooltipQuestionOutline} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 0 ? 14 : 16 }}
        />
        <Tab
          label="Answer Tags"
          disabled={disableAnswerTab}
          icon={<Icon path={mdiTextBoxCheck} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 1 ? 14 : 16 }}
        />
      </Tabs>
    </AppBar>
  );
};

export default EditTagsTabs;
