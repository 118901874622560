import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import {
  tableCellStyle,
  tableRowStyle,
} from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";
import InviteDialogContent from "./InviteDialogContent";
import RemoveDialogContent from "./RemoveDialogContent";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";

const AdminsListItem = ({
  user,
  onResendInvite,
  onRemoveUser,
}: {
  user: UserInfo;
  onResendInvite: (portal: string) => void;
  onRemoveUser: () => void;
}) => {
  const [openResendDialog, setOpenResendDialog] = useState<boolean>(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);

  const getResendDialog = () => {
    return (
      <CustomDialogBox
        title={"Resend Invite"}
        width="540px"
        openDialog={openResendDialog}
      >
        <InviteDialogContent
          closeDialog={() => {
            setOpenResendDialog(false);
          }}
          email={user.email}
          onInvite={(portal: string) => {
            onResendInvite(portal);
            setOpenResendDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getRemoveDialog = () => {
    return (
      <CustomDialogBox
        title={"Confirm user deletion"}
        width="540px"
        openDialog={openRemoveDialog}
      >
        <RemoveDialogContent
          email={user.email}
          closeDialog={() => {
            setOpenRemoveDialog(false);
          }}
          onRemove={()=>{
            onRemoveUser();
            setOpenRemoveDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  return (
    <>
      {openResendDialog && getResendDialog()}
      {openRemoveDialog && getRemoveDialog()}
      <MDBox sx={tableRowStyle}>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexBasis: "120px",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <CustomBadge content={`#${user.canonicalId}`} />
        </MDBox>
        <MDBox sx={tableCellStyle}>
          <MDTypography sx={{ color: "#7b809a" }}>{user.email}</MDTypography>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <MDBox display="flex">
            <MDButton
              variant="outlined"
              color="primary"
              sx={{ fontSize: "14px", fontWeight: "bold" }}
              onClick={() => {
                setOpenResendDialog(true);
              }}
            >
              RESEND
            </MDButton>
            <MDButton
              variant="outlined"
              color="error"
              sx={{ fontSize: "14px", fontWeight: "bold", ml: 1 }}
              onClick={() => {
                setOpenRemoveDialog(true);
              }}
            >
              REMOVE
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default AdminsListItem;
