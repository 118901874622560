import { UserRoles } from "pages/portal/types/types";

interface Props {
  userRole: UserRoles;
  requiredRole: UserRoles;
}

export const hasMinimumRequiredRole = ({
  userRole,
  requiredRole,
}: Props): boolean => {
  switch (userRole) {
    case UserRoles.superAdmin:
      // Access to everything
      return true;
    case UserRoles.admin:
      // Access to everything
      return requiredRole !== UserRoles.superAdmin;
    case UserRoles.manager:
      // Access to non admin items
      return (
        requiredRole !== UserRoles.superAdmin &&
        requiredRole !== UserRoles.admin
      );
    case UserRoles.user:
      // Access to non admin/manager items
      return (
        requiredRole !== UserRoles.superAdmin &&
        requiredRole !== UserRoles.admin &&
        requiredRole !== UserRoles.manager
      );
    case UserRoles.noAccess:
      return false;
    default:
      return false;
  }
};
