import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { labelStyle } from "../styles/SurveyStyles";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { useState } from "react";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { WebServiceStatus } from "@ivueit/vue-engine";
import {
  addAnswerTagToQuestionTag,
  removeAnswerTag,
  updateAnswerTag,
} from "../services/SurveyServices";
import { INVALID_JSON_ERROR_FROM_API } from "../utils/constants";
import { SurveyStepWithTags } from "../types/enums";

interface Props {
  count: number;
  steps: SurveyStepWithTags[];
  possibleAnswer: string;
  meta: string;
  questionID: string;
  answerID: string;
  onSuccess: () => void;
  originalOption: boolean;
  stepIndex: number;
  answerIndex: number;
}

const TagsAnswerContent = (props: Props) => {
  const {
    possibleAnswer,
    meta,
    count,
    questionID,
    answerID,
    onSuccess,
    originalOption,
  } = props;
  const [answerTag, setAnswerTag] = useState<string>(meta);
  const [answerValue, setAnswerValue] = useState<string>(possibleAnswer);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);
  const [disableSave, setDisableSave] = useState<boolean>(true);

  const handleAnswerValueInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setAnswerValue(value);

    if (!originalOption) {
      const disable =
        value.trim().isEmpty() ||
        possibleAnswer.trim().toLowerCase() === value.trim().toLowerCase();
      setDisableSave(disable);
    }
  };

  const handleAnswerTagInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setAnswerTag(value);

    const disable =
      meta.trim().toLowerCase() === value.trim().toLowerCase() ||
      answerValue.trim().isEmpty();
    setDisableSave(disable);
  };

  const verifyIsAnswerUnique = () => {
    const { steps, stepIndex, answerIndex } = props;
    let answerIsUnique = true;
    steps[stepIndex].questionTagsWithAnswerTags.answerTags.forEach(
      (answer, index) => {
        if (answer.possibleAnswer === answerValue && answerIndex !== index) {
          answerIsUnique = false;
          setSnackBar({
            title: "Attention!",
            message: "Answer already exists. Cannot add duplicate.",
            isError: true,
          });
        }
      }
    );
    return answerIsUnique;
  };

  const addAnswer = async () => {
    if (verifyIsAnswerUnique()) {
      const param = { meta: answerTag, possibleAnswer: answerValue };
      setShowLoader(true);
      const response = await addAnswerTagToQuestionTag(questionID, param);
      if (response.status === WebServiceStatus.success) {
        onSuccess();
      } else {
        const error = response.error;
        const hasInvalidJSON = error.includes(INVALID_JSON_ERROR_FROM_API);
        setSnackBar({
          title: "Attention!",
          message: hasInvalidJSON
            ? "Invalid JSON, please correct and try again"
            : "Unable to add/update answer tag.",
          isError: true,
        });
      }
      setShowLoader(false);
    }
  };

  const updateAnswer = async () => {
    if (verifyIsAnswerUnique()) {
      const param = { newMeta: answerTag, newPossibleAnswer: answerValue };
      setShowLoader(true);
      const response = await updateAnswerTag(answerID, param);
      if (response.status === WebServiceStatus.success) {
        onSuccess();
      } else {
        const error = response.error;
        const hasInvalidJSON = error.includes(INVALID_JSON_ERROR_FROM_API);
        setSnackBar({
          title: "Attention!",
          message: hasInvalidJSON
            ? "Invalid JSON, please correct and try again"
            : "Unable to add/update answer tag.",
          isError: true,
        });
      }
      setShowLoader(false);
    }
  };

  const deleteAnswer = async () => {
    setShowLoader(true);
    const response = await removeAnswerTag(answerID, {});
    if (response.status === WebServiceStatus.success) {
      onSuccess();
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to delete answer tag.",
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const handleSaveAnswer = () => {
    if (answerID) {
      if (answerTag) {
        updateAnswer();
      } else {
        deleteAnswer();
      }
    } else {
      addAnswer();
    }
  };

  return (
    <>
      <MDBox mb={2}>
        <MDBox mb={1.4} maxWidth="350px">
          <MDTypography sx={labelStyle}>Answer Value {count}</MDTypography>
          {originalOption ? (
            <MDTypography
              sx={{ ...labelStyle, fontWeight: "700", marginTop: "5px" }}
            >
              {possibleAnswer}
            </MDTypography>
          ) : (
            <MDInput
              placeholder="Enter Answer Value"
              value={answerValue}
              fullWidth
              onChange={handleAnswerValueInputChange}
            />
          )}
        </MDBox>
        <MDBox mb={1.4}>
          <MDTypography sx={labelStyle}>Answer Tag {count}</MDTypography>
          <MDInput
            placeholder="Enter Answer Tag"
            value={answerTag}
            fullWidth
            multiline
            rows={3}
            onChange={handleAnswerTagInputChange}
          />
        </MDBox>
        <MDBox display="flex" justifyContent="end">
          <StyledButtonWithIcon
            disabled={disableSave}
            onClick={handleSaveAnswer}
          >
            SAVE
          </StyledButtonWithIcon>
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default TagsAnswerContent;
