import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomDialogBox from "pages/components/CustomDialogBox";
import {
  tableRowStyle,
  tableCellStyle,
} from "pages/surveys/styles/SurveyStyles";
import ConfirmRemoveDialogContent from "pages/users/components/ConfirmRemoveDialogContent";
import { useState } from "react";
import { ARDevice } from "../utils/interface";
import AddDeviceDialogContent from "./AddDeviceDialogContent";
import { WebServiceStatus, CustomIndicator } from "@ivueit/vue-engine";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { removeDevice, updateDevice } from "../services/services";

const SettingsListItem = ({
  arDevice,
  onRefresh,
}: {
  arDevice: ARDevice;
  onRefresh: () => void;
}) => {
  const [loader, setLoader] = useState(false);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] =
    useState<boolean>(false);
  const [openEditDeviceDialog, setOpenEditDeviceDialog] =
    useState<boolean>(false);

  const removeARDevice = async () => {
    setLoader(true);
    const response = await removeDevice(arDevice);
    if (response.status === WebServiceStatus.success) {
      onRefresh();
      setSnackBarContent({
        title: "Success",
        message: `${arDevice.name} removed successfully`,
        isError: false,
      });
      setOpenConfirmRemoveDialog(false);
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: `Unable to remove ${arDevice.name} : ${response.error}`,
        isError: true,
      });
    }
    setLoader(false);
  };

  const getConfirmReadOnlyDialog = () => {
    return (
      <CustomDialogBox
        title={"Confirm"}
        width="540px"
        openDialog={openConfirmRemoveDialog}
      >
        <ConfirmRemoveDialogContent
          closeDialog={async (isActionButton) => {
            if (isActionButton) {
              await removeARDevice();
              return;
            }
            setOpenConfirmRemoveDialog(false);
          }}
          message={`Are you sure to remove ${arDevice.name}?`}
          actionTitle={"Remove"}
        />
      </CustomDialogBox>
    );
  };

  const updateARDevice = async (newValues: ARDevice) => {
    setLoader(true);
    const params = {
      currentModelValue: newValues.model,
      previousModelValue: arDevice.model,
      brand: newValues.brand,
      name: newValues.name,
    };
    const response = await updateDevice(params);
    if (response.status === WebServiceStatus.success) {
      const deviceName = response.data.name as string;
      if (deviceName) {
        onRefresh();
        setSnackBarContent({
          title: "Success",
          message: `${newValues.name} updated successfully`,
          isError: false,
        });
      }
      setOpenEditDeviceDialog(false);
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: `Unable to update ${arDevice.name} : ${response.error}`,
        isError: true,
      });
    }
    setLoader(false);
  };

  const getEditDeviceDialog = () => {
    return (
      <CustomDialogBox
        title={"Edit Device"}
        width="540px"
        openDialog={openEditDeviceDialog}
      >
        <AddDeviceDialogContent
          closeDialog={() => {
            setOpenEditDeviceDialog(false);
          }}
          toUpdateDevice={true}
          onAddOrUpdate={(params: ARDevice) => {
            updateARDevice(params);
          }}
          arDevice={arDevice}
        />
      </CustomDialogBox>
    );
  };

  return (
    <>
      {openConfirmRemoveDialog && getConfirmReadOnlyDialog()}
      {openEditDeviceDialog && getEditDeviceDialog()}
      {loader && <CustomIndicator />}
      <MDBox sx={tableRowStyle}>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "200px",
          }}
        >
          <MDTypography sx={{ color: "#7b809a" }}>
            {arDevice.model}
          </MDTypography>
        </MDBox>
        <MDBox sx={tableCellStyle}>
          <MDTypography sx={{ color: "#7b809a" }}>
            {arDevice.brand}
          </MDTypography>
        </MDBox>
        <MDBox sx={tableCellStyle}>
          <MDTypography sx={{ color: "#7b809a", ml: "2" }}>
            {arDevice.name}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "260px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <MDButton
            variant="outlined"
            color="primary"
            sx={{ fontSize: "14px", fontWeight: "bold", ml: 1 }}
            onClick={() => {
              setOpenEditDeviceDialog(true);
            }}
          >
            Edit
          </MDButton>
          <MDButton
            variant="outlined"
            color="error"
            sx={{ fontSize: "14px", fontWeight: "bold", ml: 1 }}
            onClick={() => {
              setOpenConfirmRemoveDialog(true);
            }}
          >
            REMOVE
          </MDButton>
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </>
  );
};

export default SettingsListItem;
