import MDBox from "components/MDBox";
import ShopIcon from "assets/images/Shop_Icon.svg";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  tableRowStyle,
  tableCellStyle,
} from "pages/surveys/styles/SurveyStyles";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { useState } from "react";
import ConfirmRemoveDialogContent from "./ConfirmRemoveDialogContent";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import UserImageAvatar from "pages/vuers/components/UserImageAvatar";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { WebServiceStatus } from "utils/services/AppUrls";
import { updateCompanyGroupCompanies } from "../services/services";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { avatarContainerStyle } from "../styles/UsersStyles";

interface Props {
  currentCompany: Company;
  companiesList: Company[];
  onRefresh: () => void;
  companyGroupID: string;
}

const CompaniesListItem = (props: Props) => {
  const {
    id,
    name,
    primaryEmail,
    isReadOnlyMemberOfGroup,
    logoFileId,
    canonicalId,
  } = props.currentCompany;
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] =
    useState<boolean>(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);

  const removeCompany = async () => {
    const memberCompaniesObjList = props.companiesList
      .filter((company) => id !== company.id)
      .map((company) => {
        return {
          id: company.id,
          isReadOnly: company.isReadOnlyMemberOfGroup,
        };
      });
    const response = await updateCompanyGroupCompanies(
      props.companyGroupID,
      memberCompaniesObjList
    );
    if (response.status === WebServiceStatus.success) {
      props.onRefresh();
      setOpenConfirmRemoveDialog(false);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to remove the ${name} from the group : ${response.error}`,
        isError: true,
      });
    }
  };

  const getConfirmReadOnlyDialog = () => {
    return (
      <CustomDialogBox
        title={"Confirm"}
        width="540px"
        openDialog={openConfirmRemoveDialog}
      >
        <ConfirmRemoveDialogContent
          closeDialog={async (isActionButton) => {
            if (isActionButton) {
              await removeCompany();
              return;
            }
            setOpenConfirmRemoveDialog(false);
          }}
          message={`Are you sure to remove the company ${props.currentCompany.name}?`}
          actionTitle={"Remove"}
        />
      </CustomDialogBox>
    );
  };

  return (
    <>
      {openConfirmRemoveDialog && getConfirmReadOnlyDialog()}
      <MDBox sx={tableRowStyle}>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "80px",
          }}
        >
          <CustomBadge content={`#${canonicalId}`} />
        </MDBox>
        <MDBox sx={tableCellStyle}>
          <MDBox sx={avatarContainerStyle}>
            <UserImageAvatar
              imageId={logoFileId}
              size="lg"
              shadow="lg"
              placeholderImage={ShopIcon}
              sx={{
                backgroundColor: "#4CAF50",
              }}
            />
          </MDBox>
          <MDBox ml={1}>
            <MDTypography sx={{ color: "#1A73E8 !important" }}>
              {name}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox sx={tableCellStyle}>
          <MDTypography sx={{ color: "#7b809a" }}>
            {primaryEmail || "-"}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "120px",
          }}
        >
          <MDTypography sx={{ color: "#7b809a", ml: "2" }}>
            {isReadOnlyMemberOfGroup ? "YES" : "NO"}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "120px",
          }}
        >
          <MDButton
            variant="outlined"
            color="error"
            sx={{ fontSize: "14px", fontWeight: "bold", ml: 1 }}
            onClick={() => {
              setOpenConfirmRemoveDialog(true);
            }}
          >
            REMOVE
          </MDButton>
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default CompaniesListItem;
