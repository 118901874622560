import { VueStatusCount } from "../../interfaces/dashboard_interfaces";
import VueBottomBar from "../components/VueBottomBar";

/************** FOOTER ****************/

export const generateFooterElement = (vueStatusCount: VueStatusCount) => {
  return (
    <VueBottomBar
      totalVues={vueStatusCount.totalVues}
      openCount={vueStatusCount.openVues}
      inProgressCount={vueStatusCount.inProgressVues}
      inReviewCount={vueStatusCount.inReviewVues}
      completedCount={vueStatusCount.completedVues}
    />
  );
};
