import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";

interface Props {
  closeDialog: () => void;
  onDisable: (message: string) => void;
}

const PayoutMessageDialogContent = (props: Props) => {
  const [message, setMessage] = useState<string>("");
  return (
    <>
      <DialogContent>
        <MDBox px={1.4} pt={2}>
          <MDBox flex={1}>
            <MDTypography sx={{ ...regularTextStyle, pb: 1 }}>
              Disabled Message Reason
            </MDTypography>
            <MDInput
              placeholder="Enter Disabled Message Reason"
              value={message}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMessage(event.target.value);
              }}
              fullWidth
              multiline
              rows="3"
            />
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CLOSE
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={message.trim().isEmpty()}
            onClick={() => {
              props.onDisable(message);
            }}
            sx={{ fontSize: "14px", padding: "11px 22px" }}
          >
            Disable
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default PayoutMessageDialogContent;
