import { WebServiceResponse } from "@ivueit/vue-engine";
import { SettingsPageAPIServices } from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

export const getARWhitelistedDevices =
  async (): Promise<WebServiceResponse> => {
    const url = SettingsPageAPIServices.getARWhitelistedDevices;
    return await performGetRequest(url);
  };

export const addNewDevice = async (
  params: any
): Promise<WebServiceResponse> => {
  const url = SettingsPageAPIServices.addARWhitelistedDevice;
  return await performPostRequest(url, params);
};

export const updateDevice = async (
  params: any
): Promise<WebServiceResponse> => {
  const url = SettingsPageAPIServices.updateARWhitelistedDevice;
  return await performPostRequest(url, params);
};

export const removeDevice = async (
  params: any
): Promise<WebServiceResponse> => {
  const url = SettingsPageAPIServices.removeARWhitelistedDevice;
  return await performPostRequest(url, params);
};

export const getPayoutSettings = async (): Promise<WebServiceResponse> => {
  const url = SettingsPageAPIServices.getPayoutSettings;
  return await performGetRequest(url);
};

export const updatePayoutSettings = async (
  params: any
): Promise<WebServiceResponse> => {
  const url = SettingsPageAPIServices.updatePayoutSettings;
  return await performPostRequest(url, params);
};
