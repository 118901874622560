import { DialogContent, DialogActions } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { dialogText } from "pages/surveys/styles/SurveyStyles";

interface Props {
  email: string;
  closeDialog: () => void;
  onRemove: () => void;
}

const RemoveDialogContent = (props: Props) => {
  return (
    <>
      <DialogContent sx={{ padding: "20px 16px !important" }}>
        <MDTypography
          variant="body"
          sx={{ ...dialogText, marginBottom: "10px" }}
        >
          Are you sure you want to delete the user
          <span style={{ fontWeight: "500", marginLeft: "5px" }}>
            {props.email}
          </span>
        </MDTypography>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={props.closeDialog}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="error"
            size="medium"
            onClick={props.onRemove}
            sx={{ fontSize: "14px", padding: "11px 22px" }}
          >
            REMOVE
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default RemoveDialogContent;
