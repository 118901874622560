export enum UserDetailsActionType {
  editProfile = "EDIT_PROFILE",
  editContact = "EDIT_CONTACT",
  updatePassword = "UPDATE_PASSWORD",
  userDetails = "USER_DETAILS",
}

export interface UserDetailsAction {
  type: UserDetailsActionType;
  payload: {
    firstName: string;
    lastName: string;
    designation: string;
    profileUrl: string | null;
    profileImageID: string;
    emailId: string;
    phoneNumber: string;
    isMarketingEnabled: boolean;
  };
}

export interface UserDetailsState {
  firstName: string;
  lastName: string;
  designation: string;
  profileUrl: string | null;
  profileImageID: string;
  emailId: string;
  phoneNumber: string;
  isMarketingEnabled: boolean;
}

export const defaultUserDetailState: UserDetailsState = {
  firstName: "",
  lastName: "",
  designation: "",
  profileUrl: null,
  profileImageID: "",
  emailId: "",
  phoneNumber: "",
  isMarketingEnabled: true,
};

export const UserDetailReducer = (
  state: UserDetailsState,
  action: UserDetailsAction
) => {
  const { type, payload } = action;
  switch (type) {
    case UserDetailsActionType.editProfile: {
      const firstName = payload.firstName;
      const lastName = payload.lastName;
      const designation = payload.designation;
      const profileUrl = payload.profileUrl;
      const profileImageID = payload.profileImageID;
      return {
        ...state,
        firstName: firstName,
        lastName: lastName,
        designation: designation,
        profilePicture: profileUrl,
        profileImageID: profileImageID,
      };
    }

    case UserDetailsActionType.editContact: {
      const email = payload.emailId;
      const phone = payload.phoneNumber;
      return {
        ...state,
        emailId: email,
        phoneNumber: phone,
      };
    }

    case UserDetailsActionType.userDetails: {
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName,
        designation: payload.designation,
        emailId: payload.emailId,
        profileUrl: payload.profileUrl,
        profileImageID: payload.profileImageID,
        phoneNumber: payload.phoneNumber,
        isMarketingEnabled: payload.isMarketingEnabled,
      };
    }

    default:
      return state;
  }
};
