export enum UserRolesPortal {
  noAccess = "No Access",
  superAdmin = "Operator Admin",
  admin = "Admin",
  manager = "Manager",
  user = "User",
}

export enum UserRoles {
  noAccess = "no_access",
  superAdmin = "admin_user",
  admin = "company_admin",
  manager = "company_manager",
  user = "company_user",
}
