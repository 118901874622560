import { Card, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { surveysGridCardStyle } from "pages/surveys/styles/SurveyStyles";
import { useCallback, useEffect, useState } from "react";
import AddDeviceDialogContent from "./AddDeviceDialogContent";
import SettingsList from "./SettingsList";
import { ARDevice, PayoutSetting } from "../utils/interface";
import { WebServiceStatus } from "@ivueit/vue-engine";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { CustomIndicator } from "pages/components/CustomIndicator";
import Icon from "@mdi/react";
import {
  addNewDevice,
  getARWhitelistedDevices,
  getPayoutSettings,
  updatePayoutSettings,
} from "../services/services";
import MDTypography from "components/MDTypography";
import PayoutMessageDialogContent from "./PayoutMessageDialogContent";
import { mdiInformation } from "@mdi/js";
import TooltipWrapper from "components/CustomComponents/TooltipWrapper";

const SettingsDataGrid = () => {
  const [loader, setLoader] = useState(false);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [openAddDeviceDialog, setOpenAddDeviceDialog] =
    useState<boolean>(false);
  const [openDisabledMessageDialog, setOpenDisabledMessagDialog] =
    useState<boolean>(false);
  const [arDevicesList, setARDevicesList] = useState<ARDevice[]>([]);
  const [payoutSetting, setPayoutSetting] = useState<PayoutSetting>(null);
  const showTooltip =
    !payoutSetting?.payoutsEnabled &&
    payoutSetting?.ifDisabledMessage?.value.isNotEmpty();

  const fetchPayoutSettings = useCallback(async () => {
    setLoader(true);
    const response = await getPayoutSettings();
    if (response.status === WebServiceStatus.success) {
      const data = response.data as PayoutSetting;
      const { payoutsEnabled, ifDisabledMessage } = data;
      setPayoutSetting({
        payoutsEnabled: payoutsEnabled,
        ifDisabledMessage: {
          value: ifDisabledMessage?.value ?? "",
        },
      });
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: `Unable to fetch payout settings : ${response.error}`,
        isError: true,
      });
    }
    setLoader(false);
  }, []);

  const fetchARDevices = useCallback(async () => {
    setLoader(true);
    const response = await getARWhitelistedDevices();
    if (response.status === WebServiceStatus.success) {
      const data = response.data.arWhitelistedDevices as ARDevice[];
      setARDevicesList(data);
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: `Unable to fetch AR devices : ${response.error}`,
        isError: true,
      });
    }
    setLoader(false);
  }, []);

  const addNewARDevice = async (arDevice: ARDevice) => {
    setLoader(true);
    const response = await addNewDevice(arDevice);
    if (response.status === WebServiceStatus.success) {
      const deviceName = response.data.name as string;
      if (deviceName) {
        fetchARDevices();
        setSnackBarContent({
          title: "Success",
          message: `${arDevice.name} added successfully`,
          isError: false,
        });
      }
      setOpenAddDeviceDialog(false);
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: `Unable to add ${arDevice.name} : ${response.error}`,
        isError: true,
      });
    }
    setLoader(false);
  };

  const getAddDeviceDialog = () => {
    return (
      <CustomDialogBox
        title={"Add Device"}
        width="540px"
        openDialog={openAddDeviceDialog}
      >
        <AddDeviceDialogContent
          closeDialog={() => {
            setOpenAddDeviceDialog(false);
          }}
          toUpdateDevice={false}
          onAddOrUpdate={(params: ARDevice) => {
            addNewARDevice(params);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getDisabledMessageDialog = () => {
    return (
      <CustomDialogBox
        title={"Disable Payouts"}
        width="420px"
        openDialog={openDisabledMessageDialog}
      >
        <PayoutMessageDialogContent
          closeDialog={() => {
            setOpenDisabledMessagDialog(false);
          }}
          onDisable={(message: string) => {
            updatePayout(false, message);
            setOpenDisabledMessagDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const updatePayout = async (isEnabled: boolean, message: string) => {
    setLoader(true);
    const params: PayoutSetting = {
      payoutsEnabled: isEnabled,
      ifDisabledMessage: {
        value: message,
      },
    };
    const response = await updatePayoutSettings(params);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as PayoutSetting;
      const { payoutsEnabled, ifDisabledMessage } = data;
      setPayoutSetting({
        payoutsEnabled: payoutsEnabled,
        ifDisabledMessage: {
          value: ifDisabledMessage?.value ?? "",
        },
      });
      setSnackBarContent({
        title: "Success",
        message: `Payout setting ${
          isEnabled ? "enabled" : "disabled"
        } successfully.`,
        isError: false,
      });
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: `Unable to update payout settings : ${response.error}`,
        isError: true,
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchARDevices();
  }, []);

  useEffect(() => {
    fetchPayoutSettings();
  }, []);

  return (
    <MDBox>
      {loader && <CustomIndicator />}
      {openAddDeviceDialog && getAddDeviceDialog()}
      {openDisabledMessageDialog && getDisabledMessageDialog()}
      <MDBox display={"flex"} pt={0.6} pb={1.4}>
        <MDBox display="flex" ml={"auto"} alignItems="center">
          <MDBox display="flex" alignItems="center" mr={4}>
            <Switch
              checked={payoutSetting?.payoutsEnabled}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                if (checked) {
                  updatePayout(checked, "");
                } else {
                  setOpenDisabledMessagDialog(true);
                }
              }}
            />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ userSelect: "none" }}
            >
              {`${
                payoutSetting?.payoutsEnabled ? "Disable" : "Enable"
              } Payouts`}
            </MDTypography>
            {showTooltip && (
              <MDBox sx={{ ml: 1 }}>
                <TooltipWrapper
                  title={payoutSetting?.ifDisabledMessage?.value}
                  disableHoverListener={false}
                  placement="bottom"
                >
                  <Icon
                    path={mdiInformation}
                    size={0.72}
                    color={"#7b809a"}
                    style={{ opacity: 1 }}
                  />
                </TooltipWrapper>
              </MDBox>
            )}
          </MDBox>
          <MDButton
            variant="contained"
            color="success"
            onClick={() => {
              setOpenAddDeviceDialog(true);
            }}
            sx={{ fontSize: "14px", fontWeight: "bold" }}
          >
            Add Device
          </MDButton>
        </MDBox>
      </MDBox>
      <Card sx={surveysGridCardStyle}>
        <SettingsList
          arDevicesList={arDevicesList}
          onRefresh={() => {
            fetchARDevices();
          }}
        />
      </Card>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </MDBox>
  );
};

export default SettingsDataGrid;
