import MDBox from "components/MDBox";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { useEffect, useState } from "react";
import ClientsTabs, { ClientsTabsType } from "./components/ClientsTabs";
import TagsTagContent from "./components/TagsTabContent";
import ClientTabContent from "./components/ClientTabContent";
import { useLocation, useNavigate } from "react-router-dom";
import { Company, UserInfo } from "pages/profile/utils/ProfileInterfaces";
import {
  ManageInternalVuerNetwork,
  VuerData,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import AdminsTabContent from "./components/AdminsTabContent";
import { getOrgUsersByCompanyID } from "./services/services";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";
import VuersDetailContent from "pages/vuers/VuerDetailContent";
import ComingSoon from "pages/components/ComingSoon";

const ClientDetail = () => {
  const location = useLocation();
  const client = location.state.item as Company;
  const [orgUsersList, setOrgUsersList] = useState<UserInfo[]>([]);
  const [selectedVuerData, setSelectedVuerData] = useState<VuerData | null>(
    null
  );
  const [activeTab, setActiveTab] = useState<ClientsTabsType>(
    ClientsTabsType.clientDetails
  );

  const getTabContents = () => {
    switch (activeTab) {
      case ClientsTabsType.clientDetails:
        return getClientDetailsTab();
      case ClientsTabsType.tags:
        return getTagsTab();
      case ClientsTabsType.admins:
        return getAdminsTab();
      case ClientsTabsType.internalVuerNetwork:
        return getInternalVuer();
      default:
        break;
    }
  };

  const getClientDetailsTab = () => {
    return <ClientTabContent company={client} usersList={orgUsersList} />;
  };

  const getTagsTab = () => {
    return <TagsTagContent companyID={client.id} />;
  };

  const getAdminsTab = () => {
    return (
      <AdminsTabContent
        usersList={orgUsersList}
        company={client}
        fetchOrgUsers={async () => {
          await getOrgUserByClientId();
        }}
      />
    );
  };

  const getInternalVuer = () => {
    return (
      <MDBox py={1}>
        <ManageInternalVuerNetwork
          companyId={client.id}
          shortCode={client.shortcode}
          onClickInternalVuer={(vuer) => {
            if (vuer) {
              setSelectedVuerData(vuer);
            }
          }}
        />
      </MDBox>
    );
  };

  const getClientDetailsDialog = () => {
    return (
      <CustomDialogBox
        title={selectedVuerData.name}
        width="96vw"
        openDialog={selectedVuerData !== null}
      >
        <DialogContent>
          <MDBox px={1.4} pt={2}>
            <VuersDetailContent vuer={selectedVuerData} />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              setSelectedVuerData(null);
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CLOSE
          </MDButton>
        </DialogActions>
      </CustomDialogBox>
    );
  };

  const getOrgUserByClientId = async () => {
    const response = await getOrgUsersByCompanyID(client.id);
    if (response.status === WebServiceStatus.success) {
      const orgUsersList = response.data.orgUsers as UserInfo[];
      setOrgUsersList(orgUsersList);
    } else {
      setOrgUsersList(null);
    }
  };

  useEffect(() => {
    getOrgUserByClientId();
  }, []);

  return (
    <DashboardContentLayout>
      {selectedVuerData && getClientDetailsDialog()}
      <MDBox display="flex" pt={2.2}>
        <MDBox display="flex" ml="auto" alignItems="center" mr={1.4}>
          <ClientsTabs
            onTabChange={(tab: ClientsTabsType) => {
              setActiveTab(tab);
            }}
          />
        </MDBox>
      </MDBox>
      {getTabContents()}
    </DashboardContentLayout>
  );
};

export default ClientDetail;
