import { VueSurvey } from "@ivueit/vue-engine";

export enum SurveyTemplateActionType {
  titleChanged = "TITLE_CHANGED",
  costOrSLAChanged = "COST_OR_SLA_CHANGED",
  toggleChanged = "TOGGLE_CHANGED",
  companyChanged = "COMPANY_CHANGED",
  typeChanged = "TYPE_CHANGED",
  statusChanged = "STATUS_CHANGED",
}

export const DEFAULT_STEP = {
  description: "",
  typeOf: "",
  sliderMin: 0,
  sliderMax: 0,
  sliderSegments: 0,
  minSelections: 0,
  maxSelections: 0,
  options: "",
  photoCountMin: 0,
  photoCountMax: 0,
  deviceOrientation: 0,
  videoSecondsMin: 0,
  videoSecondsMax: 0,
};

export interface SurveyTemplateAction {
  type: SurveyTemplateActionType;
  payload: VueSurvey;
}

export const surveyTemplateReducer = (
  state: VueSurvey,
  action: SurveyTemplateAction
) => {
  const { type, payload } = action;
  switch (type) {
    case SurveyTemplateActionType.titleChanged:
      return {
        ...state,
        name: payload.name,
        description: payload.description,
      };
    case SurveyTemplateActionType.costOrSLAChanged:
      return {
        ...state,
        autoSlaHours: payload.autoSlaHours
          ? {
              ...state.autoSlaHours,
              value: payload.autoSlaHours.value,
            }
          : null,
        autoCostPerVue:
          payload.autoCostPerVue && payload.autoCostPerVue.value > 0
            ? {
                ...state.autoCostPerVue,
                value: payload.autoCostPerVue.value,
              }
            : null,
        autoPayoutPerVue:
          payload.autoPayoutPerVue && payload.autoPayoutPerVue.value > 0
            ? {
                ...state.autoPayoutPerVue,
                value: payload.autoPayoutPerVue.value,
              }
            : null,
        autoUrgentCost:
          payload.autoUrgentCost && payload.autoUrgentCost.value > 0
            ? {
                ...state.autoUrgentCost,
                value: payload.autoUrgentCost.value,
              }
            : null,
        autoUrgentPayout:
          payload.autoUrgentPayout && payload.autoUrgentPayout.value > 0
            ? {
                ...state.autoUrgentPayout,
                value: payload.autoUrgentPayout.value,
              }
            : null,
      };
    case SurveyTemplateActionType.companyChanged:
      return {
        ...state,
        companyId: payload.companyId
          ? {
              ...state.companyId,
              value: payload.companyId.value,
            }
          : null,
      };
    case SurveyTemplateActionType.toggleChanged:
      return {
        ...state,
        autoBackgroundCheckRequired: payload.autoBackgroundCheckRequired,
        autoHighQualityRequired: payload.autoHighQualityRequired,
      };
    case SurveyTemplateActionType.statusChanged:
      return {
        ...state,
        archived: payload.archived,
        published: payload.published,
      };
    case SurveyTemplateActionType.typeChanged:
      return { ...state, typeOf: payload.typeOf };
    default:
      return state;
  }
};
