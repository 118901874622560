import {
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { dialogCancelButtonStyle } from "pages/dashboard/home/vues/styles/VueDetailStyles";
import ExportDialogSectionTitle from "./ExportDialogSectionTitle";
import pdfImage from "assets/images/pdf_icon_n.svg";
import jpgImage from "assets/images/jpeg_icon_n.svg";
import xlsImage from "assets/images/xls_icon.svg";
import RadioButtonListWithTitle from "./RadioButtonListWithTitle";
import { useState } from "react";
import { ConfirmationDialogContent } from "pages/dashboard/components/ConfirmationDialogContent";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  downloadVueImage,
  exportVue,
  generatePresignedFileURL,
  exportVuePhoto,
  exportBatch,
} from "pages/dashboard/home/vues/services/VueServices";
import { downloadFileToDevice } from "@ivueit/vue-engine";
import JSZip from "jszip";
import { VueExportItem } from "pages/dashboard/home/vues/vue-grid/types/types";
import { VuesPageSummaryState } from "pages/dashboard/home/vues/vue-grid/utils/reducer";
import { ImmutableVueTags } from "pages/dashboard/home/vues/vue-grid/utils/enums";
import { getFilterTagsWithoutImmutableTags } from "pages/dashboard/home/vues/vue-grid/helpers/helper_methods";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { VUE_EXPORT_LIMIT } from "../../constants";
import { getFileNameInUTC } from "utils/helpers/extensions";

export enum ExportModalType {
  batches,
  vues,
}

interface Props {
  type: ExportModalType;
  closeDialog: () => void;
  hasNonCompletedVues?: boolean;
  selectedItemIds?: string[];
  selectedVues?: VueExportItem[];
  didSelectAll?: boolean;
  clientVueState?: VuesPageSummaryState;
}

const ExportDialogContent = (props: Props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [selectedDataOption, setSelectedDataOption] = useState(-1);
  const [selectedPDFOption, setSelectedPDFOption] = useState(-1);
  const [includePDFLinks, setIncludePDFLinks] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { hasNonCompletedVues } = props;
  /// Checking whether the incoming vues are not in completed status to disable the button to show the error message
  const errorMessage = hasNonCompletedVues
    ? "One or more of the selected Vues are not in the completed status"
    : "";

  const openConfirmation = () => {
    setShowConfirmation(true);
  };

  const closeConfirmation = () => {
    setShowConfirmation(false);
    props.closeDialog();
  };

  // Content for confirmation dialog
  const getConfirmationDialog = (): JSX.Element => {
    return (
      <ConfirmationDialogContent
        title={"Your report is being exported"}
        description={
          "This may take some time. You may continue to use this site normally while your report is being created."
        }
        subDescription={
          "A link to download your report will be sent to your email address."
        }
        closeDialog={closeConfirmation}
        buttonLabel="OK"
      />
    );
  };

  // Export Batch
  const batchExportAction = async () => {
    const params = {
      batchIds: props.selectedItemIds ?? [],
      isExcel: selectedDataOption === 0,
      includePdf: includePDFLinks,
    };
    setShowLoader(true);
    const response = await exportBatch(params);
    if (response.status === WebServiceStatus.success) {
      const fileID = response.data.fileId;
      if (fileID) {
        const response = await generatePresignedFileURL(fileID);
        if (response.status === WebServiceStatus.success) {
          const presignedUrl = response.data.url;
          const fileName = `export_Survey_Export_Excel_${getFileNameInUTC()}_UTC.csv`;
          await downloadFileToDevice(presignedUrl, fileName, "text/csv");
          openConfirmation();
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "No file found.",
          isError: true,
        });
      }
    } else {
      if (
        response.error.toLowerCase() ===
        "submissionID must not be nil".toLowerCase()
      ) {
        setSnackbarContent({
          title: "Attention!",
          message: "One or more selected batches has non completed Vues",
          isError: true,
        });
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: response.error,
          isError: true,
        });
      }
    }
    setShowLoader(false);
  };

  // Export Vue
  const vueExportAction = async () => {
    const params = getParametersForVueExport();
    setShowLoader(true);
    const response = await exportVue(params);
    if (response.status === WebServiceStatus.success) {
      const fileID = response.data.fileId;
      if (fileID) {
        const response = await generatePresignedFileURL(fileID);
        if (response.status === WebServiceStatus.success) {
          const presignedUrl = response.data.url;
          const fileName = `export_Vue_Export_Excel_${getFileNameInUTC()}_UTC.csv`;
          await downloadFileToDevice(presignedUrl, fileName, "text/csv");
          openConfirmation();
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "No file found.",
          isError: true,
        });
      }
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getParametersForVueExport = () => {
    const vueIds = props.selectedItemIds ?? [];
    // Default parameter data
    var params: any = {
      vueIds: vueIds,
      isExcel: selectedDataOption === 0,
      includePdf: includePDFLinks,
    };
    if (props.didSelectAll && props.clientVueState) {
      const { clientVueState } = props;
      // When selects all items from the list, we ignore the vue ids and
      params = { ...params, vueIds: [] };

      // Appending Metadata
      if (Object.keys(clientVueState.metaKeySearches).length !== 0) {
        const metaKeys = Object.keys(clientVueState.metaKeySearches);
        metaKeys.forEach((key) => {
          const value = clientVueState.metaKeySearches[key];
          params = { ...params, [`metaKeySearches[${key}]`]: value };
        });
      }
      // Appending vueTitle value
      if (clientVueState.vueTitle.isNotEmpty()) {
        params = { ...params, vueTitle: clientVueState.vueTitle };
      }
      // Appending batch id
      if (clientVueState.batchID.isNotEmpty()) {
        params = { ...params, batchID: clientVueState.batchID };
      }
      // Appending survey ID
      if (clientVueState.surveyTemplateId.isNotEmpty()) {
        params = { ...params, surveyID: clientVueState.surveyTemplateId };
      }
      // Adding survey name
      if (clientVueState.surveyName.isNotEmpty()) {
        params = { ...params, surveyName: clientVueState.surveyName };
      }
      // Appending search value
      if (clientVueState.search.isNotEmpty()) {
        params = { ...params, search: clientVueState.search };
      }
      // Appending vueID value
      if (clientVueState.canonicalID.isNotEmpty()) {
        params = { ...params, canonicalID: clientVueState.canonicalID };
      }
      // Appending companyIdsList to the parameter string
      if (clientVueState.companyIds.length > 0) {
        params = { ...params, companyIds: clientVueState.companyIds };
      }
      // Appending completedAtStartDate
      if (clientVueState.completedAtStartDate.isNotEmpty()) {
        params = {
          ...params,
          completedAtStartDate: clientVueState.completedAtStartDate,
        };
      }
      // Appending completedAtEndDate
      if (clientVueState.completedAtEndDate.isNotEmpty()) {
        params = {
          ...params,
          completedAtEndDate: clientVueState.completedAtEndDate,
        };
      }
      // Appending completedAtStartTime
      if (clientVueState.completedAtStartTime.isNotEmpty()) {
        params = {
          ...params,
          completedAtStartTime: clientVueState.completedAtStartTime,
        };
      }
      // Appending completedAtEndTime
      if (clientVueState.completedAtEndTime.isNotEmpty()) {
        params = {
          ...params,
          completedAtEndTime: clientVueState.completedAtEndTime,
        };
      }
      // Appending createdAtStartDate
      if (clientVueState.createdAtStartDate.isNotEmpty()) {
        params = {
          ...params,
          createdAtStartDate: clientVueState.createdAtStartDate,
        };
      }
      // Appending createdAtEndDate
      if (clientVueState.createdAtEndDate.isNotEmpty()) {
        params = {
          ...params,
          createdAtEndDate: clientVueState.createdAtEndDate,
        };
      }
      // Appending createdAtStartTime
      if (clientVueState.createdAtStartTime.isNotEmpty()) {
        params = {
          ...params,
          createdAtStartTime: clientVueState.createdAtStartTime,
        };
      }
      // Appending createdAtEndTime
      if (clientVueState.createdAtEndTime.isNotEmpty()) {
        params = {
          ...params,
          createdAtEndTime: clientVueState.createdAtEndTime,
        };
      }
      // Appending slaStartsAtStartDate
      if (clientVueState.slaStartsAtStartDate.isNotEmpty()) {
        params = {
          ...params,
          slaStartsAtStartDate: clientVueState.slaStartsAtStartDate,
        };
      }
      // Appending slaStartsAtEndDate
      if (clientVueState.slaStartsAtEndDate.isNotEmpty()) {
        params = {
          ...params,
          slaStartsAtEndDate: clientVueState.slaStartsAtEndDate,
        };
      }
      // Appending slaStartsAtStartTime
      if (clientVueState.slaStartsAtStartTime.isNotEmpty()) {
        params = {
          ...params,
          slaStartsAtStartTime: clientVueState.slaStartsAtStartTime,
        };
      }
      // Appending slaStartsAtEndTime
      if (clientVueState.slaStartsAtEndTime.isNotEmpty()) {
        params = {
          ...params,
          slaStartsAtEndTime: clientVueState.slaStartsAtEndTime,
        };
      }
      // Appending slaActualMin
      if (clientVueState.slaActualMin.isNotEmpty()) {
        params = { ...params, slaActualMin: clientVueState.slaActualMin };
      }
      // Appending slaActualMax
      if (clientVueState.slaActualMax.isNotEmpty()) {
        params = { ...params, slaActualMax: clientVueState.slaActualMax };
      }
      // Appending slaTargetMin
      if (clientVueState.slaTargetMin.isNotEmpty()) {
        params = { ...params, slaTargetMin: clientVueState.slaTargetMin };
      }
      // Appending slaTargetMax
      if (clientVueState.slaTargetMax.isNotEmpty()) {
        params = { ...params, slaTargetMax: clientVueState.slaTargetMax };
      }
      // Adding Urgent escalated
      if (
        clientVueState.escalated !== null &&
        clientVueState.escalated !== undefined
      ) {
        params = {
          ...params,
          escalated: {
            value: clientVueState.escalated,
          },
        };
      }
      // Appending Urgent, only if the urgent tag is available in the vue tag list
      if (
        clientVueState.vueTags.findIndex(
          (item) => item === ImmutableVueTags.urgent
        ) !== -1
      ) {
        params = {
          ...params,
          urgent: {
            value: true,
          },
        };
      }
      // Appending high quality, only if the highQuality tag is available in the vue tag list
      if (
        clientVueState.vueTags.findIndex(
          (item) => item === ImmutableVueTags.highQuality
        ) !== -1
      ) {
        params = {
          ...params,
          highQuality: {
            value: true,
          },
        };
      }
      // Appending Internal, only if the urgent tag is available in the vue tag list
      if (
        clientVueState.vueTags.findIndex(
          (item) => item === ImmutableVueTags.internal
        ) !== -1
      ) {
        params = {
          ...params,
          internal: {
            value: true,
          },
        };
      }
      // Appending siteNameAndNumber value
      if (clientVueState.siteNameAndNumber.isNotEmpty()) {
        params = {
          ...params,
          siteNameAndNumber: clientVueState.siteNameAndNumber,
        };
      }
      // Appending postalAddress
      if (clientVueState.postalAddress.isNotEmpty()) {
        params = { ...params, postalAddress: clientVueState.postalAddress };
      }
      // Appending Address 2
      if (clientVueState.addressTwo.isNotEmpty()) {
        params = { ...params, addressTwo: clientVueState.addressTwo };
      }
      // Appending city
      if (clientVueState.city.isNotEmpty()) {
        params = { ...params, city: clientVueState.city };
      }
      // Appending stateProvinceAbbreviation
      if (clientVueState.stateProvinceAbbreviation.isNotEmpty()) {
        params = {
          ...params,
          stateProvinceAbbreviation: clientVueState.stateProvinceAbbreviation,
        };
      }
      // Appending zipPostalCode
      if (clientVueState.zipPostalCode.isNotEmpty()) {
        params = { ...params, zipPostalCode: clientVueState.zipPostalCode };
      }
      // Appending country
      if (clientVueState.countryAbbreviation.length > 0) {
        params = {
          ...params,
          countryShort: clientVueState.countryAbbreviation,
        };
      }
      // Appending submission status
      if (clientVueState.statuses.length > 0) {
        params = { ...params, statuses: clientVueState.statuses };
      }
      // Appending types
      if (clientVueState.types.length > 0) {
        params = { ...params, types: clientVueState.types };
      }
      // Appending vueTags
      const updatedTags = getFilterTagsWithoutImmutableTags(
        clientVueState.vueTags
      );
      if (updatedTags.length > 0) {
        params = { ...params, vueTags: updatedTags };
      }
      // Appending sortColumnDisplayName
      if (clientVueState.sortColumnDisplayName.isNotEmpty()) {
        params = {
          ...params,
          sortColumnDisplayName: clientVueState.sortColumnDisplayName,
        };
      }
      /// Appending mediaTags
      if (clientVueState.mediaTags.length > 0) {
        params = { ...params, mediaTags: clientVueState.mediaTags };
      }
      // Appending sortColumnDisplayName
      if (
        clientVueState.sortIsAscending !== null &&
        clientVueState.sortIsAscending !== undefined
      ) {
        params = { ...params, sortIsAscending: clientVueState.sortIsAscending };
      }
    }
    return params;
  };

  // Export  Vue PDF
  const vuePDFExportAction = async () => {
    setShowLoader(true);
    var exportCount: number = 0;
    props.selectedVues.forEach(async (vue: VueExportItem) => {
      const id = getPdfIdSelected(vue);
      if (id.length > 0) {
        const response = await generatePresignedFileURL(id);
        exportCount++;
        if (response.status === WebServiceStatus.success) {
          if (exportCount === props.selectedVues.length) {
            const fileName = `vue_${vue.vueId}_pdf.pdf`;
            downloadFileToDevice(response.data.url, fileName, "pdf");
            openConfirmation();
          }
        } else {
          setSnackbarContent({
            title: "Attention!",
            message: response.error,
            isError: true,
          });
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "No PDF available",
          isError: true,
        });
      }
    });
    setShowLoader(false);
  };

  // Get Pdf type selected
  const getPdfIdSelected = (vue: VueExportItem) => {
    switch (selectedPDFOption) {
      case 0:
        return vue.pdfFileId;
      case 1:
        return vue.pdfFileIdPhotos;
      case 2:
        return vue.pdfFileIdSurvey;
      case 3:
        return vue.pdfFileIdOrdered;
      default:
        return "";
    }
  };

  // Export Vue
  const vuePhotoExportAction = async () => {
    setShowLoader(true);
    const response = await exportVuePhoto(props.selectedItemIds);
    if (response.status === WebServiceStatus.success) {
      const vuePhotos = response.data.vuePhotos;
      if (vuePhotos.length > 0) {
        vuePhotos.forEach(async (item: any) => {
          const photoIds: string[] = item.photoIds ?? [];
          if (photoIds.length > 0 && photoIds[0].length > 0) {
            const selectedVue = props.selectedVues.filter((vueSelected) => {
              return vueSelected.id === item.vueId;
            });
            await downLoadPhotoAsZip(photoIds, selectedVue[0].vueId);
            openConfirmation();
          } else {
            setSnackbarContent({
              title: "Attention!",
              message: "One or more Vues have no photos available",
              isError: true,
            });
          }
        });
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "No photos available",
          isError: true,
        });
        setShowLoader(false);
      }
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
      setShowLoader(false);
    }
  };

  const downLoadPhotoAsZip = async (photoIds: string[], vueId: string) => {
    const getImageUrlsResponses = await Promise.all(
      photoIds.map((photoId) => downloadVueImage(photoId))
    );
    const availablePhotosUrl: any[] = getImageUrlsResponses.map((response) => {
      if (response) {
        const { mimeType, data, filename } = response;
        var filenameShort = filename.split("\\").pop().split("/").pop();
        const url = `data:${mimeType};base64,${data}`;
        return { url: url, name: filenameShort };
      } else {
        return { url: null, name: "" };
      }
    });
    await handleZip(availablePhotosUrl, vueId);
  };

  const handleZip = async (photosUrl: any[], vueId: string) => {
    const zip = new JSZip();
    // Add Images to the zip file
    for (var i = 0; i < photosUrl.length; i++) {
      const { url, name } = photosUrl[i];
      if (url && url.length > 0) {
        const fileBlob = await fetch(url)
          .then((response) => response.arrayBuffer())
          .then((buffer) => new Blob([buffer]));
        zip.file(name, fileBlob);
        if (i === photosUrl.length - 1) {
          // Generate the zip file
          const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
          });
          // Create a download link for the zip file
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(zipData);
          link.download = `vue_${vueId}_photos.zip`;
          link.target = "_blank";
          link.click();
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "File not found",
          isError: true,
        });
      }
    }
    setShowLoader(false);
  };
  // Export dialog content
  const getExportDialog = (): JSX.Element => {
    return (
      <>
        <DialogContent sx={{ padding: "0 !important" }}>
          {showLoader && <CustomIndicator />}
          <MDBox pt={1.6} px={2.6}>
            <MDTypography variant="button" sx={{ fontWeight: "500" }}>
              Select the format that you would like to export as:
            </MDTypography>
          </MDBox>
          <MDBox px={2.6} mt={1.2}>
            <ExportDialogSectionTitle
              titleHead="Data"
              title="Excel file containing details of all selected Vues"
              imgPath={xlsImage}
              exportButtonClick={() => {
                if (props.type === ExportModalType.batches) {
                  batchExportAction();
                } else {
                  vueExportAction();
                }
              }}
              isExportEnabled={selectedDataOption !== -1}
              errorMessage={""}
            />
            <MDBox pl={4}>
              <RadioButtonListWithTitle
                title="Select Spreadsheet Format:"
                radioList={[
                  { title: "CSV (include Vue links)", description: "" },
                  { title: "CSV (do not include Vue links)", description: "" },
                ]}
                selectedValue={selectedDataOption}
                onSelect={(selectedIndex: number) => {
                  setSelectedDataOption(selectedIndex);
                }}
              />
              <MDBox display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includePDFLinks}
                      onChange={(event) => {
                        setIncludePDFLinks(event.target.checked);
                      }}
                    />
                  }
                  label="Include PDF Links"
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontWeight: "400",
                    },
                  }}
                />
                <MDTypography
                  variant="caption"
                  ml="-10px"
                  sx={{ fontWeight: "400", color: "#344767", fontSize: "14px" }}
                >
                  <i>{"(Links expire after 7 days)"}</i>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          {props.type === ExportModalType.vues && (
            <>
              <Divider
                sx={{
                  background: "#C7CCD0",
                  backgroundImage: "unset !important",
                  opacity: "1",
                }}
              />
              <MDBox px={2.6}>
                <ExportDialogSectionTitle
                  titleHead="Photos"
                  title="Zip file of folders of completed Vue's photos"
                  imgPath={jpgImage}
                  exportButtonClick={() => {
                    vuePhotoExportAction();
                  }}
                  // Disabled when selected non completed vues
                  isExportEnabled={
                    !hasNonCompletedVues &&
                    props.selectedItemIds.length <= VUE_EXPORT_LIMIT
                  }
                  errorMessage={errorMessage}
                  exportItemCount={props.selectedItemIds.length}
                />
              </MDBox>
              <Divider
                sx={{
                  background: "#C7CCD0",
                  backgroundImage: "unset !important",
                  opacity: "1",
                }}
              />
              <MDBox px={2.6}>
                <ExportDialogSectionTitle
                  titleHead="PDF"
                  title="Zip file of PDF files (one per completed Vue)"
                  imgPath={pdfImage}
                  exportButtonClick={() => {
                    vuePDFExportAction();
                  }}
                  // Disabled when selected non completed vues
                  isExportEnabled={
                    !hasNonCompletedVues &&
                    selectedPDFOption !== -1 &&
                    props.selectedItemIds.length <= VUE_EXPORT_LIMIT
                  }
                  errorMessage={errorMessage}
                  exportItemCount={props.selectedItemIds.length}
                />
              </MDBox>
              <MDBox pl={4}>
                <MDBox pl={4} pr={2}>
                  <RadioButtonListWithTitle
                    title="Select PDF Format:"
                    radioList={[
                      {
                        title: "PDF Ordered",
                        description:
                          " - Displays all photos, survey questions and answers in sequential order.",
                      },
                      {
                        title: "PDF Standard",
                        description:
                          " - Displays all photos followed by survey questions and answers.",
                      },
                      {
                        title: "PDF Photos",
                        description: " - Displays photos only.",
                      },
                      {
                        title: "PDF Survey Only",
                        description:
                          " - Displays survey questions and answers only.",
                      },
                    ]}
                    selectedValue={selectedPDFOption}
                    onSelect={(selectedIndex: number) => {
                      setSelectedPDFOption(selectedIndex);
                    }}
                  />
                </MDBox>
              </MDBox>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="outlined"
            sx={dialogCancelButtonStyle}
            color="secondary"
            size="small"
            onClick={props.closeDialog}
          >
            CANCEL
          </MDButton>
        </DialogActions>
        <CustomSnackbar
          snackbarContent={snackbarContent}
          onClose={() => {
            setSnackbarContent(null);
          }}
        />
      </>
    );
  };

  return showConfirmation ? getConfirmationDialog() : getExportDialog();
};

export default ExportDialogContent;
