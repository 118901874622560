import { Tooltip } from "@mui/material";
import { tooltipStyles } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";

interface Props {
  title: string;
  disableHoverListener: boolean;
  children: JSX.Element;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

const TooltipWrapper = ({
  title,
  placement = "right",
  disableHoverListener,
  children,
}: Props) => {
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: tooltipStyles,
        },
      }}
      disableHoverListener={disableHoverListener}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default TooltipWrapper;
