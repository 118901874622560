import { mdiLinkVariant, mdiPlus, mdiDelete } from "@mdi/js";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Icon,
  IconButton,
  MDButton,
  StyledButtonWithIcon,
} from "pages/dashboard/home/vues/vue-grid/helpers/imports";
import { StepActionType } from "../types/enums";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { generateGroupColor } from "../utils/helpers";
import { SurveyGroupWithStep } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";

interface Props {
  stepNumber: string;
  stepType: string;
  groupIndex: number;
  groupName: string;
  isGrouped: boolean;
  hasConditionApplied: boolean;
  showAddConditionButton: boolean;
  onButtonClick: (type: StepActionType) => void;
}

export const HeaderStepSection = ({
  stepNumber,
  stepType,
  groupName,
  groupIndex,
  isGrouped,
  hasConditionApplied,
  showAddConditionButton,
  onButtonClick,
}: Props) => {
  return (
    <MDBox display="flex" pb={1} alignItems="center">
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="h5">{`${stepNumber}. ${stepType}`}</MDTypography>
        {isGrouped && (
          <MDBox ml={1.4}>
            <CustomBadge
              content={groupName}
              bgColor={generateGroupColor(groupIndex)}
            />
          </MDBox>
        )}
      </MDBox>
      <MDBox ml="auto" display="flex" alignItems="center">
        <StyledButtonWithIcon
          iconPath={!isGrouped ? mdiPlus : ""}
          sx={{ mr: "5px" }}
          onClick={() => {
            onButtonClick(
              isGrouped ? StepActionType.ungroup : StepActionType.group
            );
          }}
        >
          {isGrouped ? "UNGROUP" : "GROUP"}
        </StyledButtonWithIcon>
        {showAddConditionButton && (
          <MDButton
            variant="contained"
            color={hasConditionApplied ? "primary" : "default"}
            onClick={() => {
              onButtonClick(StepActionType.link);
            }}
            sx={{ minWidth: "unset", px: "12px", ml: "5px" }}
          >
            <Icon path={mdiLinkVariant} size={0.85} />
          </MDButton>
        )}
        <IconButton
          color="error"
          onClick={() => {
            onButtonClick(StepActionType.delete);
          }}
          sx={{ ml: "5px" }}
        >
          <Icon path={mdiDelete} size={0.85} />
        </IconButton>
      </MDBox>
    </MDBox>
  );
};
