import DashboardContentLayout from "layouts/DashboardContentLayout";
import { DraftSurvey } from "./pages/DraftSurvey";
import PublishedSurvey from "./pages/PublishedSurvey";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { CustomIndicator } from "@ivueit/vue-engine";
import { getSurveyTemplateById } from "./services/SurveyServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { VueSurveyData } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import { ScrollToTopButton } from "pages/dashboard/home/vues/vue_detail/components/ScrollToTopButton";
import { mdiArrowUp } from "@mdi/js";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";
import { GENERIC_ERROR_MESSAGE } from "../../constants";
import MDBox from "components/MDBox";

export const SurveyDetail = () => {
  const location = useLocation();
  const surveyTemplateId = location.state?.id ?? "";
  const [showLoader, setShowLoader] = useState(false);
  const [surveyTemplate, setSurveyTemplate] = useState<VueSurveyData>();
  const [error, setError] = useState<string>("");
  /// For scrolling
  const containerRef = useRef<HTMLDivElement>(null);
  /// Handles the visibility of "Scroll To Top" button
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        setShowScrollToTopButton(container.scrollTop > 0);
      };
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const fetchSurveyTemplateDetails = useCallback(async () => {
    setShowLoader(true);
    if (surveyTemplateId.isEmpty()) {
      setShowLoader(false);
      return;
    }
    const response = await getSurveyTemplateById(surveyTemplateId);
    if (response.status === WebServiceStatus.success) {
      // Extracts survey and groups from the response
      const surveyData = response.data as VueSurveyData;
      setSurveyTemplate(surveyData);
    } else {
      setError(GENERIC_ERROR_MESSAGE);
    }
    setShowLoader(false);
  }, [surveyTemplateId]);

  useEffect(() => {
    fetchSurveyTemplateDetails();
  }, [fetchSurveyTemplateDetails]);

  const handleScrollToTopButtonClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // generates the survey details page body based on the survey status
  const getSurveyDetailBody = () => {
    if (!surveyTemplate) {
      return showLoader && <CustomIndicator />;
    }

    // Displaying the detail content based on the survey status
    return surveyTemplate.survey.published ? (
      <PublishedSurvey survey={surveyTemplate} />
    ) : (
      <DraftSurvey
        surveyData={surveyTemplate}
        refreshSurveyData={async () => {
          await fetchSurveyTemplateDetails();
        }}
      />
    );
  };
  return (
    <DashboardContentLayout
      needCardBackground={false}
      containerRef={containerRef}
    >
      {showLoader && <CustomIndicator />}
      {getSurveyDetailBody()}
      {error && (
        <MDBox margin="auto" pt="40vh">
          <PlaceholderComponent label={error} />
        </MDBox>
      )}
      {/* ScrollToTop */}
      {showScrollToTopButton && (
        <ScrollToTopButton
          iconPath={mdiArrowUp}
          handleOnClick={handleScrollToTopButtonClick}
        />
      )}
    </DashboardContentLayout>
  );
};
