import { ListItem, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { tooltipStyles } from "../../vue-grid/components/VueActionBar";

interface Props {
  slNum: number;
  fileName: string;
  handlePreviewButtonClick: () => void;
  handleDownloadButtonClick: () => void;
}

export const AttachmentItem = ({
  slNum,
  fileName,
  handlePreviewButtonClick,
  handleDownloadButtonClick,
}: Props) => {
  return (
    <ListItem
      sx={{
        maxWidth: "910px",
        borderBottom: "1px solid rgb(233,234,237)",
        pt: "12px",
        pb: "12px",
        ":first-child": {
          borderTop: "1px solid rgb(233,234,237)",
        },
      }}
    >
      <MDBox width="20px" display="flex" justifyContent="center">
        <MDTypography
          variant="body2"
          sx={{ color: "#191919", fontWeight: "600" }}
        >
          {slNum}.
        </MDTypography>
      </MDBox>
      <MDBox flex="1" display="flex" minWidth="0">
        <Tooltip
          title={fileName}
          arrow
          placement="right"
          componentsProps={{
            tooltip: {
              sx: { tooltipStyles },
            },
          }}
        >
          <MDBox style={{ display: "flex", minWidth: "0", paddingLeft: "6px" }}>
            <MDTypography
              variant="body2"
              sx={{
                color: "#191919",
                fontWeight: "400",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {fileName}
            </MDTypography>
          </MDBox>
        </Tooltip>
      </MDBox>
      <MDBox display="flex" flexShrink="0" pl={1}>
        <MDButton
          variant="text"
          color="primary"
          sx={{ padding: "1px", fontSize: "14px" }}
          fullWidth
          onClick={handlePreviewButtonClick}
        >
          PREVIEW
        </MDButton>
        <MDButton
          variant="text"
          color="primary"
          sx={{ padding: "1px", fontSize: "14px", marginLeft: "24px" }}
          fullWidth
          onClick={handleDownloadButtonClick}
        >
          DOWNLOAD
        </MDButton>
      </MDBox>
    </ListItem>
  );
};
