import { searchBarStyle } from "@ivueit/vue-engine";
import { mdiPlus } from "@mdi/js";
import { TextField } from "@mui/material";
import Icon from "@mdi/react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { regularTextStyle } from "../styles/SurveyStyles";
import { useState } from "react";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";

interface Props {
  title?: string;
  onAddOption: (newOptions: string[]) => void;
  optionsList: string[];
}

export const CustomAddoption = ({ onAddOption, optionsList, title }: Props) => {
  const [option, setOption] = useState<string>("");
  const [error, setError] = useState<string>("");
  const disableButton = !option.trim() || optionsList.includes(option.trim());

  const handleAddOption = () => {
    const newList = [...optionsList, option];
    onAddOption(newList);
    setOption("");
  };

  const onOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = event.target.value;
    const optionAlreadyExists = optionsList
    .map((option) => option.toLowerCase())
    .includes(newOption.trim().toLowerCase());
    console.log(optionsList, optionAlreadyExists);
    setError(optionAlreadyExists ? "Option already exists" : "");
    setOption(newOption);
  };

  return (
    <>
      {title && <MDTypography sx={regularTextStyle}>{title}</MDTypography>}
      <MDBox display="flex">
        <MDBox display="flex" flexDirection="column">
          <TextField
            fullWidth
            value={option}
            sx={searchBarStyle}
            placeholder="Enter tag name"
            InputLabelProps={{ shrink: true }}
            onChange={onOptionChange}
          />
          {error && (
            <MDTypography
              sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
            >
              {error}
            </MDTypography>
          )}
        </MDBox>
        <StyledButtonWithIcon
          sx={{ ml: "5px", minWidth: "unset", px: "11px", height: "40px" }}
          onClick={handleAddOption}
          disabled={disableButton}
        >
          <Icon path={mdiPlus} size={1} />
        </StyledButtonWithIcon>
      </MDBox>
    </>
  );
};
