export const pdfLogoStyle = {
    height: "150px",
    width: "auto"
}
export const customTagItemStyle = {
    padding: "5px 10px 5px 0px"
}
export const clientAvatarContainerStyle = {
    width: "74px",
    height: "74px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}