export const UsersGridContainer = {
    minHeight: `calc(100vh - 296px)`,
    height: `calc(100vh - 296px)`,
    justifyContent: "flex-start",
    position: "relative",
    paddingRight: "10px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
}
export const avatarContainerStyle = {
    width: "74px",
    height: "74px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}