import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DatePicker } from "@mui/x-date-pickers";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { datePickerStyle } from "../home/vues/styles/VueStyles";
import { TEXTFIELD_CHANGE_DELAY } from "../../../constants";

interface Props {
  title: string;
  [key: string]: any;
  defaultDate: Moment;
  errorMessage?: string;
  onChange?: (selectedDate: Moment | null) => void;
  minimumDate?: Moment;
  maximumDate?: Moment;
  openDialog?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  minWidth?: number;
  shouldResetDate?:boolean;
}

function CustomDatePicker({
  title,
  defaultDate,
  onChange,
  minimumDate,
  maximumDate,
  errorMessage,
  openDialog,
  onOpen: shouldOpenDialog,
  onClose: shouldCloseDialog,
  minWidth,
  shouldResetDate,
  ...rest
}: Props): JSX.Element {
  /// Date Format
  const dateFormat = "MM / DD / YYYY";

  /// This holds the date selected by the user
  const [selectedDate, setSelectedDate] = useState<Moment | null>(defaultDate);
  const [previousDate, setPreviousDate] = useState<Moment | null>(defaultDate);
  /// Handles the change in datePicker
  const handleDatePickerChange = (newDate: Moment | null) => {
    const selectedNewDate = newDate ? moment(newDate, dateFormat) : null;
    setPreviousDate(selectedDate);
    setSelectedDate(selectedNewDate);
  };

  // Handling the text search for the grid
  useEffect(() => {
    const dateChanged = (newDate: Moment | null) => {
      const selectedNewDate = moment(newDate, dateFormat);
      // Below check prevents calling onchange when date is not changed
      if (!selectedNewDate.isSame(previousDate)) {
        onChange(newDate ? selectedNewDate : null);
      }
    };
    /// It helps to avoid frequent state updates, on text change
    const delayDateChange = setTimeout(() => {
      dateChanged(selectedDate);
    }, TEXTFIELD_CHANGE_DELAY);

    return () => clearTimeout(delayDateChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (shouldResetDate) {
      setSelectedDate(null);
    }
  }, [shouldResetDate])
  

  return (
    // Global styles
    <MDBox>
      <MDBox lineHeight={1} mb={0.5}>
        <MDTypography
          variant="caption"
          fontWeight="bold"
          color="dark"
          sx={{ fontSize: "14px" }}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox>
        {/* Global Styles for date picker */}
        <GlobalStyles styles={datePickerStyle} />
        <DatePicker
          {...rest}
          open={openDialog}
          onOpen={shouldOpenDialog}
          onClose={shouldCloseDialog}
          slotProps={{
            // Action props for the date picker
            actionBar: {
              actions: ["clear", "today"],
            },
            textField: {
              error: false,
              helperText: errorMessage,
            },
          }}
          sx={{
            position: "relative",
            ".MuiOutlinedInput-input": {
              paddingTop: "10px",
              paddingBottom: "10px",
            },
            ".MuiSvgIcon-root": {
              color: "#344767",
              minWidth: minWidth,
            },
            ".MuiFormHelperText-root": {
              color: "#AE1709",
              fontWeight: 400,
              ml: 0,
            },
          }}
          value={selectedDate}
          format={dateFormat}
          minDate={minimumDate}
          onChange={handleDatePickerChange}
          maxDate={maximumDate}
        />
      </MDBox>
    </MDBox>
  );
}

export default CustomDatePicker;
