import { TEXTFIELD_CHANGE_DELAY } from "@ivueit/vue-engine";
import { CustomAutoCompleteComponent } from "components/CustomComponents/CustomAutoCompleteComponent";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { useState, useMemo, useCallback, useEffect } from "react";

interface Props {
  user?: UserInfo;
  onSuccess: (user?: UserInfo) => void;
}

export const UsersSearchComponent = (props: Props) => {
  const { onSuccess, user } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(true);
  const [usersList, setUsersList] = useState<UserInfo[]>([]);
  const [userIsTyping, setUserIsTyping] = useState<boolean>(false);
  const userEmailsList = useMemo(
    () => usersList.map((user) => user.email),
    [usersList]
  );

  // This executes when an option is selected from the drop-down
  const handleOptionSelect = (selectedValue: string, reason: string) => {
    setUsersList([]);
    if (reason === "clear") {
      if (!searchText) return;
      setSearchText("");
      onSuccess(null);
      return;
    }
    setUserIsTyping(false);
    const userEmail = selectedValue as string;
    const selectedUser = usersList.filter(
      (user) => user.email.toLowerCase() === userEmail.toLowerCase()
    )[0];
    onSuccess(selectedUser);
  };

  const handleInputChange = (newValue: string) => {
    const sameUser = newValue.toLowerCase() === user?.email.toLowerCase() ?? "";
    if (sameUser) return;
    setUserIsTyping(true);
    setSearchText(newValue);
  };

  const handleSearchUsers = useCallback(async () => {
    /// TODO : NEED TO INTEGRATE SEARCH ORG USERS API HERE
    setIsFetchingUsers(false);
  }, [searchText]);

  useEffect(() => {
    if (user) {
      setSearchText(user.email);
    } else {
      setSearchText("");
    }
  }, [user]);

  useEffect(() => {
    if (userIsTyping && searchText) {
      const delaySearch = setTimeout(() => {
        handleSearchUsers();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearch);
    }
  }, [handleSearchUsers, userIsTyping, searchText]);

  return (
    <CustomAutoCompleteComponent
      onOpen={() => {
        handleSearchUsers();
      }}
      optionsList={userEmailsList}
      noOptionsText={
        isFetchingUsers
          ? "Loading..."
          : usersList.length === 0
          ? "Not available"
          : ""
      }
      value={user && user.email}
      inputValue={searchText}
      onOptionSelect={(event, selectedValue, reason) => {
        handleOptionSelect(selectedValue, reason);
      }}
      onInputChange={(event, newValue, reason) => {
        handleInputChange(newValue);
      }}
      placeholderText="Enter user name"
    />
  );
};
