import DashboardContentLayout from "layouts/DashboardContentLayout";
import UsersDataGrid from "./components/UsersDataGrid";

const Users = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <UsersDataGrid />
    </DashboardContentLayout>
  );
};

export default Users;
