import MDAvatar from "components/MDAvatar";
import userPlaceholder from "assets/images/user_filler.svg";
import { useEffect, useState } from "react";
import { downloadFile } from "pages/profile/services/ProfileServices";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  imageId: string;
  placeholderImage?: string;
  [key: string]: any;
}

const UserImageAvatar = ({ placeholderImage, imageId, ...attr }: Props) => {
  const [profileUrl, setProfileUrl] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  /// Downloading profile picture
  useEffect(() => {
    const downloadProfilePic = async () => {
      if (imageId) {
        setShowLoader(true);
        const imageData = await downloadFile(imageId);
        if (imageData) {
          const { mimeType, data } = imageData;
          const url = `data:${mimeType};base64,${data}`;
          setProfileUrl(url);
        } else {
          setProfileUrl(null);
        }
      } else {
        setProfileUrl(null);
      }
      setShowLoader(false);
    };
    downloadProfilePic();
  }, [imageId]);

  const getAvatarStyle = () => {
    let style = { ...attr.sx };
    if (!profileUrl) {
      style = {
        ...style,
        ...{
          img: {
            width: "auto !important",
            height: "auto !important",
          },
        },
      };
      return style;
    }
  };

  return (
    <>
      {showLoader ? (
        <CircularProgress size={20} color="success" />
      ) : (
        <MDAvatar
          src={
            profileUrl?.isNotEmpty()
              ? profileUrl
              : placeholderImage ?? userPlaceholder
          }
          alt="Logo"
          {...attr}
          sx={getAvatarStyle()}
        />
      )}
    </>
  );
};

export default UserImageAvatar;
