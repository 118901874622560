import {
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  WebServiceStatus,
  gridStyle,
  linkElementStyle,
  textEllipsis,
} from "@ivueit/vue-engine";
import { Card, IconButton, LinearProgress, Link, Tooltip } from "@mui/material";
import Icon from "@mdi/react";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import MDBox from "components/MDBox";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { gridTextStyle } from "pages/surveys/styles/SurveyStyles";
import { useCallback, useEffect, useState } from "react";
import DataGridBottomBar from "./BottomBar";
import MDTypography from "components/MDTypography";
import { defaultMetaData } from "pages/surveys/utils/constants";
import { GridMetaData, SubmissionVue } from "../interface/interface";
import {
  getAgingHours,
  getSubmissionRowData,
  submissionGridColumnData,
} from "../utils/helpers";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { getVuesWithSubmissionStatusByUserID } from "../services/VuerServices";
import { getVueStatus } from "pages/dashboard/home/vues/vue-grid/utils/enums";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../constants";
import { mdiInformationOutline } from "@mdi/js";
import { tooltipStyles } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { vuersGridStyle } from "../styles/VuersStyles";

export const VueSubmissionGrid = ({ vuerID }: { vuerID: string }) => {
  const gridRef = useGridApiRef();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<SubmissionVue[]>([]);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [metaValues, setMetaValues] = useState<GridMetaData>(defaultMetaData);

  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value } = params;
    const { row }: { row: SubmissionVue } = params;
    const {
      vue: { canonicalId, title, createdAt: created, slaHours, address },
      status,
      pending,
      rejectionNotes,
      vuerCompletedAt: completed,
      lastUpdatedAt,
    } = row;
    if (field === "id") {
      return (
        <Link
          sx={{ ...linkElementStyle, cursor: "pointer" }}
          onClick={() => {
            navigate(`${RoutePath.vues}/${canonicalId}`);
          }}
          variant="button"
          color="primary"
          fontWeight="regular"
          underline="always"
        >
          #{canonicalId}
        </Link>
      );
    } else if (field === "title") {
      return <MDTypography sx={gridTextStyle}>{title}</MDTypography>;
    } else if (field === "status") {
      const statusValue = pending ? "PENDING REVIEW" : getVueStatus(status);
      return (
        <MDBox display="flex" alignItems="center">
          <MDTypography sx={gridTextStyle}>{statusValue}</MDTypography>
          {rejectionNotes && (
            <Tooltip
              title={rejectionNotes}
              componentsProps={{
                tooltip: {
                  sx: tooltipStyles,
                },
              }}
            >
              <IconButton>
                <Icon path={mdiInformationOutline} size={0.85} />
              </IconButton>
            </Tooltip>
          )}
        </MDBox>
      );
    } else if (field === "address") {
      return <MDTypography sx={gridTextStyle}>{address}</MDTypography>;
    } else if (field === "age/sla") {
      const hasVueCompleted =
        getVueStatus(status) === "COMPLETED" && completed.isNotEmpty();
      const agingHours = getAgingHours(completed, created, hasVueCompleted);
      const agingAndSLAHours = `${agingHours}/${slaHours} hrs`;
      return (
        <MDBox display="flex" flexDirection="column">
          <MDTypography sx={gridTextStyle}>{agingAndSLAHours}</MDTypography>
        </MDBox>
      );
    } else if (field === "updatedDate") {
      const dateTimeFormat = "MM/DD/YYYY - hh:mm:ss A";
      return (
        <MDTypography sx={{ ...gridTextStyle, pt: "10px" }}>
          {lastUpdatedAt.formatUTCNanoSecondsToString(dateTimeFormat, true)}
        </MDTypography>
      );
    }

    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{value}</MDBox>
      </MDBox>
    );
  };

  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      var newPageNumber = isScrolling
        ? (metaValues.pageNumber + 1).toString()
        : "1";
      var pageSize = DEFAULT_PAGE_SIZE;
      return `pagination.pageSize=${pageSize}&pagination.pageNumber=${newPageNumber}`;
    },
    [metaValues.pageNumber]
  );

  const fetchSubmissionListFromServer = useCallback(
    async (isScrolling: boolean) => {
      setShowLoader(true);
      const parameters = getParameterString(isScrolling);
      const response = await getVuesWithSubmissionStatusByUserID(
        vuerID,
        parameters
      );
      var gridData: SubmissionVue[] = [];
      if (response.status === WebServiceStatus.success) {
        const {
          submissions,
          meta,
        }: {
          submissions: SubmissionVue[];
          meta: GridMetaData;
        } = response.data;
        gridData = submissions.map((data, index) => {
          return getSubmissionRowData(data, index);
        });
        setMetaValues({
          pageSize: Number(meta.pageSize),
          pageNumber: Number(meta.pageNumber),
          totalPages: Number(meta.totalPages),
          totalElements: Number(meta.totalElements),
        });
      } else {
        setSnackBar({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
      setShowLoader(false);
      if (isScrolling) {
        setRowData(rowData.concat(gridData));
      } else {
        setRowData(gridData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    fetchSubmissionListFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnRowsScrollEnd: DataGridPremiumProps["onRowsScrollEnd"] =
    async () => {
      if (
        rowData.length < metaValues.totalElements &&
        metaValues.pageNumber < metaValues.totalPages
      ) {
        await fetchSubmissionListFromServer(true);
      }
    };

  const columnData = submissionGridColumnData.map((data) => {
    data.renderCell = getCustomCells;
    return data;
  });

  const getFooter = () => {
    return (
      <MDBox display="flex" alignItems="center">
        <DataGridBottomBar
          title={"Total Count"}
          totalItems={metaValues.totalElements}
        />
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <MDTypography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No vues found
        </MDTypography>
      </MDBox>
    );
  };

  return (
    <MDBox pt={2}>
      {showLoader && <CustomIndicator />}
      <Card sx={{ height: "calc(100vh - 176px)" }}>
        <DataGridPremium
          apiRef={gridRef}
          columns={columnData}
          rows={rowData}
          sx={{ ...gridStyle, ...vuersGridStyle }}
          density="compact"
          slots={{
            footer: getFooter,
            loadingOverlay: LinearProgress,
            noRowsOverlay: getNoRowsMessage,
          }}
          disableColumnMenu
          disableRowSelectionOnClick
          loading={showLoader}
          onRowsScrollEnd={handleOnRowsScrollEnd}
        />
      </Card>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};
