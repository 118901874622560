import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { ARDevice } from "../utils/interface";
import { useState } from "react";

interface Props {
  toUpdateDevice: boolean;
  closeDialog: () => void;
  arDevice?: ARDevice;
  onAddOrUpdate: (params: ARDevice) => void;
}

const AddDeviceDialogContent = (props: Props) => {
  const [brand, setBrand] = useState<string>(props.arDevice?.brand ?? "");
  const [model, setModel] = useState<string>(props.arDevice?.model ?? "");
  const [name, setName] = useState<string>(props.arDevice?.name ?? "");

  const shouldDisable = () => {
    const hasARValues =
      brand.trim().isNotEmpty() &&
      name.trim().isNotEmpty() &&
      model.trim().isNotEmpty();
    // Add a new device --> Enables the "SAVE" button if all the fields are filled
    if (!props.toUpdateDevice) {
      return !hasARValues;
    }
    // Edit a device --> Enables the "UPDATE" button if all the fields are filled &&
    // if any new value is entered
    return !(
      hasARValues &&
      (brand !== props.arDevice.brand ||
        model !== props.arDevice.model ||
        name !== props.arDevice.name)
    );
  };

  return (
    <>
      <DialogContent>
        <MDBox display="flex" mt={3} mb={3}>
          <MDBox flex={1}>
            <MDBox display="flex">
              <MDTypography sx={regularTextStyle}>Model</MDTypography>
              <RequiredFieldMarker />
            </MDBox>
            <MDInput
              value={model}
              placeholder="Enter Model"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setModel(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
          <MDBox flex={1} ml={1.2}>
            <MDBox display="flex">
              <MDTypography sx={regularTextStyle}>Brand</MDTypography>
              <RequiredFieldMarker />
            </MDBox>
            <MDInput
              value={brand}
              placeholder="Enter Brand"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBrand(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
        </MDBox>
        <MDBox mb={2}>
          <MDBox display="flex">
            <MDTypography sx={regularTextStyle}>Name</MDTypography>
            <RequiredFieldMarker />
          </MDBox>
          <MDInput
            value={name}
            placeholder="Enter Name"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            fullWidth
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CLOSE
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={shouldDisable()}
            onClick={() => {
              const params: ARDevice = {
                brand: brand,
                model: model,
                name: name,
              };
              props.onAddOrUpdate(params);
            }}
            sx={{ fontSize: "14px", padding: "11px 22px" }}
          >
            {props.toUpdateDevice ? "UPDATE" : "ADD"}
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default AddDeviceDialogContent;
