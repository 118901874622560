import { SurveyStepType } from "@ivueit/vue-engine";
import { DEFAULT_PAGE_SIZE } from "../../../constants";
import { ConditionOperator } from "../types/interfaces";

export const DEFAULT_DROP_DOWN_VALUE = "ALL";

export const DEFAULT_NEW_SURVEY_NAME = "New Survey";

export const SEARCH_COMPANIES_PAGE_SIZE = 1000;

export const SEARCH_COMPANIES_PAGE_NUMBER = 0;

export const INVALID_JSON_ERROR_FROM_API = "invalid input syntax for type json";

export const availableSurveyStatuses = [
  "ALL",
  "PUBLISHED",
  "ARCHIVED",
  "DRAFT",
].sort();

// Fetches the survey status from the text
export const getSurveyStatusFromFilterValue = (text: string): number => {
  switch (text.toLowerCase()) {
    case "published":
      return 1;
    case "archived":
      return 2;
    case "draft":
      return 3;
    default:
      return 0;
  }
};

// Generates the survey type filter value to send through the API
export const getSurveyTypeFilterValue = (name: string): number => {
  switch (name.toUpperCase()) {
    case "DAY":
      return 1;
    case "NIGHT":
      return 2;
    case "INTERIOR":
      return 3;
    case "INTERIOR EXTERIOR":
      return 4;
    case "RESIDENTIAL":
      return 5;
    case "RESIDENTIAL INTERIOR":
      return 6;
    case "RESIDENTIAL EXTERIOR":
      return 7;
    case "LANDSCAPE":
      return 8;
    case "SNOW":
      return 9;
    case "PARKING LOT":
      return 10;
    case "EXTERIOR":
      return 11;
    case "JANITORIAL":
      return 12;
    case "LIGHTING":
      return 13;
    case "SIGNAGE":
      return 14;
    case "DISASTER":
      return 15;
    case "VACANT":
      return 16;
    case "FLOORING":
      return 17;
    case "MEASUREMENT":
      return 18;
    case "SCHEDULED":
      return 19;
    default:
      return 0;
  }
};

// Options to be displayed on the filter popup
export const getAvailableSurveyTypes = (): string[] => {
  return [
    "ALL",
    "DAY",
    "NIGHT",
    "RESIDENTIAL",
    "INTERIOR",
    "INTERIOR EXTERIOR",
    "RESIDENTIAL INTERIOR",
    "RESIDENTIAL EXTERIOR",
    "LANDSCAPE",
    "SNOW",
    "PARKING LOT",
    "EXTERIOR",
    "JANITORIAL",
    "LIGHTING",
    "SIGNAGE",
    "DISASTER",
    "VACANT",
    "FLOORING",
    "MEASUREMENT",
    "SCHEDULED",
  ].sort();
};

export const getStringValueForTypes = (type: string) => {
  switch (type) {
    case "INTERIOR EXTERIOR":
      return "INTERIOR_EXTERIOR";
    case "RESIDENTIAL INTERIOR":
      return "RESIDENTIAL_INTERIOR";
    case "RESIDENTIAL EXTERIOR":
      return "RESIDENTIAL_EXTERIOR";
    default:
      return type;
  }
};

export const getEnumStringValueForTypes = (type: string) => {
  switch (type) {
    case "INTERIOR_EXTERIOR":
      return "INTERIOR EXTERIOR";
    case "RESIDENTIAL_INTERIOR":
      return "RESIDENTIAL INTERIOR";
    case "RESIDENTIAL_EXTERIOR":
      return "RESIDENTIAL EXTERIOR";
    default:
      return type;
  }
};

export const MEDIA_ORIENTATION_TYPES = ["Either", "Landscape", "Portrait"];

export const defaultMetaData = {
  pageSize: DEFAULT_PAGE_SIZE as number,
  pageNumber: 1,
  totalPages: 0,
  totalElements: 0,
};

export enum GroupColorHexValues {
  green = "#99FF99",
  blue = "#9999FF",
  aqua = "#99FFFF",
  purple = "#FF99FF",
  red = "#FF99994D",
  yellow = "#FFFF99",
}

// Below constants defines operators used to add conditions to the survey
export const EQUALS = { index: 0, value: "equals", serverValue: "EQUAL_TO" };
export const DOES_NOT_EQUALS = {
  index: 1,
  value: "does not equal",
  serverValue: "NOT_EQUAL_TO",
};
export const LESS_THAN = {
  index: 2,
  value: "less than",
  serverValue: "LESS_THAN",
};
export const LESS_THAN_OR_EQUALS = {
  index: 3,
  value: "less than or equal to",
  serverValue: "LESS_THAN_OR_EQUAL_TO",
};
export const GREATER_THAN = {
  index: 4,
  value: "greater than",
  serverValue: "GREATER_THAN",
};
export const GREATER_THAN_OR_EQUALS = {
  index: 5,
  value: "greater than or equal to",
  serverValue: "GREATER_THAN_OR_EQUAL_TO",
};
export const CONTAINS = {
  index: 6,
  value: "contains",
  serverValue: "CONTAINS_VALUE",
};
export const EXCLUDES = {
  index: 7,
  value: "excludes",
  serverValue: "EXCLUDES_VALUE",
};

export const COMBINE_LOGIC_AND = { value: "AND", displayTitle: "All" };
export const COMBINE_LOGIC_OR = { value: "OR", displayTitle: "Any" };

// List contains all supported operators in the condtion page
export const allOperators: ConditionOperator[] = [
  EQUALS,
  DOES_NOT_EQUALS,
  LESS_THAN,
  LESS_THAN_OR_EQUALS,
  GREATER_THAN,
  GREATER_THAN_OR_EQUALS,
  CONTAINS,
  EXCLUDES,
];

// List contains all supported condition types.
// Addtion/Removal of new options to this list should
// also update `getOperatorList` method
// Otherwise `getOperatorList` will return all available operators
export const supportedConditionTypes = [
  SurveyStepType.select,
  SurveyStepType.multiSelect,
  SurveyStepType.slider,
  SurveyStepType.bool,
];
