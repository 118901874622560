import { DialogContent, DialogActions } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { dialogText } from "pages/surveys/styles/SurveyStyles";

interface Props {
  message: string;
  actionTitle: string;
  closeDialog: (actionButtonClicked: boolean) => void;
}

const ConfirmRemoveDialogContent = (props: Props) => {
  return (
    <>
      <DialogContent sx={{ padding: "32px 29px !important" }}>
        <MDTypography
          variant="body"
          sx={{ ...dialogText, marginBottom: "10px" }}
        >
          {props.message}
        </MDTypography>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog(false);
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="error"
            size="medium"
            onClick={() => {
              props.closeDialog(true);
            }}
            sx={{ fontSize: "14px", padding: "11px 22px" }}
          >
            {props.actionTitle.toUpperCase()}
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default ConfirmRemoveDialogContent;
