import { Grid, Switch } from "@mui/material";
import Stack from "@mui/material/Stack";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { topBarButtonStyle } from "pages/surveys/styles/SurveyStyles";
import VuersAvatar from "./VuersAvatar";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { useCallback, useEffect, useState } from "react";
import AdjustDialogContent from "./AdjustDialogContent";
import CustomProgressbar from "components/CustomComponents/CustomProgressbar";
import { Vuer, VuerBackgroundCheckState } from "../interface/interface";
import { NearbyUserMap } from "./NearbyUserMap";
import {
  blacklistSMS,
  findUser,
  getUserBalance,
  getVuerBackgroundCheckState,
  isSMSWhitelisted,
  setUserType,
  whitelistSMS,
} from "../services/VuerServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { CustomIndicator, WebServiceResponse } from "@ivueit/vue-engine";
import { getTextlineURL, getVuerStatusValue } from "../utils/helpers";
import { BackgroundStatus, UserType } from "../utils/constants";
import { Link } from "react-router-dom";
import { formatMicrosecondsToDate } from "utils/helpers/extensions";
import { avatarContainerStyle } from "../styles/VuersStyles";

const VuerTabContent = ({ vuer }: { vuer: Vuer }) => {
  const [vuerData, setVuerData] = useState<Vuer>(vuer);
  const [openAdjustDialog, setOpenAdjustDialog] = useState<boolean>(false);
  const [isSMSBlacklisted, setIsSMSBlacklisted] = useState<boolean>(null);
  const [backgroundStatusAndDate, setBackgroundStatusAndDate] = useState({
    backgroundStatus: "",
    backgroundDate: "",
  });
  const { backgroundStatus, backgroundDate } = backgroundStatusAndDate;
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [balance, setBalance] = useState<number>(0);
  const {
    id,
    canonicalId,
    name,
    email,
    typeOf,
    phoneNumber,
    tosAccepted,
    failedVues,
    vuesFailedForgiven,
    completedVues,
    inProgressVues,
    latitude,
    longitude,
  } = vuerData;
  const hasProfessionalStatus = typeOf.toUpperCase() === UserType.professional;
  const [isProfessional, setIsProfessional] = useState<boolean>(
    hasProfessionalStatus
  );
  const tosAcceptedValue = tosAccepted ? "YES" : "NO";
  const emailAddress = email.length > 0 ? email : "(nothing here)";
  const parsedCompletedVues = parseInt(completedVues);
  const parsedFailedVues = parseInt(failedVues);
  const parsedVuesFailedForgiven = parseInt(vuesFailedForgiven);
  const appliedFailedVues = parsedFailedVues - parsedVuesFailedForgiven;
  const phoneNumberStatus = isSMSBlacklisted ? "WHITELIST" : "BLACKLIST";
  const textLineURL = getTextlineURL(phoneNumber, name);

  const handleSetOpenAdjustDialog = () => {
    setOpenAdjustDialog(true);
  };

  const getUserRating = () => {
    if (parsedCompletedVues === 0) {
      return 0;
    }
    return Math.round(
      (parsedCompletedVues / (parsedCompletedVues + appliedFailedVues)) * 100
    );
  };

  const getAdjustDialog = () => {
    return (
      <CustomDialogBox
        title={"ADJUST"}
        width="520px"
        openDialog={openAdjustDialog}
      >
        <AdjustDialogContent
          vuer={vuerData}
          closeDialog={() => {
            setOpenAdjustDialog(false);
          }}
          onSuccess={() => {
            getVuerDetail();
          }}
          onSucessAdjustment={() => {
            fetchUserBalance();
          }}
        />
      </CustomDialogBox>
    );
  };

  const getVuerDetail = useCallback(async () => {
    setShowLoader(true);
    const response = await findUser(`internalId=${id}`);
    if (response.status === WebServiceStatus.success) {
      const currentVuer = response.data.users[0] as Vuer;
      setVuerData(currentVuer);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to fetch the vuer detail: ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  }, [id]);

  const getPhoneNumberStatus = useCallback(async () => {
    setShowLoader(true);
    const response = await isSMSWhitelisted(phoneNumber);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.value as boolean;
      setIsSMSBlacklisted(!data);
    } else {
      setIsSMSBlacklisted(true);
      setSnackBar({
        title: "Attention!",
        message: `Unable to verify whether the phone number is whitelisted: ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  }, [phoneNumber]);

  const fetchUserBalance = useCallback(async () => {
    setShowLoader(true);
    const response = await getUserBalance(id);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.amount as number;
      setBalance(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to fetch user balance: ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  }, [id]);

  const handleOnVuerStatusChange = async () => {
    setShowLoader(true);
    const typeOfValue = getVuerStatusValue(
      isProfessional ? UserType.standard : UserType.professional
    );
    const param = {
      typeOf: typeOfValue,
    };
    const response = await setUserType(id, param);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as Vuer;
      const isProfessional =
        data.typeOf.toUpperCase() === UserType.professional;
      setIsProfessional(isProfessional);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to update Vuer status : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getCustomBadge = (content: string, bgColor: string) => {
    return (
      <CustomBadge
        content={content}
        max={parseInt(content)}
        sx={{
          ".MuiBadge-badge": {
            background: `${bgColor} !important`,
            color: "white !important",
          },
        }}
      />
    );
  };

  const onChangePhoneNumberStatus = async () => {
    setShowLoader(true);
    let response: WebServiceResponse;
    if (isSMSBlacklisted) {
      response = await whitelistSMS(phoneNumber);
    } else {
      response = await blacklistSMS(phoneNumber);
    }
    if (response.status === WebServiceStatus.success) {
      getPhoneNumberStatus();
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to ${
          isSMSBlacklisted ? "Whitelist" : "Blacklist"
        } phone number: ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getBackgroundCheckState = useCallback(async () => {
    setShowLoader(true);
    const response = await getVuerBackgroundCheckState(id);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as VuerBackgroundCheckState;
      const status = data.vuesVisibleOverride.toUpperCase();
      const finishedAtValue = data.finishedAt?.value ?? "";
      let bgStatus;
      if (data.id) {
        if (status === BackgroundStatus.forced_visible) {
          bgStatus = BackgroundStatus.proceed;
        } else if (status === BackgroundStatus.forced_not_visible) {
          bgStatus = BackgroundStatus.final_adverse;
        }
      }
      setBackgroundStatusAndDate({
        backgroundDate: finishedAtValue,
        backgroundStatus: bgStatus,
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to fetch the Vuer's background status: ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  }, [id]);

  const getFormattedBackgroundDateWithStatus = () => {
    const formattedDate = formatMicrosecondsToDate(backgroundDate);
    if (
      backgroundStatus === BackgroundStatus.clear ||
      backgroundStatus === BackgroundStatus.proceed
    ) {
      return (
        <>
          {formattedDate}
          {getCustomBadge("Verified", "#4ba64f")}
        </>
      );
    } else if (backgroundStatus === BackgroundStatus.final_adverse) {
      return (
        <>
          {formattedDate}
          {getCustomBadge("Not Verified", "#AE1709")}
        </>
      );
    } else {
      return "";
    }
  };

  useEffect(() => {
    getPhoneNumberStatus();
  }, [getPhoneNumberStatus]);

  useEffect(() => {
    fetchUserBalance();
  }, [fetchUserBalance]);

  useEffect(() => {
    getBackgroundCheckState();
  }, [getBackgroundCheckState]);

  return (
    <>
      {openAdjustDialog && getAdjustDialog()}
      {showLoader && <CustomIndicator />}
      <Grid
        container
        spacing={2}
        mb={3}
        mt={0}
        sx={{ marginBottom: "0px !important" }}
        minHeight="calc(100vh - 160px)"
      >
        <Grid
          item
          xs={4}
          sx={{ borderRight: "1px solid #a8b8d8", marginTop: "-60px" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="start"
            mb={3}
            mt={0}
            pl="16px"
          >
            <Grid item>
              <MDBox sx={avatarContainerStyle}>
                <VuersAvatar
                  vuer={vuerData}
                  blacklistUser={() => {
                    blacklistSMS(phoneNumber);
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item flexGrow={1}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                {getCustomBadge(`# ${canonicalId}`, "#344767")}
                <MDBox display="flex">
                  <MDTypography
                    variant="h5"
                    fontWeight="medium"
                    mr="5px"
                    my="auto"
                  >
                    {name}
                  </MDTypography>
                </MDBox>
                <MDBox pt={1.2} pr={2}>
                  <MDTypography
                    variant="button"
                    color="dark"
                    fontWeight="regular"
                  >
                    Rating
                  </MDTypography>
                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <CustomProgressbar value={getUserRating()} />
                  </Stack>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox display="flex">
                <MDBox
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <MDTypography variant="button" sx={{ fontWeight: "700" }}>
                    COMPLETED
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="success"
                    sx={{ fontWeight: "500" }}
                  >
                    {completedVues}
                  </MDTypography>
                </MDBox>
                <MDBox
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <MDTypography variant="button" sx={{ fontWeight: "700" }}>
                    IN PROGRESS
                  </MDTypography>
                  <MDTypography variant="button" sx={{ fontWeight: "500" }}>
                    {inProgressVues}
                  </MDTypography>
                </MDBox>
                <MDBox
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <MDTypography variant="button" sx={{ fontWeight: "700" }}>
                    FAILED
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="error"
                    sx={{ fontWeight: "500" }}
                  >
                    {appliedFailedVues}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ borderTop: "1px solid #a8b8d8", marginTop: "15px" }}
            >
              <MDBox>
                <MDTypography variant="button" fontWeight="medium" mr={0.5}>
                  ToS Accepted:
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="dark"
                  fontWeight="regular"
                >
                  {tosAcceptedValue}
                </MDTypography>
              </MDBox>
              {parseInt(backgroundDate) > 0 && (
                <MDBox>
                  <MDTypography variant="button" fontWeight="medium" mr={0.5}>
                    Background Check:
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="dark"
                    fontWeight="regular"
                  >
                    {getFormattedBackgroundDateWithStatus()}
                  </MDTypography>
                </MDBox>
              )}
              <MDBox>
                <MDTypography variant="button" fontWeight="medium" mr={0.5}>
                  Phone:
                </MDTypography>
                <Link
                  to={textLineURL}
                  target="_blank"
                  style={{ marginRight: "10px" }}
                >
                  <MDTypography
                    variant="button"
                    sx={{
                      cursor: "pointer",
                      color: "#1A73E8 !important",
                      textDecoration: "underline",
                    }}
                    fontWeight="regular"
                  >
                    {phoneNumber}
                  </MDTypography>
                </Link>
                {isSMSBlacklisted && getCustomBadge("Blacklisted", "#AE1709")}
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="medium" mr={0.5}>
                  Email:
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="dark"
                  fontWeight="regular"
                >
                  {emailAddress}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="medium" mr={0.5}>
                  Wallet:
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="dark"
                  fontWeight="regular"
                >
                  {`$ ${balance.toFixed(2)}`}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="medium" mr={0.5}>
                  Failed Vues:
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="dark"
                  fontWeight="regular"
                >
                  {failedVues}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="medium" mr={0.5}>
                  Failed Vues Ignored:
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="dark"
                  fontWeight="regular"
                >
                  {vuesFailedForgiven}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="medium" mr={0.5}>
                  Failed Vues Applied:
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="dark"
                  fontWeight="regular"
                >
                  {appliedFailedVues}
                </MDTypography>
              </MDBox>
              <MDBox mt={1.2}>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="medium"
                  sx={topBarButtonStyle}
                  onClick={handleSetOpenAdjustDialog}
                >
                  ADJUST
                </MDButton>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox mt={2}>
                <MDTypography variant="h6">Vuer Status</MDTypography>
                <MDBox display="flex" alignItems="center" mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ userSelect: "none" }}
                  >
                    {typeOf.toUpperCase() === UserType.preferred
                      ? "Preferred"
                      : "Standard"}
                  </MDTypography>
                  <Switch
                    onChange={handleOnVuerStatusChange}
                    checked={isProfessional}
                    color="error"
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ userSelect: "none" }}
                  >
                    Professional
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDButton
                variant="contained"
                color={isSMSBlacklisted ? "success" : "error"}
                size="medium"
                sx={{ fontSize: "14px" }}
                onClick={onChangePhoneNumberStatus}
              >
                {phoneNumberStatus}
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <NearbyUserMap latitude={latitude} longitude={longitude} />
        </Grid>
      </Grid>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default VuerTabContent;
