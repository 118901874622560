import DashboardContentLayout from "layouts/DashboardContentLayout";
import VuersDataGrid from "./components/VuersDataGrid";

const Vuers = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <VuersDataGrid />
    </DashboardContentLayout>
  );
};

export default Vuers;
