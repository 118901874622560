import { Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { mdiAccountEdit, mdiPencil } from "@mdi/js";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import ProfileSectionDivider from "pages/profile/components/ProfileSectionDivider";
import MDButton from "components/MDButton";
import { useEffect, useRef, useState } from "react";
import CustomDialogBox from "pages/components/CustomDialogBox";
import EditContactInfoDialogContent from "./EditContactInfoDialogContent";
import EditClientProfileDialogContent from "./EditClientProfileDialogContent";
import {
  Company,
  UserInfo,
} from "pages/profile/utils/ProfileInterfaces";
import UserImageAvatar from "pages/vuers/components/UserImageAvatar";
import {
  editCompanyActivation,
  getApiKeysForCompanyID,
  updateCompanyByID,
} from "../services/services";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { WebServiceStatus } from "utils/services/AppUrls";
import { uploadFile } from "pages/profile/services/ProfileServices";
import imageCompression from "browser-image-compression";
import { getCompanyDetailsById } from "pages/dashboard/home/common/services/services";
import { clientAvatarContainerStyle } from "../styles/ClientStyles";

const ClientTabContent = ({
  company,
  usersList,
}: {
  company: Company;
  usersList?: UserInfo[];
}) => {
  const hiddenFileInputRef = useRef(null);
  const [client, setClient] = useState<Company>(company);
  const [showLoader, setShowLoader] = useState(false);
  const [openContactInfoDialog, setOpenContactInfoDialog] =
    useState<boolean>(false);
  const [openEditClientProfileDialog, setOpenEditClientProfileDialog] =
    useState<boolean>(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);

  const handleSetOpenContactInfoDialog = () => {
    setOpenContactInfoDialog(true);
  };

  const handleSetOpenEditClientProfileDialog = () => {
    setOpenEditClientProfileDialog(true);
  };

  const getCreatedAtDate = () => {
    const dateTimeFormat = "MM/DD/YYYY - hh:mm:ss A";
    return client.createdAt.formatUTCNanoSecondsToString(dateTimeFormat, true);
  };

  const updateCompanyInfo = async (
    updatedCompany: Company,
    changeActivationStatus?: boolean
  ) => {
    setShowLoader(true);
    const response = await updateCompanyByID(updatedCompany);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as Company;
      if (changeActivationStatus) {
        const response = await getApiKeysForCompanyID(client.id);
        if (response.status === WebServiceStatus.success) {
          const apiKeysList = response.data.apiKeys;
          if (usersList !== null) {
            const emailList = usersList.map((user) => {
              return user.email;
            });
            const params = {
              active: !data.archived,
              emails: emailList,
              apiKeys: apiKeysList,
            };
            const response = await editCompanyActivation(params);
            if (response.status === WebServiceStatus.success) {
              setSnackBar({
                title: "Success!",
                message: "Activation status updated successfully.",
                isError: false,
              });
            } else {
              setSnackBar({
                title: "Attention!",
                message: `Unable to update the activation status: ${response.error}`,
                isError: true,
              });
              setShowLoader(false);
              return;
            }
          } else {
            setSnackBar({
              title: "Attention!",
              message: "Unable to fetch the org users.",
              isError: true,
            });
            setShowLoader(false);
            return;
          }
        } else {
          setSnackBar({
            title: "Attention!",
            message: `Unable to fetch the API keys for the company : ${response.error}`,
            isError: true,
          });
          setShowLoader(false);
          return;
        }
      }
      setClient(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to update the Company details : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const onFileUpload = (pickedFile: File, imageKey: string) => {
    const reader = new FileReader();
    reader.onloadend = async (event) => {
      const dataURL = reader.result as string;
      const extractedUrl = dataURL.split(",")[1];
      const fileName = pickedFile.name;
      const mimeType = pickedFile.type;
      const response = await uploadFile({
        filename: fileName,
        mimeType: mimeType,
        data: extractedUrl,
      });
      if (response.status === WebServiceStatus.success) {
        const newImagegIdObj = { [imageKey]: response.data };
        const updatedInfo: Company = { ...client, ...newImagegIdObj };
        updateCompanyInfo(updatedInfo);
      } else {
        setSnackBar({
          title: "Attention!",
          message: `Unable to upload file : ${response.error}`,
          isError: true,
        });
      }
    };
    reader.readAsDataURL(pickedFile);
  };

  const getEditClientProfileDialog = () => {
    return (
      <CustomDialogBox
        title={"Edit Client Details"}
        width="450px"
        openDialog={openEditClientProfileDialog}
      >
        <EditClientProfileDialogContent
          closeDialog={() => {
            setOpenEditClientProfileDialog(false);
          }}
          name={client.name}
          fileId={client.logoFileId}
          onSuccess={(updatedObj: { [key: string]: string }) => {
            const updatedInfo: Company = { ...client, ...updatedObj };
            updateCompanyInfo(updatedInfo);
          }}
          handleFileUpload={(file: File) => {
            onFileUpload(file, "logoFileId");
          }}
        />
      </CustomDialogBox>
    );
  };

  const getContactInfoDialog = () => {
    return (
      <CustomDialogBox
        title={"Contact Information"}
        width="620px"
        openDialog={openContactInfoDialog}
      >
        <EditContactInfoDialogContent
          closeDialog={() => {
            setOpenContactInfoDialog(false);
          }}
          company={client}
          onSuccess={(updatedInfo: Company) => {
            updateCompanyInfo(updatedInfo);
          }}
        />
      </CustomDialogBox>
    );
  };

  const onPricingUpdate = () => {
    const pricingValue = { pricingVisible: !client.pricingVisible };
    const updatedInfo: Company = { ...client, ...pricingValue };
    updateCompanyInfo(updatedInfo);
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pickedFile = event.target.files && event.target.files[0];
    const MAX_FILE_SIZE_IN_MB = 0.78125; /// 800KB
    const FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
    if (!pickedFile.type.startsWith("image/")) {
      setSnackBar({
        title: "Attention!",
        message: "Please choose a valid image file.",
        isError: true,
      });
      return;
    }
    if (pickedFile !== undefined) {
      /// Handles smaller image
      if (pickedFile.size <= FILE_SIZE_IN_BYTES) {
        onFileUpload(pickedFile, "pdfLogoFileId");
        return;
      }
      /// Handles larger image
      const options = {
        maxSizeMB: MAX_FILE_SIZE_IN_MB,
        useWebWorker: true,
        maxWidthOrHeight: 800,
      };
      try {
        const compressedFile = await imageCompression(pickedFile, options);
        onFileUpload(compressedFile, "pdfLogoFileId");
      } catch (error) {
        console.log("Error caught while compressing file:", error);
      }
    }
  };

  const updateActivationStatus = () => {
    const activationStatus = { archived: !client.archived };
    const updatedInfo: Company = { ...client, ...activationStatus };
    updateCompanyInfo(updatedInfo, true);
  };

  const getCompanyDetailsByID = async () => {
    const response = await getCompanyDetailsById(client.id);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as Company;
      setClient(data);
    } else {
      console.log("Cannot fetch company details");
    }
  };

  useEffect(() => {
    getCompanyDetailsByID();
  }, []);

  return (
    <>
      {showLoader && <CustomIndicator />}
      {openContactInfoDialog && getContactInfoDialog()}
      {openEditClientProfileDialog && getEditClientProfileDialog()}
      <MDBox>
        <Grid
          container
          spacing={3}
          alignItems="center"
          mb={5}
          mt={-8}
          pl="16px"
        >
          <Grid item>
            <MDBox sx={clientAvatarContainerStyle}>
              <UserImageAvatar
                imageId={client.logoFileId}
                size="xl"
                shadow="sm"
              />
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDBox display="flex" mb={0.4}>
                <MDTypography
                  variant="h5"
                  fontWeight="medium"
                  mr="5px"
                  my="auto"
                >
                  {client.name}
                </MDTypography>
              </MDBox>
              <CustomBadge
                content={client.archived ? "Inactive" : "Active"}
                sx={{
                  ".MuiBadge-badge": {
                    background: `${
                      client.archived ? "#AE1709" : "#4CAF50"
                    } !important`,
                    color: "white !important",
                  },
                }}
              />
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox mt={-3}>
              <ButtonWithIcon
                iconPath={mdiAccountEdit}
                onClick={handleSetOpenEditClientProfileDialog}
              >
                Edit
              </ButtonWithIcon>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mb={3} mx={2.6}>
          <MDBox mb={2}>
            <MDTypography
              variant="button"
              fontWeight="medium"
              display="block"
              mb={0.5}
            >
              Created on
            </MDTypography>
            <MDTypography
              variant="button"
              color="dark"
              fontWeight="regular"
              display="block"
            >
              {getCreatedAtDate()}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mx={2.6}>
          <Grid container spacing={3} alignItems="center" mb={2}>
            <Grid item>
              <MDTypography variant="h6" fontWeight="medium">
                Contact Information
              </MDTypography>
            </Grid>
            <Grid item>
              <ButtonWithIcon
                iconPath={mdiAccountEdit}
                onClick={handleSetOpenContactInfoDialog}
              >
                Edit
              </ButtonWithIcon>
            </Grid>
            <ProfileSectionDivider />
          </Grid>
        </MDBox>
        <MDBox mb={3} mx={2.6}>
          <MDBox mb={2}>
            <MDTypography
              variant="button"
              fontWeight="medium"
              display="block"
              mb={0.5}
            >
              iVueit Account Manager
            </MDTypography>
            <MDTypography
              variant="button"
              color="dark"
              fontWeight="regular"
              display="block"
            >
              {client.accountManager ? client.accountManager : "---"}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography
              variant="button"
              fontWeight="medium"
              display="block"
              mb={0.5}
            >
              Primary Contact Email
            </MDTypography>
            <MDTypography
              variant="button"
              color="dark"
              fontWeight="regular"
              display="block"
            >
              {client.primaryEmail ? client.primaryEmail : "---"}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography
              variant="button"
              fontWeight="medium"
              display="block"
              mb={0.5}
            >
              Billing Contact Email
            </MDTypography>
            <MDTypography
              variant="button"
              color="dark"
              fontWeight="regular"
              display="block"
            >
              {client.billingEmail ? client.billingEmail : "---"}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography
              variant="button"
              fontWeight="medium"
              display="block"
              mb={0.5}
            >
              Address
            </MDTypography>
            <MDTypography
              variant="button"
              color="dark"
              fontWeight="regular"
              display="block"
            >
              {client.address ? client.address : "---"}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mx={2.6}>
          <Grid container spacing={3} alignItems="center" mb={2}>
            <Grid item>
              <MDTypography variant="h6" fontWeight="medium">
                PDF Logo
              </MDTypography>
            </Grid>
            <Grid item>
              <ButtonWithIcon
                iconPath={mdiPencil}
                onClick={() => {
                  hiddenFileInputRef.current.click();
                }}
              >
                Edit
              </ButtonWithIcon>
              <input
                type="file"
                accept="image/*"
                ref={hiddenFileInputRef}
                onChange={handleFileInputChange}
                style={{ display: "none" }}
              />
            </Grid>
            <ProfileSectionDivider />
          </Grid>
        </MDBox>
        <MDBox mb={4} mx={3.2} sx={clientAvatarContainerStyle}>
          <UserImageAvatar
            imageId={client.pdfLogoFileId}
            size="xl"
            shadow="sm"
          />
        </MDBox>
        <MDBox mx={2.6} mb={2}>
          <Grid container spacing={3} alignItems="center" mb={2}>
            <Grid item>
              <MDTypography variant="h6" fontWeight="medium">
                More Options
              </MDTypography>
            </Grid>
            <ProfileSectionDivider />
          </Grid>
        </MDBox>
        <MDBox mx={2.6}>
          <MDTypography
            variant="button"
            fontWeight="medium"
            display="block"
            mb={0.5}
          >
            Client pricing visible ?
          </MDTypography>
          <MDBox display="flex" alignItems="center" mb={1}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              No
            </MDTypography>
            <Switch
              checked={client.pricingVisible}
              onChange={onPricingUpdate}
              color="info"
            />
            <MDTypography variant="button" fontWeight="regular" color="dark">
              Yes
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mx={2.6} mt={4} mb={5}>
          <MDButton
            variant="contained"
            color={client.archived ? "success" : "error"}
            size="medium"
            sx={{ fontSize: "14px" }}
            onClick={updateActivationStatus}
          >
            {client.archived ? "ACTIVATE" : "DEACTIVATE"}
          </MDButton>
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default ClientTabContent;
