import { Select, MenuItem, FormControl } from "@mui/material";
import MDBox from "components/MDBox";

interface Props {
  value?: string;
  defaultValue?: string;
  optionsList: string[];
  onChange?: (option: string) => void;
}

export const CustomSingleSelect = (props: Props) => {
  const { value, optionsList, onChange, defaultValue } = props;

  const defaultOption = defaultValue ?? optionsList.length > 0 ? optionsList[0] : '';

  return (
    <MDBox>
      <FormControl sx={{ minWidth: 80 }} size="small">
        <Select
          defaultValue={defaultOption}
          sx={{ mt: "10px" }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value?.toUpperCase()}
        >
          {optionsList.map((item, index) => (
            <MenuItem key={index} value={item.toUpperCase()} sx={{ minWidth: "80px" }}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MDBox>
  );
};
