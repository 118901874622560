import DashboardContentLayout from "layouts/DashboardContentLayout";
import ClientsDataGrid from "./components/ClientsDataGrid";

const Clients = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <ClientsDataGrid />
    </DashboardContentLayout>
  );
};

export default Clients;
