import { linkElementStyle } from "@ivueit/vue-engine";
import { Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { SubmissionVue, Transaction } from "../interface/interface";
import moment from "moment";
import { convertNanoToMilliSeconds } from "utils/helpers/extensions";
import { TransactionType } from "./constants";

export const getVuerStatusValue = (userType: string) => {
  switch (userType.toUpperCase()) {
    case "STANDARD":
      return 0;
    case "PREFERRED":
      return 1;
    case "PROFESSIONAL":
      return 2;
    default:
      break;
  }
};

const vuerListColumns = [
  {
    field: "canonicalId",
    header: "VUER ID",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 150,
    },
  },
  {
    field: "vuer",
    header: "VUER",
    config: {
      width: 350,
      minWidth: 300,
      maxWidth: 400,
    },
  },
  { field: "completedVues", header: "COMPLETED VUES" },
  { field: "failedVues", header: "FAILED VUES" },
  { field: "typeOf", header: "VUER STATUS" },
];

// Generating default column data
const getVuerColumnData = (): GridColDef[] => {
  return vuerListColumns.map((column) => {
    let columnData = {
      field: column.field,
      headerName: column.header.toUpperCase(),
      sortable: false,
      editable: false,
      flex: 1,
    };

    if (column.config) {
      columnData = { ...columnData, ...column.config };
    }

    return columnData;
  });
};

export const vuerColumnData: GridColDef[] = getVuerColumnData();

/// This returns the link component for the cell "VueId/SiteName"
export const getLinkElement = (value: string, onClick: () => void) => {
  return (
    <Link
      sx={{ ...linkElementStyle, cursor: "pointer" }}
      onClick={onClick}
      variant="button"
      color="primary"
      fontWeight="regular"
      underline="always"
    >
      {value}
    </Link>
  );
};

export const getTextlineURL = (phoneNumber: string, name: string) => {
  const textLineURL = `https://application.textline.com/conversations?phone_number=${phoneNumber}&message=Hi%20${name}%20,%20this%20is%20iVueit%20support`;
  return textLineURL;
};

/// VUES SUBMISSION GRID
export const submissionGridColumns = [
  {
    field: "id",
    header: "ID",
    config: {
      width: 70,
      minWidth: 70,
      maxWidth: 110,
    },
  },
  {
    field: "title",
    header: "TITLE",
    config: {
      width: 420,
      minWidth: 420,
      maxWidth: 480,
    },
  },
  {
    field: "status",
    header: "STATUS",
    config: {
      width: 130,
      minWidth: 130,
      maxWidth: 200,
    },
  },
  {
    field: "address",
    header: "ADDRESS",
    config: {
      width: 380,
      minWidth: 380,
      maxWidth: 440,
    },
  },
  {
    field: "age/sla",
    header: "AGE/SLA",
    config: {
      width: 130,
      minWidth: 130,
      maxWidth: 200,
    },
  },
  {
    field: "updatedDate",
    header: "UPDATED DATE",
    config: {
      width: 275,
      minWidth: 275,
      maxWidth: 300,
    },
  },
];

const getSubmissionGridData = (): GridColDef[] => {
  return submissionGridColumns.map((column) => {
    let columnData = {
      field: column.field,
      headerName: column.header.toUpperCase(),
      sortable: false,
      editable: false,
    };

    if (column.config) {
      columnData = { ...columnData, ...column.config };
    }

    return columnData;
  });
};

export const submissionGridColumnData: GridColDef[] = getSubmissionGridData();

export const getSubmissionRowData = (
  data: SubmissionVue,
  index: number
): SubmissionVue => {
  const {
    vue,
    status,
    rejectionNotes,
    lastUpdatedAt,
    vuerCompletedAt,
    pending,
    pendingNotes,
  } = data;
  return {
    id: index,
    vue: {
      id: vue.id,
      canonicalId: vue.canonicalId,
      title: vue.title,
      description: vue.description,
      notes: vue.notes,
      typeOf: vue.typeOf,
      address: vue.address,
      latitude: vue.latitude,
      longitude: vue.longitude,
      payout: vue.payout,
      cost: vue.cost,
      surveyTemplateId: vue.surveyTemplateId,
      authorId: vue.authorId,
      submissionId: vue.submissionId,
      slaHours: vue.slaHours,
      startsAt: vue.startsAt,
      endsAt: vue.endsAt,
      startsAtDst: vue.startsAtDst,
      endsAtDst: vue.endsAtDst,
      tz: vue.tz,
      publishAt: vue.publishAt,
      createdAt: vue.createdAt,
      expiresAt: vue.expiresAt,
      pdfFileId: vue.pdfFileId,
      pdfFileIdSurvey: vue.pdfFileIdSurvey,
      pdfFileIdPhotos: vue.pdfFileIdPhotos,
      internalEntityId: vue.internalEntityId,
      meta: vue.meta,
      escalated: vue.escalated,
      pdfFileIdOrdered: vue.pdfFileIdOrdered,
      claimTimeLimitInMinutes: vue.claimTimeLimitInMinutes,
      selectedDays: vue.selectedDays,
      arRequired: vue.arRequired,
      includePhotoTimestamp: vue.includePhotoTimestamp,
      slaStart: vue.slaStart,
      backgroundCheckRequired: vue.backgroundCheckRequired,
      highQualityRequired: vue.highQualityRequired,
      urgent: vue.urgent,
      canUseReferral: vue.canUseReferral,
    },
    status: status,
    rejectionNotes: rejectionNotes,
    lastUpdatedAt: lastUpdatedAt,
    vuerCompletedAt: vuerCompletedAt,
    pending: pending,
    pendingNotes: pendingNotes,
  };
};

export const getAgingHours = (
  completedAt: string,
  createdAt: string,
  hasVueCompleted: boolean
) => {
  const createdVal = parseInt(createdAt);
  const createdAtValueInMilliSeconds = convertNanoToMilliSeconds(createdVal);
  if (hasVueCompleted) {
    const completedVal = parseInt(completedAt);
    const completedAtValueInMilliSeconds =
      convertNanoToMilliSeconds(completedVal);
    return Math.floor(
      moment(completedAtValueInMilliSeconds).diff(
        moment(createdAtValueInMilliSeconds),
        "hours"
      )
    );
  } else {
    return Math.floor(
      moment().diff(moment(createdAtValueInMilliSeconds), "hours")
    );
  }
};

/// VUES TRANSACTION GRID
export const transactionGridColumns = [
  {
    field: "id",
    header: "ID",
    config: {
      width: 70,
      minWidth: 70,
      maxWidth: 110,
    },
  },
  {
    field: "type",
    header: "TYPE",
    config: {
      width: 130,
      minWidth: 130,
      maxWidth: 200,
    },
  },
  {
    field: "amount",
    header: "AMOUNT",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 200,
    },
  },
  {
    field: "time",
    header: "CREATED AT",
    config: {
      width: 220,
      minWidth: 220,
      maxWidth: 250,
    },
  },
  {
    field: "description",
    header: "DESCRIPTION",
    config: {
      width: 150,
      minWidth: 150,
      maxWidth: 200,
    },
  },
  {
    field: "notes",
    header: "NOTES",
    config: {
      width: 500,
      minWidth: 550,
      maxWidth: 550,
    },
  },
];

const getTransactionGridData = (): GridColDef[] => {
  return transactionGridColumns.map((column) => {
    let columnData = {
      field: column.field,
      headerName: column.header.toUpperCase(),
      sortable: false,
      editable: false,
    };

    if (column.config) {
      columnData = { ...columnData, ...column.config };
    }

    return columnData;
  });
};

export const transactionGridColumnData: GridColDef[] = getTransactionGridData();

export const getTransactionRowData = (data: Transaction): Transaction => {
  return {
    id: data.id,
    canonical_id: data.canonical_id,
    userId: data.userId,
    amount: data.amount,
    automated: data.automated,
    typeOf: data.typeOf,
    shortDescription: data.shortDescription,
    notes: data.notes,
    createdAt: data.createdAt,
    createdBy: data.createdBy,
  };
};

export const getTransactionTypeInString = (type: string) => {
  const typeInUpperCase = type.toUpperCase();
  if (typeInUpperCase === TransactionType.earning) {
    return "Earning";
  } else if (typeInUpperCase === TransactionType.amendment) {
    return "Admin Adjust";
  } else {
    return "Unknown type";
  }
};
