import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { HeaderStepSection } from "./HeaderStepSection";
import { searchBarStyle } from "pages/dashboard/home/vues/styles/VueStyles";
import MDInput from "components/MDInput";
import { CustomPreviewComponent } from "./CustomPreviewComponent";
import { inputNumberStyle, regularTextStyle } from "../styles/SurveyStyles";
import { WebServiceStatus, SurveyStep } from "@ivueit/vue-engine";
import { TEXTFIELD_CHANGE_DELAY } from "../../../constants";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { updateSurveyTemplateStep } from "../services/SurveyServices";
import { StepActionType } from "../types/enums";
import { SurveyGroupWithStep, VueSurveyData } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import {
  hasConditionsOnSurvey,
  hasConditionsOnSurveyStep,
  isGroupOrphaned,
} from "../utils/helpers";

interface Props {
  serialNumber: string;
  hasBeenGrouped: boolean;
  hasConditionsApplied: boolean;
  showAddConditionButton: boolean;
  step: SurveyStep;
  groupName: string;
  onSuccess: (step: SurveyStep) => void;
  surveyData: VueSurveyData;
  onDeleteGroup: (id: string) => void;
  onHeaderClick: (type: StepActionType) => void;
  groupIndex: number;
}
const DraftModeSliderComponent = (props: Props) => {
  const [delayTypeAction, setDelayTypeAction] = useState<boolean>(false);
  const [error, setError] = useState({
    prompt: "",
    minValue: "",
    maxValue: "",
    segments: "",
  });
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);
  const { serialNumber, step, hasBeenGrouped } = props;
  const [sliderStep, setSliderStep] = useState<SurveyStep>(step);
  const { id, description, sliderSegments, sliderMin, sliderMax } = sliderStep;
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);

  const handleMinValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const min = parseInt(value);
    const isValid = !isNaN(min) && min >= 0 && min < sliderMax;
    setSliderStep({ ...sliderStep, [event.target.name]: value });
    setError({ ...error, minValue: !isValid ? "Invalid min value" : "" });
    setDelayTypeAction(isValid);
  };

  const handleMaxValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const max = parseInt(value);
    const isValid = !isNaN(max) && max > 0 && sliderMin < max;
    setSliderStep({ ...sliderStep, [event.target.name]: value });
    setError({ ...error, maxValue: !isValid ? "Invalid max value" : "" });
    setDelayTypeAction(isValid);
  };

  const handleSegmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const segment = parseInt(value);
    const isValid = !isNaN(segment) && segment > 0;
    setSliderStep({ ...sliderStep, [event.target.name]: value });
    setError({ ...error, segments: !isValid ? "Invalid segments" : "" });
    setDelayTypeAction(isValid);
  };

  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSliderStep({ ...sliderStep, [event.target.name]: value });
    setError({ ...error, prompt: !value ? "Prompt cannot be empty" : "" });
    setDelayTypeAction(value.isNotEmpty());
  };

  const updateGroupIdForStep = () => {
    // Updating the groupId for this step
    setSliderStep({ ...sliderStep, groupId: "" });
    setDelayTypeAction(true);
  };

  const deleteGroup = () => {
    const hasSurveyConditions = hasConditionsOnSurvey(props.surveyData, step);
    const hasStepConditions = hasConditionsOnSurveyStep(props.surveyData, step);
    // TODO - Allow/deny based on specific scenaario, for now deny all
    // when ungrouping, stepcondition could contain condition from step in group, if so deny
    // always deny if parent step of condition, as only other steps of same group could be linked
    if (hasSurveyConditions || hasStepConditions) {
      setSnackBar({
        title: "Attention!",
        message: "This step is linked to another, cannot ungroup step.",
        isError: true,
      });
      return;
    }
    const islastStepInTheGroup = isGroupOrphaned(
      step,
      props.surveyData.survey.steps
    );
    const groupID = islastStepInTheGroup ? sliderStep.groupId : "";
    if (groupID) {
      // Removing the group if the group is orphaned
      props.onDeleteGroup(groupID);
    }
    updateGroupIdForStep();
  };

  const handleUpdateSurveyStep = useCallback(async () => {
    if (id.isEmpty()) {
      return;
    }
    setShowLoader(true);
    const response = await updateSurveyTemplateStep(id, sliderStep);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as SurveyStep;
      props.onSuccess(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to update Slider step.",
        isError: true,
      });
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderStep, id]);

  useEffect(() => {
    if (sliderMin + sliderMax + sliderSegments === 0) {
      setDelayTypeAction(true);
      setSliderStep({
        ...sliderStep,
        sliderMin: 0,
        sliderMax: 10,
        sliderSegments: 10,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (delayTypeAction) {
      const delaySearchAction = setTimeout(() => {
        handleUpdateSurveyStep();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
  }, [handleUpdateSurveyStep, delayTypeAction]);

  return (
    <MDBox p={2}>
      <HeaderStepSection
        groupName={props.groupName}
        stepNumber={serialNumber}
        stepType={"Slider"}
        isGrouped={hasBeenGrouped}
        onButtonClick={(type: StepActionType) => {
          type === StepActionType.ungroup
            ? deleteGroup()
            : props.onHeaderClick(type);
        }}
        hasConditionApplied={props.hasConditionsApplied}
        showAddConditionButton={props.showAddConditionButton}
        groupIndex={props.groupIndex}
      />
      <MDBox
        border="1px solid #C7CCD0"
        p="16px 16px 20px 16px"
        borderRadius="8px"
      >
        <MDBox mb={1}>
          <MDInput
            fullWidth
            error={error.prompt}
            name="description"
            value={description}
            placeholder="Enter Prompt"
            multiline
            rows={2}
            onChange={handlePromptChange}
          />
        </MDBox>
        <MDBox display="flex">
          <MDBox>
            <MDTypography sx={regularTextStyle}>Min</MDTypography>
            <MDInput
              name="sliderMin"
              type="number"
              error={error.minValue}
              value={sliderMin}
              placeholder="Min"
              sx={{ ...searchBarStyle, ...inputNumberStyle }}
              onChange={handleMinValueChange}
            />
          </MDBox>
          <MDBox ml={1.2}>
            <MDTypography sx={regularTextStyle}>Max</MDTypography>
            <MDInput
              name="sliderMax"
              type="number"
              placeholder="Max"
              error={error.maxValue}
              value={sliderMax}
              sx={{ ...searchBarStyle, ...inputNumberStyle }}
              onChange={handleMaxValueChange}
            />
          </MDBox>
          <MDBox ml={1.2}>
            <MDTypography sx={regularTextStyle}>Segments</MDTypography>
            <MDInput
              name="sliderSegments"
              type="number"
              error={error.segments}
              value={sliderSegments}
              placeholder="Segments"
              sx={{ ...searchBarStyle, ...inputNumberStyle }}
              onChange={handleSegmentChange}
            />
          </MDBox>
        </MDBox>
        <MDBox pt={1}>
          <CustomPreviewComponent step={sliderStep} />
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};

export default DraftModeSliderComponent;
