import MDBox from "components/MDBox";
import { HeaderStepSection } from "./HeaderStepSection";
import { MEDIA_ORIENTATION_TYPES } from "../utils/constants";
import MDTypography from "components/MDTypography";
import { searchBarStyle } from "pages/dashboard/home/vues/styles/VueStyles";
import { CustomSingleSelect } from "pages/dashboard/components/CustomSingleSelect";
import { inputNumberStyle, regularTextStyle } from "../styles/SurveyStyles";
import { StepActionType, getDeviceOrientationValue } from "../types/enums";
import { CustomPreviewComponent } from "./CustomPreviewComponent";
import MDInput from "components/MDInput";
import {
  SurveyStep,
  TEXTFIELD_CHANGE_DELAY,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import { useCallback, useEffect, useState } from "react";
import { updateSurveyTemplateStep } from "../services/SurveyServices";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  hasConditionsOnSurvey,
  hasConditionsOnSurveyStep,
  isGroupOrphaned,
} from "../utils/helpers";
import {
  SurveyGroupWithStep,
  VueSurveyData,
} from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";

interface Props {
  onHeaderClick: (type: StepActionType) => void;
  stepNumber: string;
  hasBeenGrouped: boolean;
  hasConditionsApplied: boolean;
  showAddConditionButton: boolean;
  step: SurveyStep;
  groupName: string;
  onSuccess: (step: SurveyStep) => void;
  groupIndex: number;
  surveyData: VueSurveyData;
  onDeleteGroup: (id: string) => void;
}

export const DraftModePhotoComponent = ({
  surveyData,
  onHeaderClick,
  stepNumber,
  hasBeenGrouped,
  groupName,
  groupIndex,
  hasConditionsApplied,
  showAddConditionButton,
  step,
  onDeleteGroup,
  onSuccess,
}: Props) => {
  const [photoStep, setPhotoStep] = useState<SurveyStep>(step);
  const [delayTypeAction, setDelayTypeAction] = useState<boolean>(false);
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const { id, description, photoCountMin, photoCountMax, deviceOrientation } =
    photoStep;
  const [error, setError] = useState({
    prompt: "",
    minValue: "",
    maxValue: "",
  });

  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhotoStep({ ...photoStep, [event.target.name]: value });
    setError({ ...error, prompt: !value ? "Prompt cannot be empty" : "" });
    setDelayTypeAction(value.isNotEmpty());
  };

  const handleMinValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const min = parseInt(value);
    setPhotoStep({ ...photoStep, [event.target.name]: min });
    const isValid =
      !isNaN(min) && !isNaN(photoCountMax) && min >= 0 && min <= photoCountMax;
    setError({ ...error, minValue: !isValid ? "Invalid min value" : "" });
    setDelayTypeAction(isValid);
  };

  const handleMaxValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const max = parseInt(value);
    const isValid =
      !isNaN(photoCountMin) && !isNaN(max) && max >= 0 && photoCountMin <= max;
    setPhotoStep({ ...photoStep, [event.target.name]: max });
    setError({ ...error, maxValue: !isValid ? "Invalid max value" : "" });
    setDelayTypeAction(isValid);
  };

  const handleUpdateSurveyStep = useCallback(async () => {
    if (id.isEmpty()) {
      return;
    }
    setShowLoader(true);
    // Updating the request body
    const orientation = getDeviceOrientationValue(photoStep.deviceOrientation);
    const requestBody = { ...photoStep, deviceOrientation: orientation };
    const response = await updateSurveyTemplateStep(id, requestBody);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as SurveyStep;
      onSuccess(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to update Photo step.",
        isError: true,
      });
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoStep, id]);

  const updateGroupIdForStep = () => {
    // Updating the groupId for this step
    setPhotoStep({ ...photoStep, groupId: "" });
    setDelayTypeAction(true);
  };

  const deleteGroup = () => {
    const hasSurveyConditions = hasConditionsOnSurvey(surveyData, step);
    const hasStepConditions = hasConditionsOnSurveyStep(surveyData, step);
    // TODO - Allow/deny based on specific scenaario, for now deny all
    // when ungrouping, stepcondition could contain condition from step in group, if so deny
    // always deny if parent step of condition, as only other steps of same group could be linked
    if (hasSurveyConditions || hasStepConditions) {
      setSnackBar({
        title: "Attention!",
        message: "This step is linked to another, cannot ungroup step.",
        isError: true,
      });
      return;
    }
    const islastStepInTheGroup = isGroupOrphaned(step, surveyData.survey.steps);
    const groupID = islastStepInTheGroup ? photoStep.groupId : "";
    if (groupID) {
      // Removing the group if the group is orphaned
      onDeleteGroup(groupID);
    }
    updateGroupIdForStep();
  };

  useEffect(() => {
    if (delayTypeAction) {
      const delaySearchAction = setTimeout(() => {
        handleUpdateSurveyStep();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
  }, [handleUpdateSurveyStep, delayTypeAction]);

  return (
    <MDBox p="20px">
      <HeaderStepSection
        stepNumber={stepNumber}
        stepType={"Photo"}
        groupName={groupName}
        isGrouped={hasBeenGrouped}
        onButtonClick={(type: StepActionType) => {
          type === StepActionType.ungroup ? deleteGroup() : onHeaderClick(type);
        }}
        hasConditionApplied={hasConditionsApplied}
        showAddConditionButton={showAddConditionButton}
        groupIndex={groupIndex}
      />
      <MDBox
        border="1px solid #C7CCD0"
        p="16px 16px 20px 16px"
        borderRadius="8px"
      >
        <MDBox mb={1}>
          <MDInput
            fullWidth
            name="description"
            error={error.prompt}
            value={description}
            placeholder="Enter Prompt"
            multiline
            rows={2}
            onChange={handlePromptChange}
          />
        </MDBox>
        <MDBox display="flex" mb={1}>
          <MDBox>
            <MDTypography sx={regularTextStyle}>Minimum Photos</MDTypography>
            <MDInput
              name="photoCountMin"
              type="number"
              placeholder="Min"
              error={error.minValue}
              value={photoCountMin}
              sx={{ ...searchBarStyle, ...inputNumberStyle }}
              onChange={handleMinValueChange}
            />
          </MDBox>
          <MDBox ml={1.2}>
            <MDTypography sx={regularTextStyle}>
              Maximum Photos (0 for unlimited)
            </MDTypography>
            <MDInput
              name="photoCountMax"
              type="number"
              placeholder="Max"
              error={error.maxValue}
              value={photoCountMax}
              sx={{ ...searchBarStyle, ...inputNumberStyle }}
              onChange={handleMaxValueChange}
            />
          </MDBox>
          <MDBox
            ml={1.2}
            width={"120px"}
            sx={{
              ".MuiInputBase-formControl": {
                marginTop: "0 !important",
              },
              ".MuiFormControl-root": {
                width: "100%",
              },
            }}
          >
            <MDTypography sx={regularTextStyle}>Photo Orientation</MDTypography>
            <CustomSingleSelect
              optionsList={MEDIA_ORIENTATION_TYPES}
              onChange={(option: string) => {
                setDelayTypeAction(true);
                setPhotoStep({ ...photoStep, deviceOrientation: option });
              }}
              value={deviceOrientation as string}
            />
          </MDBox>
        </MDBox>
        <MDBox mb={1}>
          <CustomPreviewComponent step={photoStep} />
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};
