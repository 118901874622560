import { CustomMapComponent } from "pages/dashboard/home/vues/vue_detail/components/googlemap/CustomMapComponent";
import { useCallback, useEffect, useState } from "react";
import { getNearbyUsers } from "../services/VuerServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { NearbyUser } from "../interface/interface";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { OverlayViewF, OverlayView } from "@react-google-maps/api";
import { CustomMapMarker } from "pages/dashboard/home/vues/vue_detail/components/googlemap/CustomMapMarker";
import MDBox from "components/MDBox";
import { NEARBY_USER_RANGE } from "../utils/constants";
import { LatLng } from "@ivueit/vue-engine";

export const NearbyUserMap = ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) => {
  const vuerCoords: LatLng = {
    latitude: latitude,
    longitude: longitude,
  };
  const [nearbyUsersList, setNearbyUsersList] = useState<NearbyUser[]>([]);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);

  const fetchNearbyUsers = useCallback(async () => {
    const parameters = `latlng.latitude=${latitude}&latlng.longitude=${longitude}&page.pageNumber=1&page.pageSize=100&range=${NEARBY_USER_RANGE}`;
    const response = await getNearbyUsers(parameters);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.users as NearbyUser[];
      setNearbyUsersList(data);
    } else {
      setNearbyUsersList([]);
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    fetchNearbyUsers();
  }, [fetchNearbyUsers]);

  const generateMarkers = () => {
    return nearbyUsersList.map((user, index) => {
      const {
        location: { latitude, longitude },
      } = user;
      const hasDefinedLocation = latitude && longitude;
      return hasDefinedLocation ? (
        <OverlayViewF
          key={index}
          position={{
            lat: latitude,
            lng: longitude,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <CustomMapMarker
            lat={latitude}
            lng={longitude}
            color="#4CAF50"
            textColor="#ffffff"
            content={(index + 1).toString()}
            onHover={() => {}}
          />
        </OverlayViewF>
      ) : (
        <></>
      );
    });
  };

  return (
    <MDBox
      height="500px"
      mt="10px"
      mr="15px"
      sx={{
        border: "1px solid #344767",
        borderRadius: "5px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <CustomMapComponent
        markerComponents={generateMarkers()}
        onlyUpdateViewPortOnDrag
        handleMapDrag={() => {}}
        coordinates={vuerCoords}
        zoom={10}
      />
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};
