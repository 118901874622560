// Constant range used for the nearby users API
export const NEARBY_USER_RANGE = 100000;
export const TYPE_FOR_CREATING_TRANSACTION = "AMMENDMENT";

export enum UserType {
  standard = "STANDARD",
  preferred = "PREFERRED",
  professional = "PROFESSIONAL",
}

export enum BackgroundStatus {
  unforced = "UNFORCED",
  forced_visible = "FORCED_VISIBLE",
  forced_not_visible = "FORCED_NOT_VISIBLE",
  proceed = "PROCEED",
  final_adverse = "FINAL_ADVERSE",
  clear = "CLEAR",
}

export enum TransactionType {
  earning = "EARNING",
  amendment = "AMMENDMENT",
}
