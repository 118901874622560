import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  tableRowStyle,
  tableCellStyle,
} from "pages/surveys/styles/SurveyStyles";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { useState } from "react";
import ConfirmRemoveDialogContent from "./ConfirmRemoveDialogContent";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { removeOrgUserFromCompanyGroup } from "../services/services";
import {
  CustomIndicator,
  GENERIC_ERROR_MESSAGE,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

interface Props {
  user: UserInfo;
  companyGroupId: string;
  onRefresh: () => void;
}

const UsersListItem = ({ user, companyGroupId, onRefresh }: Props) => {
  const [loader, setLoader] = useState(false);
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] =
    useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const perfromUserDeletion = async () => {
    setLoader(true);
    const { id } = user;
    const response = await removeOrgUserFromCompanyGroup(id, companyGroupId);
    setLoader(false);
    if (response.status === WebServiceStatus.success) {
      onRefresh();
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setOpenConfirmRemoveDialog(false);
  };
  const getConfirmReadOnlyDialog = () => {
    return (
      <CustomDialogBox
        title={"Confirm"}
        width="540px"
        openDialog={openConfirmRemoveDialog}
      >
        <ConfirmRemoveDialogContent
          closeDialog={(isActionButton) => {
            if (isActionButton) {
              perfromUserDeletion();
              return;
            }
            setOpenConfirmRemoveDialog(false);
          }}
          message={`Are you sure to remove the user ${user.email}?`}
          actionTitle={"REMOVE"}
        />
      </CustomDialogBox>
    );
  };

  return (
    <>
      {loader && <CustomIndicator />}
      {openConfirmRemoveDialog && getConfirmReadOnlyDialog()}
      <MDBox sx={tableRowStyle}>
        <MDBox sx={tableCellStyle}>
          <MDTypography sx={{ color: "#7b809a" }}>{user.email}</MDTypography>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "140px",
          }}
        >
          <MDTypography sx={{ color: "#7b809a" }}>
            {user.createdAt.formatUTCNanoSecondsToString("MM/DD/YYYY")}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "220px",
          }}
        >
          <MDBox ml={1}>
            <MDTypography sx={{ color: "#7b809a" }}>
              {user.createdAt.formatUTCNanoSecondsToString("hh:mm:ss A", true)}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "100px",
          }}
        >
          <MDTypography color="success">
            {user.valid ? "ACTIVE" : "INACTIVE"}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            ...tableCellStyle,
            flexGrow: "0",
            flexShrink: "0",
            flexBasis: "120px",
          }}
        >
          <MDButton
            variant="outlined"
            color="error"
            sx={{ fontSize: "14px", fontWeight: "bold", ml: 1 }}
            onClick={() => {
              setOpenConfirmRemoveDialog(true);
            }}
          >
            REMOVE
          </MDButton>
        </MDBox>
        <CustomSnackbar
          snackbarContent={snackBarContent}
          onClose={() => {
            setSnackBarContent(null);
          }}
        />
      </MDBox>
    </>
  );
};

export default UsersListItem;
