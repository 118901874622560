import { Grid } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import MDTypography from "components/MDTypography";

interface Props {
  title: string;
  totalItems: number;
  sx?: SxProps;
}

const DataGridBottomBar = ({ totalItems, title, sx }: Props) => {
  return (
    <Grid
      container
      display="flex"
      px={4}
      pb={1.1}
      pt={0.6}
      sx={{
        borderTop: "0.5px solid #A8B8D8",
        ...sx
      }}
    >
      <Grid item xs={2} display="flex" alignItems="center" pl={2}>
        <MDTypography variant="h5" fontWeight="bold">
          SUMMARY
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="button" color="textLight" fontWeight="regular">
          {title}
        </MDTypography>
        <MDTypography variant="h5">
          {totalItems > 0 ? totalItems : "-"}
        </MDTypography>
      </Grid>
    </Grid>
  );
};

export default DataGridBottomBar;
