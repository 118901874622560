import { DialogContent, DialogActions, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import TagsQuestionContainer, {
  TagsQuestionContainerType,
} from "./TagsQuestionContainer";
import MDBox from "components/MDBox";
import TagsQuestionContent from "./TagsQuestionContent";
import TagsAnswerContent from "./TagsAnswerContent";
import EditTagsTabs, { EditTagsTabType } from "./EditTagsTabs";
import { useCallback, useEffect, useState } from "react";
import {
  AnswerTag,
  QuestionTagsWithAnswerTags,
  SurveyStepWithTags,
  SurveyTags,
} from "../types/enums";
import {
  SurveyStepType,
  getSurveyStepType,
} from "pages/dashboard/home/vues/vue_detail/types/types";
import {
  PlaceholderComponent,
  SurveyStep,
  VueSurvey,
} from "@ivueit/vue-engine";
import { getStepAndAnswerTagsForSurveyTemplateId } from "../services/SurveyServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { mdiPlus } from "@mdi/js";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import Icon from "@mdi/react";

interface Props {
  survey: VueSurvey;
  closeDialog: () => void;
}

const EditTagsDialogContent = ({ survey, closeDialog }: Props) => {
  const [stepsWithTags, setStepsWithTags] = useState<SurveyStepWithTags[]>([]);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isQuestionTagsAvailable, setIsQuestionTagsAvailable] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<EditTagsTabType>(
    EditTagsTabType.questionTags
  );

  const generateAnswersBasedOnType = (
    tagWithSameStepID: QuestionTagsWithAnswerTags,
    possibleOptions: string[],
    type: SurveyStepType
  ) => {
    const answerTagsForDefaultOptions: AnswerTag[] = [];
    if (tagWithSameStepID && tagWithSameStepID.answerTags.length > 0) {
      const { answerTags, questionTag } = tagWithSameStepID;

      /// For "Text" type, we will not have options & so we don't have to create new answer object
      if (type === SurveyStepType.text) {
        return answerTags;
      }

      /// Generating answerObject for each of the answer tags from the backend
      answerTags.map((answerTag) => {
        const { id, associatedQuestionTagId, possibleAnswer, meta } = answerTag;
        const answerObj = {
          id: id,
          associatedQuestionTagId: associatedQuestionTagId,
          possibleAnswer: possibleAnswer,
          meta: meta,
          originalOption: true,
        };
        return answerTagsForDefaultOptions.push(answerObj);
      });
      /// Removing the options (which is there already in the response) from the possible option list
      answerTags.forEach((answer) => {
        for (let index = possibleOptions.length - 1; index >= 0; index--) {
          const option = possibleOptions[index];
          const hasSameOption =
            answer.possibleAnswer.toLowerCase() === option.toLowerCase();
          if (hasSameOption) {
            possibleOptions.splice(index, 1);
          }
        }
      });
      /// Generating answerObject for each of the remaining options
      possibleOptions.map((option) => {
        const answerObj = {
          id: "",
          associatedQuestionTagId: questionTag.id,
          possibleAnswer: option,
          meta: "",
          originalOption: true,
        };
        return answerTagsForDefaultOptions.push(answerObj);
      });
    } else {
      /// If there are no answer tags already available, we are generating answer object for the default options
      possibleOptions.map((option) => {
        const answerObj: AnswerTag = {
          id: "",
          associatedQuestionTagId:
            (tagWithSameStepID && tagWithSameStepID.questionTag.id) ?? "",
          possibleAnswer: option,
          meta: "",
          originalOption: true,
        };
        return answerTagsForDefaultOptions.push(answerObj);
      });
    }
    return answerTagsForDefaultOptions;
  };

  const fetchQuestionAnswerTags = useCallback(async () => {
    setShowLoader(true);
    const response = await getStepAndAnswerTagsForSurveyTemplateId(survey.id);
    if (response.status === WebServiceStatus.success) {
      const responseData = response.data as SurveyTags;
      const { surveyTemplateId, questionTagsWithAnswerTags } = responseData;
      const hasQuestionTags = questionTagsWithAnswerTags.length > 0;
      setIsQuestionTagsAvailable(hasQuestionTags);
      let surveyTagsWithSteps: SurveyStepWithTags[] = [];
      if (hasQuestionTags) {
        // Looping over the steps & populating the question & answer tags for each step
        const stepsWithTags = survey.steps.map((step) => {
          const possibleOptions = generatePossibleOptionsBasedOnType(step);
          const type = getSurveyStepType(step.stepType);
          const tagWithSameStepID = questionTagsWithAnswerTags.filter(
            (tag) => tag.questionTag.associatedSurveyStepId === step.id
          )[0];
          /// Generating answer tags based on type
          const updatedAnswerTags: AnswerTag[] = generateAnswersBasedOnType(
            tagWithSameStepID,
            possibleOptions,
            type
          );
          const updatedQuestionWithAnswerTag: QuestionTagsWithAnswerTags = {
            questionTag: tagWithSameStepID
              ? tagWithSameStepID.questionTag
              : null,
            answerTags: updatedAnswerTags,
            answerTagsAreImmutable: tagWithSameStepID
              ? tagWithSameStepID.answerTagsAreImmutable
              : null,
          };
          /// Creating new object which have both step & tag details
          const newObj: SurveyStepWithTags = {
            ...step,
            questionTagsWithAnswerTags: updatedQuestionWithAnswerTag,
            surveyTemplateId: surveyTemplateId,
            possibleOptions: possibleOptions,
          };
          return newObj;
        });
        surveyTagsWithSteps = stepsWithTags;
      } else {
        const stepsWithoutTags: SurveyStepWithTags[] = survey.steps.map(
          (step) => {
            return {
              ...step,
              surveyTemplateId: survey.id,
            };
          }
        );
        surveyTagsWithSteps = stepsWithoutTags;
      }
      setStepsWithTags(surveyTagsWithSteps);
    } else {
      closeDialog();
      setSnackBar({
        title: "Attention!",
        message: "Unable to fetch tags.",
        isError: true,
      });
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey.id, survey.steps]);

  useEffect(() => {
    fetchQuestionAnswerTags();
  }, [fetchQuestionAnswerTags]);

  /// Show tags only for these step types
  const showTags = (stepType: SurveyStepType) => {
    return (
      stepType === SurveyStepType.text ||
      stepType === SurveyStepType.select ||
      stepType === SurveyStepType.multiSelect ||
      stepType === SurveyStepType.slider ||
      stepType === SurveyStepType.bool ||
      stepType === SurveyStepType.photo ||
      stepType === SurveyStepType.measurement ||
      stepType === SurveyStepType.video
    );
  };

  // Show answer tags only for these types
  const showAnswerTags = (stepType: SurveyStepType) => {
    return (
      stepType === SurveyStepType.text ||
      stepType === SurveyStepType.bool ||
      stepType === SurveyStepType.select ||
      stepType === SurveyStepType.multiSelect ||
      stepType === SurveyStepType.slider
    );
  };

  /// This returns the default set of possible optiosn for 4 types --> Select, Multi-Select, Bool, Slider
  const generatePossibleOptionsBasedOnType = (step: SurveyStep): string[] => {
    const { stepType, sliderMin, sliderMax, options } = step;
    let possibleOptions: string[] = [];
    switch (stepType) {
      case SurveyStepType.select:
      case SurveyStepType.multiSelect:
        possibleOptions = options
          ? (JSON.parse(options).options as string[])
          : [];
        break;
      case SurveyStepType.slider:
        possibleOptions = Array.from(
          { length: sliderMax - sliderMin + 1 },
          (count, index) => {
            return sliderMin + index;
          }
        ).map((count) => count.toString());
        break;
      case SurveyStepType.bool:
        possibleOptions = ["Yes", "No"];
        break;
      default:
        break;
    }
    return possibleOptions;
  };

  const getTagAnswerContent = (
    stepWithTag: SurveyStepWithTags,
    stepIndex: number
  ) => {
    const { questionTag, answerTags, answerTagsAreImmutable } =
      stepWithTag.questionTagsWithAnswerTags;
    const { stepType } = stepWithTag;
    const type = getSurveyStepType(stepType);
    const shouldShowAnswerTags = showAnswerTags(type);
    if (questionTag && questionTag.meta) {
      let elements: JSX.Element[] = [];
      return answerTags.map((answerTag, index) => {
        const { associatedQuestionTagId, id, possibleAnswer, meta } = answerTag;
        const possibleOptions = generatePossibleOptionsBasedOnType(
          survey.steps[stepIndex]
        );
        const originalOption =
          type === SurveyStepType.bool ||
          possibleOptions.includes(possibleAnswer);
        // If the answerTags are immutable, show placeholder
        if (answerTagsAreImmutable) {
          elements = [
            <>
              Question Tag is in use on a Published Survey, Answer Tag Edits
              Disallowed
            </>,
          ];
        } else if (shouldShowAnswerTags && !answerTagsAreImmutable) {
          // Generating answer tags only if the answerTags are immutable
          elements = [
            <TagsAnswerContent
              key={`${id}${possibleAnswer}`}
              count={index + 1}
              steps={stepsWithTags}
              possibleAnswer={possibleAnswer}
              meta={meta}
              questionID={associatedQuestionTagId}
              answerID={id}
              originalOption={originalOption}
              onSuccess={() => {
                fetchQuestionAnswerTags();
              }}
              stepIndex={stepIndex}
              answerIndex={index}
            />,
          ];
        }
        return elements;
      });
    } else if (!questionTag) {
      // If there are no question tags, we will not have answer tags
      return <>Question Tag Required To Add Answer Tags</>;
    } else {
      return <>No Answer Tags Applied</>;
    }
  };

  const handleAddAnswerTagButtonClick = (stepWithTag: SurveyStepWithTags) => {
    const updatedSteps = stepsWithTags.map((step) => {
      const { questionTagsWithAnswerTags } = step;
      const { questionTag, answerTags, answerTagsAreImmutable } =
        questionTagsWithAnswerTags;
      return step.id === stepWithTag.id
        ? {
            ...step,
            questionTagsWithAnswerTags: {
              questionTag: questionTag,
              answerTags: [
                ...answerTags,
                {
                  id: "",
                  associatedQuestionTagId: questionTag.id,
                  possibleAnswer: "",
                  meta: "",
                  originalOption: false,
                },
              ],
              answerTagsAreImmutable: answerTagsAreImmutable,
            },
          }
        : step;
    });
    setStepsWithTags(updatedSteps);
  };

  // Answer tags will be populated only for the following types --> bool, select, multiSelect, slider, text
  const getAnswerTags = () => {
    return (
      <Grid container spacing={2} pt={2}>
        {stepsWithTags &&
          stepsWithTags.map((stepWithTag, indexVal) => {
            const { index, description, stepType } = stepWithTag;
            const { questionTag, answerTagsAreImmutable } =
              stepWithTag.questionTagsWithAnswerTags;
            const type = getSurveyStepType(stepType);
            const shouldShowTags = showTags(type);
            const shouldShowAnswerTags = showAnswerTags(type);
            const showAddAnswerTagButton =
              type !== SurveyStepType.bool &&
              shouldShowAnswerTags &&
              questionTag &&
              questionTag.meta &&
              !answerTagsAreImmutable;
            return (
              shouldShowTags && (
                <Grid item xs={6} key={indexVal}>
                  <TagsQuestionContainer
                    count={parseInt(index) + 1}
                    question={description}
                    type={TagsQuestionContainerType.answer}
                  >
                    {getTagAnswerContent(stepWithTag, indexVal)}
                    {showAddAnswerTagButton && (
                      <MDBox my={2.2}>
                        <StyledButtonWithIcon
                          onClick={() => {
                            handleAddAnswerTagButtonClick(stepWithTag);
                          }}
                        >
                          <Icon
                            path={mdiPlus}
                            size={0.85}
                            style={{ marginRight: "5px" }}
                          />
                          ANSWER TAG
                        </StyledButtonWithIcon>
                      </MDBox>
                    )}
                  </TagsQuestionContainer>
                </Grid>
              )
            );
          })}
      </Grid>
    );
  };

  const getQuestionTags = () => {
    return (
      <Grid container spacing={2} pt={2}>
        {stepsWithTags.map((stepWithTag, indexVal) => {
          const { index, description, stepType, questionTagsWithAnswerTags } =
            stepWithTag;
          const { questionTag } = questionTagsWithAnswerTags ?? {};
          const type = getSurveyStepType(stepType);
          const shouldShowTags = showTags(type);
          const meta = questionTag ? questionTag.meta : "";
          const id = questionTag && questionTag.id;
          return (
            shouldShowTags && (
              <Grid item xs={6} key={indexVal}>
                <TagsQuestionContainer
                  count={parseInt(index) + 1}
                  question={description}
                  type={TagsQuestionContainerType.question}
                >
                  <TagsQuestionContent
                    value={meta}
                    stepID={stepWithTag.id}
                    questionID={id}
                    onSuccess={() => {
                      fetchQuestionAnswerTags();
                    }}
                  />
                </TagsQuestionContainer>
              </Grid>
            )
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <MDBox display="flex" justifyContent="end" mt={2} mr={2.8}>
        <MDBox>
          <EditTagsTabs
            onTabChange={(tab: EditTagsTabType) => {
              setActiveTab(tab);
            }}
            disableAnswerTab={!isQuestionTagsAvailable}
          />
        </MDBox>
      </MDBox>
      <DialogContent sx={{ minHeight: "60vh" }}>
        {survey.steps.length > 0 ? (
          <MDBox>
            {activeTab === EditTagsTabType.questionTags
              ? getQuestionTags()
              : getAnswerTags()}
          </MDBox>
        ) : (
          <PlaceholderComponent
            label={"No Steps available to configure tags"}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #A8B8D8" }}>
        <MDButton
          variant="outlined"
          color="dark"
          size="medium"
          onClick={closeDialog}
          sx={{
            borderColor: "#C7CCD0",
            padding: "11px 16px",
            fontSize: "14px",
            fontWeight: "bold",
            "&:hover": { borderColor: "#C7CCD0" },
          }}
        >
          CLOSE
        </MDButton>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default EditTagsDialogContent;
