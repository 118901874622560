import { mdiFilterOff, mdiDelete } from "@mdi/js";
import { Divider, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";
import { useEffect, useState } from "react";
import { TEXTFIELD_CHANGE_DELAY } from "./../../../../../../constants";
import { inputNumberStyle } from "pages/surveys/styles/SurveyStyles";

const FilterByRangeContent: React.FC<{
  shouldShowRemoveColumnButton: boolean;
  previousMinRange: string;
  previousMaxRange: string;
  columnName: string;
  closePopper: (event: Event | React.SyntheticEvent<Element, Event>) => void;
  removeColumn: () => void;
  applyRangeFilter: (minRange: string, maxRange: string) => void;
  allowNegative?: boolean;
}> = (props) => {
  /// This holds the minimum range
  const [minRange, setMinRange] = useState<string>(props.previousMinRange);
  /// This holds the maximum range
  const [maxRange, setMaxRange] = useState<string>(props.previousMaxRange);
  /// State to track the export confirmation dialog
  const [openConfirmationDialog, setOpenConfirmation] =
    useState<boolean>(false);

  // Handling the text change
  useEffect(() => {
    const triggerTextChange = () => {
      props.applyRangeFilter(minRange, maxRange);
    };
    /// The method delays the api call for 1 second
    /// It helps us to avoid frequent api calls & state updates, on text change
    const delayFilterInvokation = setTimeout(() => {
      triggerTextChange();
    }, TEXTFIELD_CHANGE_DELAY);

    return () => clearTimeout(delayFilterInvokation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minRange, maxRange]);

  /// Opens confirmation dialog
  const openConfirmation = () => {
    setOpenConfirmation((prevState) => !prevState);
  };

  /// Closes confirmation dialog
  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  /// Handles the onClick of "YES, REMOVE" button
  const removeColumn = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    closeConfirmation();
    props.removeColumn();
    props.closePopper(event);
  };

  /// Handles the onClick of "Cancel" button in the dialog
  const handleCancelClick = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    closeConfirmation();
    props.closePopper(event);
  };

  // Generates actions for confirmation box
  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={handleCancelClick}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={removeColumn}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        Yes, Remove
      </MDButton>,
    ];
  };

  /// Gets the confirmation dialog
  const getConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title={`Remove ${props.columnName}`}
        width="454px"
        height="200px"
        openDialog={openConfirmationDialog}
      >
        <ModalPopUp
          content={`Are you sure you want to remove the ${props.columnName} column from the table?`}
          closeDialog={closeConfirmation}
          actions={confirmationDialogActions()}
        />
      </CustomDialogBox>
    );
  };

  /// Handles the onClick of "Remove Column" button in popper
  const handleRemoveColumnClick = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    openConfirmation();
  };

  const handleOnchange = (value: string, cb: Function) => {
    if (!props.allowNegative && parseInt(value) < 0) {
      cb("0");
    } else {
      cb(value);
    }
  };

  return (
    <>
      {openConfirmationDialog && getConfirmationDialog()}
      {!openConfirmationDialog && (
        <MDBox>
          <MDBox px={2.5} pt={2}>
            <MDTypography fontWeight="bold" sx={{ fontSize: "14px" }}>
              Filter By:
            </MDTypography>
            <MDBox pt={2} pb={2}>
              <TextField
                fullWidth
                type="number"
                placeholder={`Min ${props.columnName}`}
                value={minRange}
                InputLabelProps={{ shrink: true }}
                onChange={(event) =>
                  handleOnchange(event.target.value, setMinRange)
                }
                sx={{ py: 1, ...inputNumberStyle }}
              />
            </MDBox>
            <MDBox pb={0}>
              <TextField
                fullWidth
                type="number"
                placeholder={`Max ${props.columnName}`}
                value={maxRange}
                InputLabelProps={{ shrink: true }}
                onChange={(event) =>
                  handleOnchange(event.target.value, setMaxRange)
                }
                sx={{ py: 1, ...inputNumberStyle }}
              />
            </MDBox>
          </MDBox>
          <Divider
            sx={{
              backgroundColor: "#A8B8D8",
              height: "1x",
              opacity: 0.5,
              backgroundImage: "none !important",
            }}
          />
          <MDBox px={2} pb={2}>
            <MDBox>
              <ButtonWithIcon
                iconPath={mdiFilterOff}
                disabled={minRange.isEmpty() && maxRange.isEmpty()}
                fullWidth
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#1A73E7",
                  "&:disabled": { color: "#c7ccd0", borderColor: "#c7ccd0" },
                  "&:hover": { color: "#1A73E7", borderColor: "#c7ccd0" },
                }}
                onClick={(
                  event: Event | React.SyntheticEvent<Element, Event>
                ) => {
                  props.applyRangeFilter("", "");
                  props.closePopper(event);
                }}
              >
                CLEAR FILTERS
              </ButtonWithIcon>
            </MDBox>
            {props.shouldShowRemoveColumnButton && (
              <MDBox pt={1}>
                <ButtonWithIcon
                  iconPath={mdiDelete}
                  fullWidth
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#AE1709",
                    "&:hover": { color: "#AE1709", borderColor: "#c7ccd0" },
                  }}
                  onClick={handleRemoveColumnClick}
                >
                  REMOVE COLUMN
                </ButtonWithIcon>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      )}
    </>
  );
};

export default FilterByRangeContent;
