import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { CustomIndicator } from "pages/components/CustomIndicator";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  dialogText,
  regularTextStyle,
} from "pages/surveys/styles/SurveyStyles";
import { useEffect, useState } from "react";
import {
  createManualTransaction,
  updateUserByID,
} from "../services/VuerServices";
import { Vuer } from "../interface/interface";
import { WebServiceStatus } from "utils/services/AppUrls";
import { TEXTFIELD_CHANGE_DELAY } from "../../../constants";
import { TYPE_FOR_CREATING_TRANSACTION } from "../utils/constants";

interface Props {
  vuer: Vuer;
  closeDialog: () => void;
  onSuccess: () => void;
  onSucessAdjustment: () => void;
}

const AdjustDialogContent = (props: Props) => {
  const { failedVues, id, vuesFailedForgiven } = props.vuer;
  const [newIgnoredCount, setNewIgnoredCount] =
    useState<string>(vuesFailedForgiven);
  const [textChanged, setTextChanged] = useState<boolean>(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState<string>("");
  const [amount, setAmount] = useState<string>("0");
  const [description, setDescription] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  /// Description's character limit should be 14
  const isInValidDescription = description && description.length >= 14;

  const updateVuerDetail = async () => {
    const newCount = parseInt(newIgnoredCount);
    const oldCount = parseInt(vuesFailedForgiven);
    const failedVueCount = parseInt(failedVues);
    if (newCount >= 0 && newCount !== oldCount) {
      const value = newCount > failedVueCount ? failedVues : newIgnoredCount;
      const param = {
        VuesFailedForgiven: {
          value: value,
        },
      };
      setShowLoader(true);
      const response = await updateUserByID(id, param);
      if (response.status === WebServiceStatus.success) {
        const data = response.data as Vuer;
        setNewIgnoredCount(data.vuesFailedForgiven);
        props.onSuccess();
      } else {
        setSnackBar({
          title: "Attention!",
          message: `Unable to adjust Vue failures: ${response.error}`,
          isError: true,
        });
      }
      setShowLoader(false);
    }
  };

  const createAdjustment = async () => {
    const type = TYPE_FOR_CREATING_TRANSACTION;
    const params = {
      amount: amount,
      notes: notes,
      shortDescription: description,
      typeOf: type,
    };
    setError("");
    setShowLoader(true);
    const response = await createManualTransaction(id, params);
    if (response.status === WebServiceStatus.success) {
      props.onSucessAdjustment();
      setSnackBar({
        title: "Success!",
        message: "Transaction created successfully",
        isError: false,
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to create new transaction: ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (textChanged) {
      /// The method delays the api call for 0.7 ms
      /// It helps us to avoid frequent api calls & state updates, on text change
      const delayAPIInvocation = setTimeout(() => {
        updateVuerDetail();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delayAPIInvocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIgnoredCount]);

  return (
    <>
      {showLoader && <CustomIndicator />}
      <DialogContent>
        <MDBox mt={3} mb={3}>
          <MDTypography
            variant="body"
            sx={{ ...dialogText, fontWeight: "500", marginBottom: "10px" }}
          >
            Failed Vues : {failedVues}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" mb={1.4}>
          <MDBox flex={1}>
            <MDTypography sx={regularTextStyle}>
              Failed Vues Ignored
            </MDTypography>
            <MDInput
              placeholder="Enter ignored failed Vue count"
              value={newIgnoredCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const enteredValue = event.target.value;
                setNewIgnoredCount(enteredValue);
                setTextChanged(true);
              }}
              fullWidth
            />
          </MDBox>
          <MDBox flex={1} ml={1.2}>
            <MDTypography sx={regularTextStyle}>Adjust</MDTypography>
            <MDInput
              placeholder="Enter adjust amount"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAmount(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
        </MDBox>
        <MDBox flex={1} mb={1.4}>
          <MDTypography sx={regularTextStyle}>Short Description</MDTypography>
          <MDInput
            error={error.isNotEmpty()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.target.value);
              setError(
                isInValidDescription
                  ? "Description cannot exceed 14 characters"
                  : ""
              );
            }}
            placeholder="Enter short description"
            fullWidth
            multiline
            rows="2"
          />
          {error && (
            <MDTypography
              sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
            >
              {error}
            </MDTypography>
          )}
        </MDBox>
        <MDBox flex={1}>
          <MDTypography sx={regularTextStyle}>Notes</MDTypography>
          <MDInput
            placeholder="Enter notes"
            fullWidth
            multiline
            rows="3"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNotes(event.target.value);
            }}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={createAdjustment}
            disabled={!amount.trim() || !notes.trim() || isInValidDescription}
          >
            ADJUST
          </MDButton>
        </>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default AdjustDialogContent;
