import MDBox from "components/MDBox";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import UsersTabs, { UsersTabsType } from "./components/UsersTabs";
import { useState } from "react";
import CompaniesListTabContent from "./components/CompaniesListTabContent";
import UsersListTabContent from "./components/UsersListTabContent";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyGroup } from "pages/profile/utils/ProfileInterfaces";
import CustomDialogBox from "pages/components/CustomDialogBox";
import ConfirmRemoveDialogContent from "./components/ConfirmRemoveDialogContent";
import { deleteCompanyGroup } from "./services/services";
import {
  CustomIndicator,
  GENERIC_ERROR_MESSAGE,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { RoutePath } from "./../../constants";

const UserDetails = () => {
  const location = useLocation();
  const companyGroup = location.state.item as CompanyGroup;
  const isActive = companyGroup.valid;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loader, setLoader] = useState(false);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [activeTab, setActiveTab] = useState<UsersTabsType>(
    UsersTabsType.activeCompanies
  );
  const navigate = useNavigate();

  const getTabContents = () => {
    switch (activeTab) {
      case UsersTabsType.activeCompanies:
        return getCompaniesListTab();
      case UsersTabsType.activeGroups:
        return getUsersListTab();
    }
  };

  const getCompaniesListTab = () => {
    return <CompaniesListTabContent companyGroupId={companyGroup.id} />;
  };

  const getUsersListTab = () => {
    return <UsersListTabContent companyGroupId={companyGroup.id} />;
  };

  const performCompanyGroupDeletion = async () => {
    setLoader(true);
    const { id } = companyGroup;
    const response = await deleteCompanyGroup(id);
    setLoader(false);
    if (response.status === WebServiceStatus.success) {
      navigate(RoutePath.users, { replace: true });
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
  };

  const getConfirmDialog = () => {
    return (
      <CustomDialogBox
        title={"Confirm Deletion"}
        width="540px"
        openDialog={showConfirmation}
      >
        <ConfirmRemoveDialogContent
          closeDialog={async (isActionButton) => {
            if (isActionButton) {
              await performCompanyGroupDeletion();
              return;
            }
            setShowConfirmation(false);
          }}
          message={`Are you sure to remove the company ${companyGroup.name}?`}
          actionTitle={"Delete Company Group"}
        />
      </CustomDialogBox>
    );
  };

  const onDeleteCompanyClick = () => {
    setShowConfirmation(true);
  };

  return (
    <DashboardContentLayout>
      {showConfirmation && getConfirmDialog()}
      {loader && <CustomIndicator />}
      <MDBox p="16px">
        <Grid container spacing={3} mb={2}>
          <Grid item flexGrow="1">
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDBox mb={0.4}>
                <MDBox display="flex">
                  <MDTypography variant="h5" fontWeight="medium" mr="5px">
                    {companyGroup.name}
                  </MDTypography>
                  <CustomBadge
                    content={isActive ? "Active" : "Inactive"}
                    sx={{
                      ".MuiBadge-badge": {
                        background: `${
                          isActive ? "#4CAF50" : "#AE1709"
                        } !important`,
                        color: "white !important",
                      },
                    }}
                  />
                </MDBox>
                <MDTypography variant="h6" sx={{ fontWeight: "500" }} mt="5px">
                  {companyGroup.description}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          {isActive && (
            <Grid item>
              <MDButton
                variant="contained"
                color="error"
                size="medium"
                sx={{ fontSize: "14px" }}
                onClick={onDeleteCompanyClick}
              >
                DELETE
              </MDButton>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox display="flex">
        <MDBox display="flex" alignItems="center" ml="auto" mr={1.4}>
          <UsersTabs
            onTabChange={(tab: UsersTabsType) => {
              setActiveTab(tab);
            }}
          />
        </MDBox>
      </MDBox>
      <MDBox mt={-10}>{getTabContents()}</MDBox>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </DashboardContentLayout>
  );
};

export default UserDetails;
