import { IndividualVue } from "./vue_detail_interface";

export enum IndividualVueActionType {
  loadVueData = "LOAD_VUE_DATA",
  loadPhotoFiles = "LOAD_PHOTO_FILES",
  loadVideoFile = "LOAD_VIDEO_FILE",
  editMediaTags = "EDIT_PHOTO_TAGS",
  editVueTags = "EDIT_VUE_TAGS",
  editMediaEscalation = "EDIT_PHOTO_ESCALATION",
  editMediaVisibility = "EDIT_PHOTO_VISIBILITY",
}

export interface IndividualVuePageAction {
  type: IndividualVueActionType;
  payload: IndividualVue;
  id?: string;
  isStepTypeVideo?: boolean;
}

export const individualVuePageReducer = (
  state: IndividualVue,
  action: IndividualVuePageAction
) => {
  const { type, payload, isStepTypeVideo } = action;
  switch (type) {
    case IndividualVueActionType.loadVueData:
      return {
        ...state,
        vue: payload.vue,
        submissionId: payload.submissionId,
        status: payload.status,
        vuerCompletedAt: payload.vuerCompletedAt,
        companyId: payload.companyId,
        companyName: payload.companyName,
        lastUpdatedAt: payload.lastUpdatedAt,
      };
    case IndividualVueActionType.editVueTags:
      return {
        ...state,
        vue: {
          ...state.vue,
          vueTags: payload.vue.vueTags,
        },
      };
    case IndividualVueActionType.loadPhotoFiles:
      /// Check whether the ids of state.photos is same as the ids of payload.photos
      if (
        payload.photos.some((payloadPhoto) =>
          state.photos.some((statePhoto) => statePhoto.id === payloadPhoto.id)
        )
      ) {
        return state;
      }
      const appendPhotos = [...state.photos, ...payload.photos];
      return {
        ...state,
        photos: appendPhotos,
      };
    case IndividualVueActionType.loadVideoFile:
      return {
        ...state,
        videos: payload.videos,
      };
    case IndividualVueActionType.editMediaTags:
      const updatedMedia = isStepTypeVideo
        ? payload.videos[0]
        : payload.photos[0];
      let mediaWithNewTags = {};
      if (isStepTypeVideo) {
        mediaWithNewTags = {
          videos: [updatedMedia],
        };
      } else {
        mediaWithNewTags = {
          photos: state.photos.map((photo) =>
            photo.id === updatedMedia.id ? updatedMedia : photo
          ),
        };
      }
      return {
        ...state,
        ...mediaWithNewTags,
      };
    case IndividualVueActionType.editMediaEscalation:
      const editedMediaWithEscalationStatus = isStepTypeVideo
        ? payload.videos[0]
        : payload.photos[0];
      let mediaWithNewEscalationStatus = {};
      if (isStepTypeVideo) {
        mediaWithNewEscalationStatus = {
          videos: [editedMediaWithEscalationStatus],
        };
      } else {
        mediaWithNewEscalationStatus = {
          photos: state.photos.map((photo) =>
            photo.id === editedMediaWithEscalationStatus.id
              ? editedMediaWithEscalationStatus
              : photo
          ),
        };
      }
      return {
        ...state,
        ...mediaWithNewEscalationStatus,
      };
    case IndividualVueActionType.editMediaVisibility:
      const updatedMediaWithNewVisibility = isStepTypeVideo
        ? payload.videos[0]
        : payload.photos[0];
      let mediaWithNewVisibility = {};
      if (isStepTypeVideo) {
        mediaWithNewVisibility = {
          videos: [updatedMediaWithNewVisibility],
        };
      } else {
        mediaWithNewVisibility = {
          photos: state.photos.map((photo) =>
            photo.id === updatedMediaWithNewVisibility.id
              ? updatedMediaWithNewVisibility
              : photo
          ),
        };
      }
      return {
        ...state,
        ...mediaWithNewVisibility,
      };
    default:
      return state;
  }
};
