import axios, { AxiosError, AxiosResponse } from "axios";
import { WebServiceResponse, WebServiceStatus } from "utils/services/AppUrls";
import {
  getAuthenticationHeader,
  refreshAuthToken,
} from "pages/authentication/services/AuthenticationServices";
import { LocalStorageKeys } from "context/AuthProvider";
import { GENERIC_ERROR_MESSAGE } from "../../constants";

axios.defaults.baseURL = process.env.REACT_APP_DOMAIN;
axios.defaults.headers.common["Accept"] = "*";
axios.defaults.headers.post["Content-Type"] = "application/json";
const refreshTokenEndPoint = `/login/v1/refresh`;
const verifyTokenEndPoint = `/login/v1/verify`;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 429) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const authToken = window.localStorage.getItem(
          LocalStorageKeys.authToken
        );
        if (error.response.config.url.includes(verifyTokenEndPoint)) {
          // Not refreshing token for verify token API. 
          // Whenever verify token fails, the method calls refresh automatically and updates the context
          return Promise.reject(error);
        } else if (authToken && !error.response.config.url.includes(refreshTokenEndPoint)) {
          const formattedToken = authToken.replace(/"/g, "");
          // Refreshing token and retrying original request
          return axios
            .post(refreshTokenEndPoint, { body: formattedToken })
            .then((response) => {
              if (response.status === 200) {
                const newToken = response.data.body.replace(/"/g, "");
                window.localStorage.setItem(
                  LocalStorageKeys.authToken,
                  newToken
                );
                originalRequest.headers.x_ivueit_auth_token = newToken;
                if (originalRequest.url.includes(verifyTokenEndPoint)) {
                  // Updating original request with new token end point, only for verify api
                  originalRequest.data = JSON.stringify({ body: newToken });
                }
                // Retrying original request
                return axios(originalRequest);
              }
            })
            .catch((error) => {
              console.log("Error caught while refreshing token:", error);
            });
        }
      }
    } else {
      console.log("API call broken with other status: ", error.response.status);
    }
    return Promise.reject(error);
  }
);

// Perform POST request with Axios
export const performPostRequest = async (
  url: string,
  requestData?: any
): Promise<WebServiceResponse> => {
  try {

    var requestBody:any = {
      method: "post",
      url: url,
      headers: getAuthenticationHeader(),
    };
    if (requestData) {
      requestBody = {...requestBody, data: requestData}
    }

    const response = await axios(requestBody);
    return parseNetworkResponse(response);
  } catch (error) {
    return handleNetworkException(error);
  }
};

// Perform GET request with Axios
export const performGetRequest = async (
  url: string
): Promise<WebServiceResponse> => {
  try {
    const response = await axios.get(url, {
      headers: getAuthenticationHeader(),
    });
    return parseNetworkResponse(response);
  } catch (error) {
    return handleNetworkException(error);
  }
};

// Parses the network call response
const parseNetworkResponse = (response: AxiosResponse<any, any>) => {
  const { data, status } = response;
  if (status === 200) {
    if (data) {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.success,
        data: data,
      };
      return responseData;
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } else {
    const responseData: WebServiceResponse = {
      status: WebServiceStatus.error,
      error: GENERIC_ERROR_MESSAGE,
    };
    return responseData;
  }
};

// handles the network error
const handleNetworkException = async (error: any) => {
  var errorMessage = "";
  if ((error as AxiosError).response) {
    const responseData = (error as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    errorMessage =
      responseData.message ??
      "Could not process the request. Please contact the admin team.";

    switch (responseData.code) {
      case 8:
        const tokenRefreshed = await refreshAuthToken();
        errorMessage = tokenRefreshed
          ? "TOKEN_REFRESHED"
          : "TOKEN_REFRESH_FAILED";
        break;
      default:
        break;
    }
  } else {
    errorMessage = GENERIC_ERROR_MESSAGE;
  }
  const result: WebServiceResponse = {
    status: WebServiceStatus.error,
    error: errorMessage,
  };
  return result;
};

/// Common method for file download
/// Accepts the url & returns the details of the downloaded image
export const downloadPhotoFile = async (
  url: string
): Promise<WebServiceResponse> => {
  return await performGetRequest(url);
};
